// Apple      90 
// Mango    45
// Potato     15 
// Tomato    24
// Banana   50
// Grapes    60
// Pumpkin  40
// Gauva     35
// Orka        62
// Spinach   23


export const level4 = [
    { name: "Shirt", price: 120 },
    { name: "Pant", price: 260 },
    { name: "Cap", price: 60 },
    { name: "Vest", price: 35 },
    { name: "Coat", price: 450 },
    { name: "Shoes", price: 370 },
    { name: "Socks", price: 48 },
    { name: "Sun Glasses", price: 165 },
    { name: "Rain Coat", price: 130 },
    { name: "Bag", price: 310 }
];
  
export const level5 = [
    { name: "Chair", price: 260 },
    { name: "Table", price: 640 },
    { name: "Stool", price: 170 },
    { name: "Bed", price: 4250 },
    { name: "Sofa", price: 1650 },
    { name: "Bed Sheet", price: 760 },
    { name: "Door", price: 850 },
    { name: "Window", price: 1270 },
    { name: "Desk", price: 530 },
    { name: "Cupboard", price: 2100 }
];

export const level6 = [
    { name: "TV", price: 5300 },
    { name: "Laptop", price: 21000 },
    { name: "Microwave", price: 10200 },
    { name: "Mobile", price: 7600 },
    { name: "Ipad", price: 36300 },
    { name: "Headphone", price: 1200 },
    { name: "Speaker", price: 2600 },
    { name: "Camera", price: 15620 },
    { name: "Projector", price: 12560 },
    { name: "Printer", price: 17800 }
];

export const level3 =  [
    { name: "Apple", price: 90 },
    { name: "Mango", price: 45 },
    { name: "Potato", price: 15 },
    { name: "Tomato", price: 24 },
    { name: "Banana", price: 50 },
    { name: "Grapes", price: 60 },
    { name: "Pumpkin", price: 40 },
    { name: "Gauva", price: 35 },
    { name: "Orka", price: 62 },
    { name: "Spinach", price: 23 }
];


export const level2 = [
    { name:'Matchstick', price: 5},
    { name:'Chips', price:10},
    { name:'Namkeen', price:8},
    { name:'Soap', price:9},
    { name:'Toothpaste', price: 11},
    { name:'Juice', price:15},
    { name:'Chocolate', price:12},
    { name:'Surf', price: 14},
    { name:'Papad', price: 16},
    { name:'Cold Drink', price:20},
]

export const level1 = [
    { name:'Pen', price:  5},
    { name:'Pencil', price: 2},
    { name:'Eraser', price: 3},
    { name:'Chart', price: 4},
    { name:'Notebook  10', price: 10},
    { name:'Box', price: 8},
    { name:'Map', price: 3},
    { name:'Marker', price: 10},
    { name:'Crayon', price: 7},
    { name:'Sketch', price: 8},
]