import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WhiteBar from "../coreComponents/WhiteBar";
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';



const MultiplicationTable = () => {
  const [multiples, setMultiples] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  ]);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);


  const level0 = () => {
    const arr = [1, 2, 10];
    return {
      a: arr[Math.floor(Math.random() * arr.length)]
    };
  };
  const level1 = () => {
    const arr1 = [1, 2, 3, 5, 10];
    {
      return {
        a: arr1[Math.floor(Math.random() * arr1.length)]
      };
    }
  };
  const level2 = () => {
    return {
      a: Math.floor(1 + Math.random() * 9)
    };
  };
  const level3 = () => {
    return {
      a: Math.floor(1 + Math.random() * 14)
    };
  };
  const level4 = () => {
    return {
      a: Math.floor(1 + Math.random() * 19)
    };
  };
  const level5 = () => {
    return {
      a: Math.floor(1 + Math.random() * 24)
    };
  };
  const level = () => {
    switch (parseInt(location.state.level)) {
      case 0:
        const arr = [1, 2, 10];
        return {
          a: arr[Math.floor(Math.random() * arr.length)]
        };
        break;
      case 1:
        const arr1 = [1, 2, 3, 5, 10];
        {
          return {
            a: arr1[Math.floor(Math.random() * arr1.length)]
          };
        }
        break;
      case 2:
        return {
          a: Math.floor(1 + Math.random() * 9)
        };
        break;
      case 3:
        return {
          a: Math.floor(1 + Math.random() * 14)
        };
        break;
      case 4:
        return {
          a: Math.floor(1 + Math.random() * 19)
        };
        break;
      case 5:
        return {
          a: Math.floor(1 + Math.random() * 24)
        };
        break;
    }
  };
  const [numbers, setNumbers] = useState(level());
  const [submitted, setSubmitted] = useState(false);
  //   const dispatch = useDispatch();
  const handleNxt = () => {
    if(location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
  }
    setMultiples(["", "", "", "", "", "", "", "", "", ""]);
    setSubmitted(false);
    switch (parseInt(location.state.level)) {
      case 0:
        setNumbers(level0());
        break;
      case 1:
        setNumbers(level1());
        break;
      case 2:
        setNumbers(level2());
        break;
      case 3:
        setNumbers(level3());
        break;
      case 4:
        setNumbers(level4());
        break;
      case 5:
        setNumbers(level5());
        break;
    }
  };

  let multiplesOfA = [];
  for (let i = 1; i <= 10; i++) {
    multiplesOfA.push(i * numbers.a);
  }

  // 

  const handleSubmit = async (e) => {
    e.preventDefault();
    let answer = multiples.length === multiplesOfA.length &&
    multiples.every((value, index) => value === multiplesOfA[index])
    // await dispatch(submitResult({result:(parseInt(answer) === numbers.a + numbers.b ? "CORRECT" : "INCORRECT"),email:localStorage.getItem('email')}))
    setSubmitted(true);
    if(location.state.homework){
      dispatch(updateHomeWork({
       module: location.state.topic, id: location.state.id, correct: answer}))    
  }
  else {
      await dispatch(submitResult({
       result: answer ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
  }
  setAttempted(attempted + 1);

  };

  const handleFactorChange = React.useCallback((event) => {
    const index = parseInt(event.target.dataset.index, 10);
    setMultiples((multiples) => {
      const newMultiples = [...multiples];
      newMultiples[index] = parseInt(event.target.value);
      return newMultiples;
    });
  }, []);

  // const addInput = React.useCallback(
  //   () => setFactors((factors) => [...factors, ""]),
  //   []
  // );

  const incorrectBar = (
    <div className=" mx-3">
      <nav className="d-flex navbar mt-5 incorrect">
        <div className="nav-item mx-2 fs-5" style={{ color: "white" }}>
          <i class="fa-solid fa-xmark"></i> Wrong !
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <button className="btn btn-light mx-2" onClick={handleNxt}>
            Next
          </button>
        </div>
      </nav>
      <div className="container mt-4 " style={{ maxWidth: "500px" }}>
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            rowGap: "5%",
            justifyContent: "space-between"
          }}
        >
          {multiplesOfA.map((multiple, index) => {
            return (
              <div
                className="d-flex"
                key={index}
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ fontSize: "30px" }}>{numbers.a}</div>
                <div className="mx-2" style={{ fontSize: "30px" }}>
                  X
                </div>
                <div style={{ fontSize: "30px" }}>{index + 1}</div>
                <div className="mx-2" style={{ fontSize: "30px" }}>
                  =
                </div>
                <input
                  style={{
                    backgroundColor: "white",
                    fontSize: "30px",

                    width: "60px",
                    border: "none"
                  }}
                  disabled={submitted}
                  type="number"
                  data-index={index}
                  className="text-center align-items-center"
                  value={multiple}
                  onChange={handleFactorChange}
                ></input>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  const correctBar = (
    <nav className="d-flex navbar mt-5 mx-3 correct">
      <div className=" nav-item fs-3 mx-1 " style={{ color: "white" }}>
        <i class="fa-solid fa-check"></i> Correct!
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <button className="btn btn-light mx-3" onClick={handleNxt}>
          Next
        </button>
      </div>
    </nav>
  );
  return (
    <div style={{overflow:"scroll" }}>
      {/* <Navbar /> */}
      <WhiteBar label="Multiplication Table" level={location.state.level}/>
      <div className="container mt-4 " style={{ maxWidth: "500px" }}>
        <h3 style={{ textAlign: "center", marginBottom: "7%" }}>
          Table of {numbers.a}
        </h3>
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            rowGap: "5%",
            justifyContent: "space-between"
          }}
        >
          {multiples.map((multiple, index) => {
            return (
              <div
                className="d-flex"
                key={index}
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ fontSize: "30px" }}>{numbers.a}</div>
                <div className="mx-2" style={{ fontSize: "30px" }}>
                  X
                </div>
                <div style={{ fontSize: "30px" }}>{index + 1}</div>
                <div className="mx-2" style={{ fontSize: "30px" }}>
                  =
                </div>
                <input
                  style={{
                    backgroundColor: "white",
                    fontSize: "30px",
                    // width:
                    //   25 * `${(numbers.a + index).toString().length}` +
                    //   10 +
                    //   "px"
                    width: "60px",
                    border: "none"
                  }}
                  onWheel={(e) => e.target.blur()}
                  disabled={submitted}
                  type="number"
                  data-index={index}
                  className="text-center align-items-center"
                  value={multiple}
                  onChange={handleFactorChange}
                ></input>
              </div>
            );
          })}
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "100px" }}
        >
          <button
            disabled={submitted}
            type="submit"
            className="btn btn-dark mb-5"
            onClick={handleSubmit}
          >
            submit
          </button>
        </div>
      </div>
      {submitted === true
        ? multiples.length === multiplesOfA.length &&
          multiples.every((value, index) => value === multiplesOfA[index])
          ? correctBar
          : incorrectBar
        : ""}
    </div>
  );
};

export default MultiplicationTable;
