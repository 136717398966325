import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Homepage.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Component/Navbar/Navbar";
import PhotoScore from "../../Component/PhotoScore/PhotoScore";
import MapButton from "../../Component/MapButton";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import StudentForms from "../../Component/Forms/StudentForm";
import AdminForm from "../../Component/Forms/AdminForm";
import Delete from "../../Component/Forms/Delete";
import CustomButton from "../../Component/coreComponents/CustomButton";
import { resetScore } from "../../redux/scoreSlice";

const Homepage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const result = useSelector((state) => state.result);
  const [isShow,setShow] = useState();
  const [isShowDelete,setShowDelete] = useState();
  const [loading, setLoading] = useState(false);
  const [score, setScore] = useState(null);
  useEffect(() => {
    fetchData();
  }, [])


  const fetchData = async () => {
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/performance/viewAllPerformance`, {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    const scores = data.response;
    setScore(scores.find(sc => sc.CLASS === user.className && sc.SECTION === user.section))
    setLoading(false);
  }
  return (
    <div>
      {/* <Navbar /> */}
      <Modal show={isShow} >
        <Modal.Header closeButton onClick={()=>setShow(false)}><h5 className="m-0 p-0">Create Profile</h5></Modal.Header>
        {user.role === 'ADMIN' ? <StudentForms /> : <AdminForm/>}
      </Modal>
      <Modal show={isShowDelete} >
        <Modal.Header closeButton onClick={()=>setShowDelete(false)}><h5>Delete Profile</h5></Modal.Header>
        <Delete />
      </Modal>
      <div className="d-flex mt-3 mx-3" style={{ backgroundColor: "white" }}>
        <div className="py-2 mx-2 fs-5" style={{ color: "black" }}>
          Welcome !
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center my-1">
        <div className="container-fluid h-auto ">
          <PhotoScore
            title1={user.firstName.charAt(0).toUpperCase()+user.firstName.slice(1).toLowerCase() + ' ' + user.lastName.charAt(0).toUpperCase()+user.lastName.slice(1).toLowerCase()}
            title2={user.role ==='ADMIN' || user.role ==='SUPERADMIN' ? user.organization.toUpperCase() : 'Grade ' + user.className + ' ' + user.section}
            title3={user.role ==='ADMIN' || user.role ==='SUPERADMIN' ? '' : user.role.charAt(0)+user.role.slice(1).toLowerCase()}
            role={user.role}
            score={user.role === "STUDENT" ? result.score.toString() : score ? Math.floor(score?.average) : '0'}
          />
          <div className="row">
            <div className="col-3 justify-content-center my-2">
              <div className="d-flex flex-column text-center">
                {user.role === 'ADMIN' || user.role === 'SUPERADMIN' ?
                <>
                 <CustomButton label="Create Profile" onClick={()=>setShow(true)}/>
                 <CustomButton label="Delete Profile" onClick={()=>setShowDelete(true)}/>
                 <CustomButton label="View Error Reports" onClick={()=>{
                  navigate('/error/ErrorReport')
                 }}/>
                </>
                  : ''}
                <MapButton role={user.role} isShow={isShow}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
