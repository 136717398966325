import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Style.css";
import CustomButton from "../coreComponents/CustomButton";
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from 'react-router-dom';

function Parallelogram({ location }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);

  const [base, setBase] = useState(25);
  const [height, setHeight] = useState(22);
  const [angle, setAngle] = useState(60);
  const [area, setArea] = useState(0);
  const [perimeter, setPerimeter] = useState(0);
  const [isCorrect, setIsCorrect] = useState(null);

  // const calculateArea = () => {
  //   const area = base * height * Math.sin(angle * (Math.PI / 180));
  //   setArea(Math.round(area));
  // };

  // const calculatePerimeter = () => {
  //   const side = Math.sqrt(
  //     base * base +
  //       height * height -
  //       2 * base * height * Math.cos(angle * (Math.PI / 180))
  //   );
  //   const perimeter = 2 * Math.round(base + side);
  //   setPerimeter(Math.round(perimeter));
  // };

  if(location.state.homework && attempted >= location.state.ques) {
    alert('you have completed your homework');
    navigate('/homepage/homework');
  }

  const checkAnswers = async () => {
    if (
      area === Math.round(base * height * Math.sin(angle * (Math.PI / 180))) &&
      perimeter ===
      Math.round(
        2 *
        (base +
          Math.sqrt(
            base * base +
            height * height -
            2 * base * height * Math.cos(angle * (Math.PI / 180))
          ))
      )
    ) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
    if (location.state.homework) {
      dispatch(updateHomeWork({
        module: location.state.topic, id: location.state.id, correct: isCorrect
      }))
    }
    else {
      await dispatch(submitResult({
        result: isCorrect ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
      }))
    }
    setAttempted(attempted + 1);
  };

  return (
    <>
      <Container>
        <Row>
          <Col xs={12} md={5} className="mt-3">
            <div
              style={{
                backgroundColor: "white",
                width: 100,
                height: 100,
                transform: `skew(-${angle}deg)`,
              }}
            ></div>
          </Col>
          <Col xs={12} md={7} className="mt-5 d-flex">
            <Form>
              <Form.Group className="d-flex align-items-center mb-3">
                <Form.Label className="mx-2">Area</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter area"
                  value={area}
                  onChange={(e) => setArea(parseInt(e.target.value))}
                />
              </Form.Group>
              <Form.Group className="d-flex align-items-center">
                <Form.Label className="mx-2">Perimeter</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter perimeter"
                  value={perimeter}
                  onChange={(e) => setPerimeter(parseInt(e.target.value))}
                />
              </Form.Group>

            </Form>
          </Col>
        </Row>
        <div className="text-center mt-5">
          <CustomButton label="Submit" onClick={checkAnswers}></CustomButton>
        </div>
      </Container>
      {isCorrect === true ? (
        <CorrectBar />
      ) : (
        ""
      )}
      {isCorrect === false ? (
        <Col className="text-center mt-2">

          <IncorrectBar />
          <p>
            Area:{" "}
            {Math.round(base * height * Math.sin(angle * (Math.PI / 180)))}
          </p>
          <p>
            Perimeter:{" "}
            {Math.round(
              2 *
              (base +
                Math.sqrt(
                  base * base +
                  height * height -
                  2 * base * height * Math.cos(angle * (Math.PI / 180))
                ))
            )}
          </p>
        </Col>
      ) : ("")}
      <p>Base: {base}</p>
      <p>Height: {height}</p>
      <p>Angle: {angle}</p>
    </>
  );
}

export default Parallelogram;
