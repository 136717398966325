import React from 'react'
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";

function ShowAnswer({ answer, seq, missing, next }) {

  return (

    <>
      {
        (answer ?
          <CorrectBar onClick={next} /> :
          <IncorrectBar onClick={next}>
            <div className='d-flex gap-3 mt-0 justify-content-center' style={{ fontSize: '25px' }}>
            <div className='line-container'>
                <div className='line'></div>
                {
                   seq &&  seq.map((no, index) =>(
                    <div key={index} className='number-container'>
                        <div className="line-bar">
                            <div className={`${index === missing ? 'green-circle': 'hide'} ${index} ${missing}`}></div>
                        </div>
                        <span>{no}</span>
                    </div>
                    ))
                }
           </div>
            </div>
          </IncorrectBar>)
      }
    </>
  )

}

export default ShowAnswer


  // let steps = [], cols, printAnswer,  r = [];
  // function showDivision(x, y) {

  //   var a = x, b = y, q = '';
  //   var num = a.toString();
  //   cols = num.length;
  //   var i = 0;
  //   var temp = parseInt(num[i]);
    

  //   while (i < num.length) {

  //     /** tempq stores current quotient like 108/9
  //      * tempq will be 1/9 =0, b is divisor
  //      * q is current quotient to store answer
  //      * tempr stores remainder from temp and b, temp stores first value by default and will keep increasng
  //      * temp =1, temp = 10 temp = 108 
  //      * tempq2 stores remainder and the next number in the dividend
  //      * like 10
  //      * if r < divisor
  //      */
  //     var tempq = parseInt(temp / b);
  //     q = `${q}${tempq.toString()}`

  //     var tempr = temp % b;
  //     var tempq2 = `${tempr.toString()}${num[i + 1]}`; // i < num.length - 1 ?  : `${tempr.toString()}`;

  //     //if b * tempq == 0 the value will not get pushed
  //     
      
  //     if ((b * tempq)) {
  //       steps.push(b * tempq, parseInt(tempq2))
  //       r.push(tempr);
  //     } 
      
  //     temp = parseInt(tempq2);
  //     

  //     // 
  //     i++;
  //   }

  //   printAnswer = parseInt(q);;

  // }

  // showDivision(numbers.a, numbers.b);