import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
// import { getState, submitResult } from "../../redux/authSlice";
import Navbar from "../Navbar/Navbar";
import { submitResult } from "../../redux/scoreSlice";
import { useDispatch } from "react-redux";
import WhiteBar from "../coreComponents/WhiteBar";
import { updateHomeWork } from '../../redux/homeWorkSlice';

const Multiplication = () => {
  const [answer, setAnswer] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);
  const level0 = () => {
    const arr = [1, 2, 5, 10];
    return {
      a: Math.floor(Math.random() * 10),
      b: arr[Math.floor(Math.random() * arr.length)]
    };
  };
  const level1 = () => {
    return {
      a: Math.floor(Math.random() * 10),
      b: Math.floor(Math.random() * 10)
    };
  };
  const level2 = () => {
    return {
      a: Math.floor(Math.random() * 15),
      b: Math.floor(Math.random() * 10)
    };
  };
  const level3 = () => {
    return {
      a: Math.floor(Math.random() * 20),
      b: Math.floor(Math.random() * 10)
    };
  };
  const level4 = () => {
    return {
      a: Math.floor(Math.random() * 20),
      b: Math.floor(Math.random() * 15)
    };
  };
  const level5 = () => {
    return {
      a: Math.floor(Math.random() * 50),
      b: Math.floor(Math.random() * 20)
    };
  };
  const level = () => {
    switch (parseInt(location.state.level)) {
      case 0:
        const arr = [1, 2, 5, 10];
        return {
          a: Math.floor(Math.random() * 10),
          b: arr[Math.floor(Math.random() * arr.length)]
        };
        break;
      case 1:
        {
          return {
            a: Math.floor(Math.random() * 10),
            b: Math.floor(Math.random() * 10)
          };
        }
        break;
      case 2:
        return {
          a: Math.floor(Math.random() * 15),
          b: Math.floor(Math.random() * 10)
        };
        break;
      case 3:
        return {
          a: Math.floor(Math.random() * 20),
          b: Math.floor(Math.random() * 10)
        };
        break;
      case 4:
        return {
          a: Math.floor(Math.random() * 20),
          b: Math.floor(Math.random() * 15)
        };
        break;
      case 5:
        return {
          a: Math.floor(Math.random() * 50),
          b: Math.floor(Math.random() * 20)
        };
        break;
    }
  };
  const [numbers, setNumbers] = useState(level());
  //   const [answer, setAnswer] = useState();
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();
  const handleNxt = () => {
    if(location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
  }
    setAnswer("");
    setSubmitted(false);
    switch (parseInt(location.state.level)) {
      case 0:
        setNumbers(level0());
        break;
      case 1:
        setNumbers(level1());
        break;
      case 2:
        setNumbers(level2());
        break;
      case 3:
        setNumbers(level3());
        break;
      case 4:
        setNumbers(level4());
        break;
      case 5:
        setNumbers(level5());
        break;
    }
  };

  let n = numbers.a > numbers.b ? numbers.a : numbers.b;
  let m = numbers.b < numbers.a ? numbers.b : numbers.a;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (location.state.homework) {
      dispatch(updateHomeWork({
        module: location.state.topic, id: location.state.id, correct: parseInt(answer) === numbers.a + numbers.b
      }))
    }
    else {
      await dispatch(submitResult({
        result: parseInt(answer) === numbers.a + numbers.b ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
      }))
    }
    setAttempted(attempted + 1);
    setSubmitted(true);
  };

  const incorrectBar = (
    <div className=" mx-3">
      <nav className="d-flex navbar mt-5 incorrect">
        <div className="nav-item mx-2 fs-5" style={{ color: "white" }}>
          <i class="fa-solid fa-xmark"></i> Wrong !
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <button className="btn btn-light mx-2" onClick={handleNxt}>
            Next
          </button>
        </div>
      </nav>
      <div className="mx-1 mt-2 d-flex justify-content-center">
        <div className="text-center">
          <div className=" mt-2">
            <div style={{ fontSize: "30px", marginLeft: "30px" }}>{n}</div>
            {/* <br /> */}
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              X {m}
            </div>
          </div>
          <div style={{ borderTop: "2px solid #000000 " }}></div>
          <div
            style={{
              fontSize: "30px",
              marginLeft: "20px",
              display: "flex",
              justifyContent: "flex-end"
            }}
            className="mb-0"
          >
            {n *
              (m.toString().length > 1 ? parseInt(m.toString().charAt(1)) : m)}
          </div>
          {m.toString().length > 1 && (
            <div
              style={{
                fontSize: "30px",
                // marginLeft: "20px",
                marginRight: "15px",
                display: "flex",
                justifyContent: "flex-end"
              }}
              className="mb-0"
            >
              {n * parseInt(m.toString().charAt(0))}
            </div>
          )}
          <div style={{ borderTop: "2px solid #000000 " }}></div>
          {m.toString().length > 1 && (
            <div
              style={{
                fontSize: "30px",
                marginLeft: "20px"
                // marginRight: "30px"
              }}
              className="mb-0"
            >
              {n * m}
            </div>
          )}
        </div>
      </div>
    </div>
  );
  const correctBar = (
    <nav className="d-flex navbar mt-5 mx-3 correct">
      <div className=" nav-item fs-3 mx-1 " style={{ color: "white" }}>
        <i class="fa-solid fa-check"></i> Correct!
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <button className="btn btn-light mx-3" onClick={handleNxt}>
          Next
        </button>
      </div>
    </nav>
  );
  return (
    <div style={{ backgroundColor: "#f2f1f1", height: "auto" }}>
      {/* <Navbar /> */}
      <WhiteBar label="Multiplication" level={location.state.level} />
      <div className="container mt-4 ">
        <div className="d-flex justify-content-center">
          <div style={{ fontSize: "40px" }}>{n}</div>
          <div className="mx-2" style={{ fontSize: "40px" }}>
            X
          </div>
          <div style={{ fontSize: "40px" }}>{m}</div>
          <div className="mx-2" style={{ fontSize: "40px" }}>
            =
          </div>
          <input
            style={{
              backgroundColor: "white",
              fontSize: "30px",
              width: "60px",
              height: "60px"
            }}
            disabled={submitted}
            type="number"
            className="text-center align-items-center"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          ></input>
        </div>
        <div className="d-flex mt-5 justify-content-center">
          <button
            disabled={submitted || !answer}
            type="submit"
            className="btn btn-dark mb-5"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
      {submitted === true
        ? parseInt(answer) === n * m
          ? correctBar
          : incorrectBar
        : ""}
    </div>
  );
};

export default Multiplication;
