import React, { useState, useEffect } from "react";
import "./Level5.css";
import { useLocation, useNavigate } from "react-router-dom";

import { Col } from "react-bootstrap";
import Navbar from "../Navbar/Navbar";
import ClockModel from "./ClockModel";
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";
import UserButton from "../coreComponents/UserButton/UserButton";
import { Children } from "react";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { submitResult } from "../../redux/scoreSlice";
import { useDispatch } from "react-redux";


const Level5 = () => {
  const dispatch = useDispatch();
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);
  const [hourAngle, setHourAngle] = useState(null);
  const [minuteAngle, setMinuteAngle] = useState(null);
  const [answer, setAnswer] = useState("");
  const [result, setResult] = useState("");
  const [showAngleQuestion, setShowAngleQuestion] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);


  const updateTime = () => {
    const randomHour = Math.floor(Math.random() * 12) + 1;
    const randomMinute = Math.floor(Math.random() * 60);
    setHour(randomHour);
    setMinute(randomMinute);
    const hourHandAngle = getHourHandAngle(randomHour, randomMinute);
    setHourAngle(Math.floor(hourHandAngle));
    const minuteHandAngle = getMinuteHandAngle(randomMinute);
    setMinuteAngle(Math.floor(minuteHandAngle));
    setShowAngleQuestion(Math.random() < 0.5);
  };

  useEffect(() => {
    updateTime();
  }, []);

  const getHourHandAngle = (hours, minutes) => {
    const hourAngle = 0.5 * (60 * hours + minutes);
    return hourAngle > 360 ? hourAngle - 360 : hourAngle;
  };

  const getMinuteHandAngle = (minutes) => {
    const minuteAngle = 6 * minutes;
    return minuteAngle > 360 ? minuteAngle - 360 : minuteAngle;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let answertype = false;
    if (showAngleQuestion) {
      const angleDiff = Math.abs(hourAngle - minuteAngle);
      if (parseInt(answer) === angleDiff) {
        answertype = true;
        setResult(<CorrectBar onClick={handleNext} />);
      } else {
        answertype = false;
        setResult(
          <IncorrectBar
            onClick={handleNext}
            children={
              <div className="d-flex justify-content-center">
                <h1>The correct answer is {angleDiff}</h1>
              </div>
            }
          ></IncorrectBar>
        );
      }
    } else {
      if (parseInt(answer) === hourAngle) {
        answertype = true;
        setResult(<CorrectBar onClick={handleNext} />);
      } else {
        answertype = false;
        setResult(
          <IncorrectBar
            onClick={handleNext}
            children={
              <div className="d-flex justify-content-center">
                <h1>The correct answer is {hourAngle}.</h1>
              </div>
            }
          ></IncorrectBar>
        );
      }
    }
    if (location.state.homework) {
      dispatch(updateHomeWork({
        module: location.state.topic, id: location.state.id, correct: answertype
      }))
    }
    else {
      await dispatch(submitResult({
        result: answertype ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
      }))
    }
    setAttempted(attempted + 1);
  };

  const handleInputChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleReset = (e) => {
    setAnswer("");
  };

  const handleNext = () => {
    if (location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
    }
    updateTime();
    setResult("");
    setAnswer("");
  };

  return (
    <>
      <div className="d flex">
        <div className="d-flex justify-content-center">
          {showAngleQuestion ? <h4>At What Angle Is The Hour and min Hand</h4> :
            <h4>At What Angle Is The Hour Hand</h4>}
        </div>
        <div className="d-flex">
          <div style={{ width: "30%" }} className="text-center">
            <ClockModel hour={hour} minute={minute} />
          </div>

          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "70%" }}
          >
            <form onSubmit={handleSubmit}>
              <input
                type="number"
                id="answer"
                name="answer"
                className="form-control text-center"
                value={answer}
                onChange={handleInputChange}
              />
            </form>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center gap-2"
        style={{ marginTop: "-24px" }}
      >
        <UserButton
          className="btn btn-primary"
          label="Reset"
          onClick={handleReset}
        />

        <UserButton
          label="Submit"
          className="btn btn-primary"
          onClick={handleSubmit}
        />
      </div>
      {result !== null ? result : ""}
    </>
  );
};

export default Level5;
