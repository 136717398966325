import { useState } from "react";
import { Form, Row, Col, FormGroup, FormLabel } from "react-bootstrap";
import CustomButton from "../coreComponents/CustomButton";
import "./forms.css";
const Delete = () => {
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const handleRegister = (e) => {
    e.preventDefault();
    
    // .then((res)=>{
    //   
    //   setShow(true);
    //   if(res.payload.status === 200){
    //     setMessage({msg:"Successful Registration!",status:"success"});
    //   } else{
    //     setMessage({msg:"Registration failed!",status:"fail"});
    //   }
    // })
  };
  return (
        <Form className="m-2 p-4">
          <Row>
            <FormGroup>
              <FormLabel for="input1">User Id</FormLabel>
              <Form.Control
                type="email"
                id="input1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel for="input3">User Role</FormLabel>
              <Form.Control
                as="select"
                id="input3"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="TEACHER">Teacher</option>
                <option value="STUDENT">Student</option>
              </Form.Control>
            </FormGroup>
          </Row>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input7">Password</FormLabel>
                <Form.Control
                  type="password"
                  id="input7"
                  className={
                    confirmPassword && confirmPassword !== password
                      ? "form-control is-invalid"
                      : ""
                  }
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input9">Confirm Password</FormLabel>
                <Form.Control
                  type="password"
                  id="input9"
                  className={
                    confirmPassword && confirmPassword !== password
                      ? "form-control is-invalid"
                      : ""
                  }
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5 text-center">
              <CustomButton
                label="Submit"
                onClick={handleRegister}
                disabled={!(role && email && password === confirmPassword)}

              />
            </Col>
          </Row>
        </Form>
      // </div>
  );
};

export default Delete;
