import React from 'react'

const IncorrectAdditonBar = (props) => {
    
    const a = props.addition == "true" ? props.numbers.a : Object.values(props.numbers).sort()[1] 
    const b = props.addition == "true" ? props.numbers.b : Object.values(props.numbers).sort()[0] 
   
  return (
    <div className=" mx-3">
          <nav className="d-flex navbar mt-5 incorrect">
            <div className="nav-item mx-2 fs-5" style={{ color: "white" }}>
              <i class="fa-solid fa-xmark mx-1"></i> Wrong !
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <button className="btn btn-light mx-2" onClick={props.onClick}>
                Next
              </button>
            </div>
          </nav>
          <div className="mx-1 mt-2 d-flex justify-content-center">
            <div className="text-center m-0 py-0">
              {props.carry && <div className="d-flex py-0 m-0 flex-row-reverse" style={{fontSize:"20px", marginLeft: "28px", height: "15px"}}>
                <div className="my-0 py-0" style={{width:"16.5px"}}>&nbsp;</div>
                {props.carry.carry1 > 0 ? <div className="my-0 py-0" style={{width:"16.5px", height: "20px"}}>{props.carry.carry1}</div> : <div className="my-0 py-0" style={{width:"16.5px", height: "20px"}}>&nbsp;</div>}
                {props.carry.carry2 > 0 ? <div className="m-0 p-0" style={{width:"16.5px",height: "20px"}}>{props.carry.carry2}</div> : ""}
              </div>}
              <div style={{fontSize:'30px', marginLeft: "30px", height: "30px"}}>{a}</div>
              <div style={{fontSize:'30px'}} className="d-flex flex-row-reverse">{props.addition == "true" ? "+" : "-" }{' '}{b}</div>
              <div style={{ borderTop: "2px solid #000000 "}}></div>
              <div style={{fontSize:'30px',marginLeft:"20px"}} className="d-flex flex-row-reverse mb-0">{props.addition == "true" ? a + b : a - b}</div>
              <div style={{ borderTop: "2px solid #000000 "}}></div>
            </div>
          </div>
        </div>
  )
}

export default IncorrectAdditonBar