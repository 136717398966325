import React, { useState, useEffect } from 'react';
import { returnRandomNumber } from './Fraction';
import ShowAnswer from './ShowAnswer';
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from 'react-router-dom';

//Level 2; generate 4 fractions that the child has to arrange in ascending order.
// All 3 fraction type to be included. Max Numbers in numerator = 40, Max Numbers in numerator = 20, 
/**
 * refactor logic
 * numerator max 40 after whole calculation
 */
const MAX_NUM = 40;
const MAX_DEN = 20;

const whiteDivStyle = {
  minHeight: '70px',
  minWidth: '70px',
  backgroundColor: 'white'
}

function Fraction_level2({ location }
) {
  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);
  const [fractions, setFractions] = useState(null);
  const [answer, setAnswer] = useState([0, 0, 0, 0]);
  const [solution, setSolution] = useState([]);
  const [submitted, isSubmitted] = useState(false);
  const [cansubmit, setCansubmit] = useState(false);
  const [show, setShow] = useState(false);
  const [answerType, setAnswerType] = useState(false);
  const dispatch = useDispatch();


  useEffect(() => {

    generateFraction();
    return () => {

    }
  }, [])

  const generateFraction = () => {
    setAnswer([0, 0, 0, 0])
    const fraction = [
      returnRandomFraction(),
      returnRandomFraction(),
      returnRandomFraction(),
      returnRandomFraction(),
    ]
    setFractions(fraction)

    let tempsol = [...fraction];
    tempsol.sort((a, b) => b.val - a.val);
    setSolution(tempsol);
    
  }


  const returnRandomFraction = () => {
    let tempFrac = {}, w, n, d
    n = returnRandomNumber(1, MAX_NUM);
    d = returnRandomNumber(1, MAX_DEN);
    if (returnRandomNumber(1, 3) === 3) {
      w = d < 10 ? returnRandomNumber(1, 10) : returnRandomNumber(1, 5);
    }
    let improper = n + (w * d);

    if (w && improper > MAX_NUM) return returnRandomFraction()
    let val = (w ? improper : n) / d;
    tempFrac = {
      n: n, d: d, w: w, i: improper, val: val
    }

    return tempFrac;
  }

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('list-item', index);
  };

  const handleDrop = (e, index) => {
    const data = e.dataTransfer.getData('list-item');
    setCansubmit(true);
    answer[index] = fractions[data];
    setAnswer(answer.map((a, i) => {
      if (index === i) {
        return fractions[data]
      } else return a;
    }));
  };

  const onSubmit = async () => {
    setAnswerType(check(solution));
    setCansubmit(false);
    setShow(true);
    await dispatch(submitResult({result: answerType ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))

    if (location.state.homework) {
      dispatch(updateHomeWork({
        module: location.state.topic, id: location.state.id, correct: answerType
      }))
    }
    else {
      await dispatch(submitResult({
        result: answerType ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
      }))
    }
    setAttempted(attempted + 1);
  }

  const next = () => {
    
if(location.state.homework && attempted >= location.state.ques) {
  alert('you have completed your homework');
  navigate('/homepage/homework');
}
    setFractions(null);
    setAnswer([0, 0, 0, 0]);
    isSubmitted(false);
    setCansubmit(false);
    setShow(false);
    setAnswerType(false);

    generateFraction();
  }

  const check = (arr) => {
    let ans = true;
    answer.map((a, index) => {
      if (a.val !== arr[index].val)
        ans = false;
    })
    return ans;
  }


  return (
    <>
      <div style={{ fontSize: '30px' }} className='d-flex flex-column flex-wrap mt-5 align-items-center justify-content-center'>
        <p>Arrange the fractions in ascending order</p>
        <ul className='d-flex gap-2 p-0 m-0' >
          {

            fractions && fractions.map(({ w, n, d }, index) => (
              <>
                <li
                  style={{ userSelect: 'none' }}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  key={index} className='d-flex align-items-center gap-2  bg-light  p-2'>
                  {w && w}
                  <span className='text-center d-flex flex-column align-items-center justify-content-center'>
                    <p className='m-0 p-0'>{n}</p>
                    <div style={{ height: '2px', border: '1px solid black', width: '100%' }}></div>
                    <p className='m-0 p-0'> {d}</p>
                  </span>
                </li>
                {index < 3 ? <p className='align-self-center m-0'>{','}</p> : null}
              </>
            ))
          }
        </ul>

        { /** area to take inputs */}
        <div className='d-flex gap-2 mt-3'>
          <ul className='d-flex gap-2 p-0 m-0' style={{ height: 'fit-content' }}>
            {

              answer.map(({ w, n, d }, index) => (
                <>
                  <li
                    onDrop={(e) => { handleDrop(e, index) }}
                    onDragOver={e => e.preventDefault()}
                    style={whiteDivStyle}>
                    {
                      (n && d) &&
                      <>
                        <div className='d-flex justify-content-center align-items-center gap-2  bg-light  p-2'>
                          {w && w}
                          <span className='text-center d-flex flex-column align-items-center justify-content-center'>
                            <p className='m-0 p-0'>{n}</p>
                            <div style={{ height: '2px', border: '1px solid black', width: '100%' }}></div>
                            <p className='m-0 p-0'> {d}</p>
                          </span>
                        </div>
                      </>
                    }
                  </li>
                  {index < 3 ? <p className='align-self-center m-0'>{'<'}</p> : null}
                </>
              ))
            }
          </ul>

        </div>
        <div className='d-flex gap-3'>
          <button
            disabled={!cansubmit && !submitted}
            onClick={() => { setAnswer([0, 0, 0, 0], setCansubmit(false)) }}
            type='submit'
            className="btn btn-secondary mb-3 mt-5 px-4"
          >
            Reset
          </button>
          <button
            disabled={!cansubmit}
            onClick={onSubmit}
            type='submit'
            className="btn btn-dark mb-3 mt-5 px-4"
          >
            Submit
          </button>
        </div>
      </div>
      {
        show && <ShowAnswer next={next} answer={answerType}>
          <div style={{ fontSize: '25px' }} className='d-flex gap-2 mt-1 align-items-center justify-content-center'>
            <ul className='d-flex gap-2 p-0 m-0' style={{ height: 'fit-content' }}>
              {

                solution.map(({ w, n, d }, index) => (
                  <>
                    <li
                      onDrop={(e) => { handleDrop(e, index) }}
                      onDragOver={e => e.preventDefault()}
                      style={whiteDivStyle}>
                      {
                        (n && d) &&
                        <>
                          <div className='d-flex justify-content-center align-items-center gap-2  bg-light  p-2'>
                            {w && w}
                            <span className='text-center d-flex flex-column align-items-center justify-content-center'>
                              <p className='m-0 p-0'>{n}</p>
                              <div style={{ height: '2px', border: '1px solid black', width: '100%' }}></div>
                              <p className='m-0 p-0'> {d}</p>
                            </span>
                          </div>
                        </>
                      }
                    </li>
                    {index < 3 ? <p className='align-self-center'>{'<'}</p> : null}
                  </>
                ))
              }
            </ul>
          </div>
        </ShowAnswer>
      }
    </>
  )
}

export default Fraction_level2