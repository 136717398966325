const { Modal } = require("react-bootstrap")

const CoverModal= (props) => {
return (<Modal show={props.isShow} className="d-flex alert alert-danger" style={{alignItems:'center'}}>
  <Modal.Header>
  </Modal.Header>
    <Modal.Title  className="d-flex flex-column" style={{alignItems:'center'}}>Access Denied</Modal.Title>
    <Modal.Body  className="d-flex flex-column" style={{alignItems:'center',justifyContent:"center", fontSize: "35px"}}>
        Please Go to Fullscreen Mode.
    </Modal.Body>
</Modal>)
}
export default CoverModal;
    