import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchHomework } from '../../redux/homeWorkSlice';




function ViewHomeWork() {

    const homework = useSelector((state) => state.homework.data);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [selectedHomework, setSelectedHomework] = useState(null); 
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [msg, setmsg] = useState(null);
    const [assid, setAssid] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    
    }, [])

    const fetchData = async () => {

        setLoading(true);
        await dispatch(fetchHomework({homework, email: user.email, user}))
        setLoading(false);
    }

    const onClick = (id) => {
        homework.map(h => {
            if(h.assignmentID === id){
                setSelectedHomework(h.assignments)
                setAssid(id);
                
            }
        })
        
    }

    const visit = ( name, level, ques, attempted) => {
        name = name.replace(/\s/g, ''); // removes all whitespace characters
        //http://localhost:3000/homepage/practice/NumberNames
        
        navigate(`/homepage/practice/${name}`, {
            state: {
                level,
                topic: name,
                homework: true,
                id: assid,
                ques: parseInt(ques),
                attempted
            }
        })
    }

    const saveHomework = async () => {

        setSaving(true);
        const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/assignment/saveHomework`, {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              "x-auth-token": `${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                homework: homework,
                studentmail: user.email,
                className: user.className,
                section: user.section,
            }),
          })
          const data = await res.json();
          setSaving(false);
          setmsg(data);
        
    }

  return (
    <div className='d-flex flex-column gap-3'>
    <div className="d-flex mt-3 mx-3" style={{ backgroundColor: "white" }}>
        <div className="py-2 mx-2 fs-5" style={{ color: "black" }}>
          Home Work !
        </div>
      </div>
     {/** draw table */}
     {
        loading ? <h3>Loading...</h3> :
        homework && homework.length ? 
        <div className='p-3'>
        <div className='d-flex gap-3 mb-3'>
        {
            homework && homework.map((work, index) => (
                <button className='btn btn-dark' 
                onClick={() => onClick(work.assignmentID)}>{work.createdAt}</button>
            ))
        }
        </div>
        {
            selectedHomework && 
            newFunction(selectedHomework, visit)
        }
     </div>
     : 
     <h3 className='text-center'>No Home work today!!!</h3>
     }
     <button
     disabled={saving || !homework.length}
     onClick={saveHomework}
      className='btn btn-dark align-self-center' style={{ maxWidth: '200px '}}>
        {saving ? 'Saving...' : 'Save Homework'}
     </button>
     <p>{msg && msg.msg}</p>
    </div>
  )
}

export default ViewHomeWork;

function newFunction(selectedHomework, visit) {
    return <table className='table table-light text-center'>
        <thead>
            <tr>
                <th>S.no</th>
                <th>Topic</th>
                <th>Level</th>
                <th>Number of Questions</th>
                <th>Attempted</th>
                <th>Correct</th>
                <th>Visit</th>
            </tr>
        </thead>
        <tbody>
            {selectedHomework && selectedHomework.map((item, index) => (
                <tr key={item.assignmentID}>
                    <td className='align-middle'>{index + 1}</td>
                    <td className='align-middle'>{item.topic}</td>
                    <td className='align-middle'>{item.level}</td>
                    <td className='align-middle'>{item.numberOfQues}</td>
                    <td className='align-middle'>{item.attempted}</td>
                    <td className='align-middle'>{item.correct}</td>
                    <td className='align-middle'>
                        {item.completed ? <p className='m-0 text-success'>Completed</p> : <button
                            onClick={() => visit(item.topic, item.level, item.numberOfQues, item.attempted )}
                            className='btn btn-dark'>Visit</button>}
                    </td>
                </tr>
            ))}
        </tbody>
    </table>;
}
