import React from 'react'
import Square from './Square';
import { useLocation } from 'react-router-dom';
import WhiteBar from '../coreComponents/WhiteBar';
import Navbar from '../Navbar/Navbar'
import Rectangle from './Rectangle';
import Circle from './Circel';
import Parallelogram from './Parallelogram';
import SquareAndTriangle from './SquareAndRectangle';


const AreaPerimeter = () => {
    const location = useLocation();
    let content;
    
    switch (parseInt(location.state.level)) {
        case 0: 
            content = <Square location={location}/>            
            break;
        case 1: 
            content = <Rectangle location={location}/>
            break;
        case 2: 
            content = <Circle location={location}/>            
            break;
        case 3: 
            content = <Parallelogram location={location}/>            
            break;
        case 4: 
            content = <SquareAndTriangle location={location}/>            
            break;
        // case 5: 
        //     content = <Level5 />            
        //     break;
    
        default:
            break;}
  return (
    <div>
    <WhiteBar label="Area And Perimeter" level={parseInt(location.state.level)}/>
    {content}
</div>
  )
}

export default AreaPerimeter