import React, { useEffect, useState, useRef  } from 'react'
import { useLocation } from 'react-router-dom';
import CorrectBar from '../coreComponents/CorrectBar';
import IncorrectBar from '../coreComponents/IncorrectBar';
import WhiteBar from '../coreComponents/WhiteBar';
import { useNavigate } from 'react-router-dom';



const answerfn =  ({depth,limit}) => {

    let arrAns = []
    for(var i =0; i<depth; i++){
        arrAns.push([]);
    }
    for(var j=depth-1;j>=0;j--){

        for(var i =0; i<=j; i++){
            if(j===depth-1){

                arrAns[j].push(Math.floor(Math.random()*(limit)+1));
            } else {
                arrAns[j].push(arrAns[j+1][i]+arrAns[j+1][i+1]);

            }
        }
    }

    
    const ansArr =arrAns;
    let inputL=[];
    let inputA=[];
    for(var i=0;i<ansArr.length;i++){
        const arr = [];
        const arrA = [];
        const index = Math.floor(Math.random()*(i+1));
        for(var j=0;j<=i;j++){
            if(index === j){
                arr.push(ansArr[i][j])
            } else{
                arr.push(null);
            }
            arrA.push(null);
        }
        inputL.push(arr);
        inputA.push(arrA);
    }
    return {inputList:inputL,ansArry:ansArr,inputAnswer: inputA};
    
}
const MagicTriangle = () => {
    const inputRef = useRef(null);
    const location = useLocation();
    const levels = [
        {level: 0, depth: 3, limit: 3},
        {level: 1, depth: 3, limit: 5},
        {level: 2, depth: 4, limit: 4},
        {level: 3, depth: 4, limit: 5},
        {level: 4, depth: 5, limit: 3},
        {level: 5, depth: 6, limit: 3},
    ]
    let {inputList,ansArry} = answerfn(levels[location.state.level])
    const [submitted, setSubmitted] = useState(false);
    const [ansArr, setAnsArr] = useState(ansArry);
    const [inputAnswer, setInputAnswer] = useState(inputList);
    const [inputLists, setInputLists] = useState(inputList);
    const check = () => {
        let status = false;
        for(var i=0;i<ansArr.length;i++){
            for(var j=0;j<=i;j++){
                if(ansArr[i][j] !== parseInt(inputLists[i][j])){
                    status = false;
                    
                    break;
                } else {
                    status = true;
                    
                }
            }
        }
        return status;
    }
    useEffect(() => {
        inputRef.current.focus();
    
      return () => {
        
      }
    }, [])
    const handleSubmit = () => {
        setSubmitted(true);
    }
    const handleNxt = () => {
        
        setSubmitted(false);
        window.location.reload();
    }
  return (
    <>
    <WhiteBar label="Magic Triangle" level={location.state.level}/>
        <div className='d-flex flex-column justify-content-center gap-1'>
        {
                inputLists.map((list,i)=>{
                    return(
                        <React.Fragment  key={i}>

                        <tr className='d-flex justify-content-center my-1 gap-2' key={i}>
                            {
                                list.map((num,j)=>{
                                    
                                    return(
                                        <td 
                                        style={{minWidth: '50px'}}
                                        key={`${i}`+`${j}`}>

                                            <input
                                            //  value={num}
                                            ref={j===0 && i === 0? inputRef : null} 
                                             value={inputAnswer[i][j] === null ? num : num}
                                             className=" text-center border-1  form-control"
                                             style={{width: '50px'}}
                                             onChange={(e)=>{const l = inputLists;l[i][j]=e.target.value; setInputLists(l)}}
                                             />
                                        </td>
                                    )
                                })
                            }
                        </tr>
                        </React.Fragment>
                    )
                })
            }
            <div className="d-flex mt-5 justify-content-center">
              <button
                type="submit"
                className="btn btn-dark mb-5"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
        </div>
        {submitted ? (check() ? <CorrectBar onClick={handleNxt}/> : <IncorrectBar onClick={handleNxt} />) : ''}
    </>
  )
}

export default MagicTriangle