import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import selectedTopics from '../../Component/coreComponents/gradeWiseTopics/GradeTopics';
import WhiteBar from '../../Component/coreComponents/WhiteBar';

function Homework() {
  const user = useSelector(state => state.user);
  const [selectedMathGrade, setSelectedMathGrade] = useState(selectedTopics({ className: user.className }).math);
  const [selectedEngGrade, setSelectedEngGrade] = useState(selectedTopics({ className: user.className }).eng);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState('');
  const [assignment, setAssignment] = useState([]);
  const [currentTopic, setCurrentTopic] = useState('');
  const [currentLevel, setCurrentLevel] = useState('');
  const [currentNumberOfQues, setCurrentNumberOfQues] = useState('');
  const [msg, setMsg] = useState();

  const handleTopicChange = (event) => {
    setCurrentTopic(event.target.value);
  }

  const handleLevelChange = (event) => {
    setCurrentLevel(event.target.value);
  }

  const handleNumberOfQuesChange = (event) => {
    setCurrentNumberOfQues(event.target.value);
  }

  const handleRemove = (index) => {
    // event.preventDefault();
    if (assignment.length === 1) {
      setAssignment([])
    } else {
      const newAssignment = assignment.slice(); // create a new copy of the array
      newAssignment.splice(index, 1); // modify the new array
      setAssignment(newAssignment); // set the new array as the state
    }
    
  }

  const handleAdd = (event) => {
    event.preventDefault();
    const newAssignment = { topic: currentTopic, level: currentLevel, numberOfQues: currentNumberOfQues };
    setAssignment([...assignment, newAssignment]);
    
    // reset current word fields
    setCurrentTopic('');
    setCurrentLevel('');
    setCurrentNumberOfQues('');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setSaving(true);
    const res = await fetch(
      process.env.REACT_APP_API_ADDRESS +
      '/api/assignment/assignHomework',

      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "x-auth-token": `${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ 
          forClass: user.className,
          email: user.email, 
          role: user.role, assignments: assignment,
          section: user.section
        }),
      }
    );
    const resJson = await res.json();
    setSaving(false);
    setSaved(resJson.msg);
    
  }

  return (
    <div>
      <WhiteBar label="Add assignment" />
      <div className='mx-3'>
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th style={{ width: 20 }} scope="col">#</th>
              <th style={{ width: 100 }} scope="col">Topic</th>
              <th style={{ width: 100 }} scope="col">Level</th>
              <th style={{ width: 50 }} scope="col">Number of Questions</th>
            </tr>
          </thead>
          <tbody>
            {assignment.length > 0 && assignment.map((item, index) => (
              <tr>
                <th scope="row">{index}</th>
                <td>
                  <select
                    className='btn btn-outline-dark dropdown-toggle'
                    value={item.topic}
                    onChange={handleTopicChange}
                  >
                    <option >select topics</option>
                    {selectedMathGrade.map((mathTopic) => {
                      return (
                        <option value={mathTopic}>{mathTopic}</option>
                      )
                    })}
                    {selectedEngGrade.map((engTopic) => {
                      return (
                        <option value={engTopic}>{engTopic}</option>
                      )
                    })}
                  </select>
                </td>
                <td >
                  <select
                    className='btn btn-outline-dark dropdown-toggle'
                    value={item.level}
                    onChange={handleLevelChange}
                  >
                    <option value="0">Level 0</option>
                    <option value="1">Level 1</option>
                    <option value="2">Level 2</option>
                    <option value="3">Level 3</option>
                    <option value="4">Level 4</option>
                    <option value="5">Level 5</option>
                  </select>
                </td>
                <td>
                  <input
                    className="form-control"
                    type='number'
                    style={{
                      ":hover": {
                        MozAppearance: "number-input"
                      },
                      ":focus": {
                        MozAppearance: "number-input"
                      }
                    }}
                    value={item.numberOfQues}
                    onChange={handleNumberOfQuesChange} />
                </td>
                <td >
                  <button
                    className='btn btn-dark'
                    onClick={() => handleRemove(index)}
                  >
                    -
                  </button>
                </td>
              </tr>
            ))}
            <tr>
              <th scope="row">{assignment.length}</th>
              <td >
                <select
                  className='btn btn-outline-dark dropdown-toggle'
                  value={currentTopic}
                  onChange={handleTopicChange}
                >
                  <option >select topics</option>
                  {selectedMathGrade.map((mathTopic) => {
                    return (
                      <option value={mathTopic}>{mathTopic}</option>
                    )
                  })}
                  {selectedEngGrade.map((engTopic) => {
                    return (
                      <option value={engTopic}>{engTopic}</option>
                    )
                  })}
                </select>
                {/* <input className="form-control" type='text' value={currentTopic} onChange={handletopicChange}/> */}
              </td>
              <td >
                <select
                  className='btn btn-outline-dark dropdown-toggle'
                  value={currentLevel}
                  onChange={handleLevelChange}
                >
                  <option value="0">Level 0</option>
                  <option value="1">Level 1</option>
                  <option value="2">Level 2</option>
                  <option value="3">Level 3</option>
                  <option value="4">Level 4</option>
                  <option value="5">Level 5</option>
                </select>
              </td>
              <td>
                <input
                  className="form-control"
                  type='number'
                  style={{
                    ":hover": {
                      MozAppearance: "number-input"
                    },
                    ":focus": {
                      MozAppearance: "number-input"
                    }
                  }}
                  value={currentNumberOfQues}
                  onChange={handleNumberOfQuesChange} />
              </td>
              <td >
                <button className='btn btn-dark' onClick={handleAdd} disabled={!(currentLevel && currentTopic && currentNumberOfQues)}>+</button>
              </td>
            </tr>
          </tbody>

        </table>

        {/* input here */}
        <div className='text-center'>

          <button type="submit" className='btn btn-dark mb-3' onClick={handleSubmit}>Submit</button>
          {
            saving && <p>Saving...</p>
          }
          {
            saved && <p>{saved}</p>
          }
        </div>

      </div>
    </div>
  );
}

export default Homework;


// import React from "react";
// import { useState } from "react";
// import WhiteBar from "../../Component/coreComponents/WhiteBar";
// import Navbar from "../../Component/Navbar/Navbar";

// const Homework = () => {
//   const dataClass = [
//     { subject: "Math" },
//     { subject: "Science" },
//     { subject: "English" },
//   ];

//   const classKey = ["Topics", "Select Level", "Questions"];

//   const [key, setKey] = useState(classKey);
//   const [datas, setDatas] = useState(dataClass);
//   const [selectedClass, setSelectedClass] = useState(null);
//   const [question, setQuestion] = useState("");

//   const handleSelect = (e) => {
//     setSelectedClass(e.target.value);
//   };
//   const handleQuestion = (e) => {
//     setQuestion(e.target.value);
//   };
//   return (
//     <div Color: "#f2f1f1", height: "700px" }}>
//       {/* <Navbar /> */}
//       <WhiteBar label="Assign Homework"/>
//       <div>
//         <table className="table table-striped" 0%" }}>
//           <thead>
//             <tr>
//               <th scope="col"></th>
//               {key.map((data) => {
//                 return (
//                   <th scope="col" .33%" }}>
//                     {data}
//                   </th>
//                 );
//               })}
//             </tr>
//           </thead>
//           <tbody>
//             {datas.map((dataObj, index) => {
//               var index = index + 1;
//               return (
//                 <tr>
//                   <th scope="row">{index}</th>
//                   {key.map((data) => {
//                     return data === "Select Level" ? (
//                       <td>
//                         <select onChange={handleSelect}>
//                           <option value={null}>Select Level</option>
//                           <option value={1}>Level 1</option>
//                           <option value={2}>Level 2</option>
//                           <option value={3}>Level 3</option>
//                           <option value={4}>Level 4</option>
//                           <option value={5}>Level 5</option>
//                         </select>
//                       </td>
//                     ) : data === "Topics" ? (
//                       <td>{dataObj.subject}</td>
//                     ) : data === "Questions" ? (
//                       <td>
//                         <input
//                           disabled={!selectedClass}
//                           type="number"
//                           value={question}
//                           onChange={handleQuestion}
//                           placeholder="Enter Number of Questions"
//                         />
//                       </td>
//                     ) : (
//                       <td>{dataObj[data]}</td>
//                     );
//                   })}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };
// export default Homework;
