import React, { useEffect, useState } from 'react'
import { level1, level2, level3, level4, level5, level6 } from './cartlist';
import { randomNum } from './ShoppingCart';
import CorrectBar from '../coreComponents/CorrectBar';
import IncorrectBar from '../coreComponents/IncorrectBar';
import { RxCross2 } from 'react-icons/rx';
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";

//delete button befoer name when input
/*
 *  display messages when budget exceeds 
 *  no incorrect bar
 *  level 6
 *  two things< message you exceeded your limit 
 *  highlight and show correct total
 *  search website template
*/

const style = {
    width: '50%',
    maxHeight: '60vh',
    overflowY: 'scroll',
    scrollbarColor: "rgba(0, 0, 0, 0.5) #f1f1f1",
    scrollbarWidth: "thin",
    /* Set scrollbar width and height */
}

function Level6() {
    const [selectedItems, setSelectedItems] = useState([]);
    const [items, setItems] = useState([]);
    const [totalAmount, settotalAmount] = useState(0);
    const [inputTotal, setinputTotal] = useState({val: '', wrong: false});
    const [submitted, setSubmitted] = useState(false);
    const [answertype, setAnswerType] = useState([]);
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        generateParams();
    }, []);

    const generateParams = () => {
        let temp = items.splice(0, items.length);
        temp = items.concat(level1, level2, level3, level4, level5, level6)

        settotalAmount(randomNum(500, 100000, true));

        
        setItems(temp.map(i => { return { ...i, quantity: '', cost: '' } }))

    }

    const handleCheckboxChange = (item) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((i) => i !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };

    const onChange = (e, item, type) => {
        const updatedItems = selectedItems.map((i) => {
            if (i === item) {
                return { ...i, [type]: e.target.value.length ? parseInt(e.target.value) : '' };
            }
            return i;
        });
        setSelectedItems(updatedItems);
    }

    const onSubmit = async () => {
        let wrong = false, temptotal = 0;

        setSelectedItems(selectedItems.map((item) => {
            const { price, quantity, cost} = item;
            let w = false;
            if(cost !== price * quantity){
                w = true;
                wrong=true;
                
            }
            temptotal+= (price * quantity);
            return { wrong: w, ...item}
        }))
        if(inputTotal.val !== temptotal){
            
            wrong = true;
            setinputTotal({...inputTotal, wrong: true})
        }
        if(temptotal > totalAmount){
            wrong = true
            
        }


        setSubmitted(true);
        setAnswerType(!wrong);
        setTotal(temptotal);
        await dispatch(submitResult({result: answertype ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
        
    }

    const removeItem = (item) => {
        setSelectedItems(selectedItems.filter((i) => i !== item));
    }

    const next = () => {
        setSubmitted(false);
        setAnswerType(false);
        setSelectedItems([]);
        setinputTotal({val: '', wrong: false});
        generateParams();

    }

    return (
        <>
        <div className='d-flex justify-content-center align-items-center pt-3 gap-3'>

            <div style={{ padding: '0 100px', gap: '100px', width: '100%' }} className='pt-3 d-flex justify-content-center align-items-center'>
                {/** draw table */}
                <div style={style} className='bg-light px-8'>
                    <table className="m-0 table-borderless table-light table border border-dark ">
                        <thead className='border border-dark' style={{ position: "sticky", top: 0, zIndex: 1 }}>
                            <tr>
                                <th className='border border-dark'></th>
                                <th className='border-end border-bottom border-dark'>Name</th>
                                <th className='border-end border-bottom border-dark'>Price</th>
                                <th style={{ maxWidth: '100px' }} className='text-center'>Check</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                items && items.map((item, index) => (
                                    <>
                                    <tr key={index}>
                                        <td className='border-end border-dark'>{index + 1}</td>
                                        <td className='border-end border-dark'>{item.name}</td>
                                        <td className='border-end border-dark'>Rs. {item.price}</td>
                                        <td className='text-center' >
                                            <input
                                                className='m-0 form-check-input'
                                                type="checkbox"
                                                checked={selectedItems.some(i => i.name === item.name && i.price === item.price)}
                                                onChange={() => handleCheckboxChange(item)} />
                                        </td>
                                    </tr>
                                    </>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

                {/** draw input */}
                <div className='d-flex text-center justify-content-center flex-column align-items-center gap-2'>
                    <p className='m-0' style={{ fontSize: '25px' }}>
                        You have Rs. {totalAmount}, shop within your budget
                    </p>
                    <div style={{...style, width: '100%'}} className='bg-light'>
                        {
                            selectedItems.length ?
                                <>
                                    <table style={{ maxWidth: '100%' }} className="table m-0 table-bordered">
                                        <thead className="thead-dark">
                                            <tr>
                                            <th></th>
                                                <th>Name</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedItems.map((item) => (
                                                <tr key={item.name}>
                                                    <td><RxCross2 style={{
                                                        cursor: 'pointer',
                                                    }}
                                                        onClick={() => removeItem(item)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <span className='d-flex justify-content-center align-items-center gap-2'>

                                                            <p className='m-0'> {item.name} </p>
                                                        </span>
                                                    </td>
                                                    <td>Rs. {item.price}</td>
                                                    <td>
                                                        <input
                                                            value={item.quantity || ""}
                                                            onChange={(e) => { onChange(e, item, 'quantity') }}
                                                            className={`form-control m-0`}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            value={item.cost || ""}
                                                            onChange={(e) => { onChange(e, item, 'cost') }}
                                                            className={`form-control m-0  ${item.wrong ? 'border-2 border-danger': ''}`}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td colSpan="2" className='text-center' style={{ textAlign: "right" }}>
                                                    Total Price:
                                                </td>
                                                <td colSpan='2'>
                                                    <input
                                                        value={inputTotal.val}
                                                        onChange={(e) => {
                                                            
                                                            setinputTotal({
                                                                ...inputTotal,
                                                                val: e.target.value.length ? parseInt(e.target.value) : ''
                                                            })
                                                            
                                                        }}
                                                        className={`form-control m-0 ${submitted && (inputTotal.wrong ? 'border-2 border-danger text-decoration-line-through': '')}`}
                                                    />
                                                    {
                                                        (submitted && total > totalAmount) ? 
                                                        <p className='text-center mb-1 text-danger' style={{ fontSize: '15px'}}>You went out of budget</p> : null
                                                    }
                                                    {
                                                        (submitted && (
                                                            inputTotal.wrong ? <p className='text-center text-success' style={{ fontSize: '15px' }}>
                                                            Your total should be Rs.{total}
                                                            </p> : null
                                                        ))
                                                    }
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </> :
                                <p style={{ fontSize: '20px' }} className='text-center m-0'>Mark the items you want</p>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div style={{ width: '100%'}} className='d-flex pt-3 align-items-center justify-content-center'>
            <button 
            onClick={onSubmit}
            className='btn btn-dark'> submit </button>
        </div>
        {
            submitted && (answertype ? <CorrectBar onClick={next}/> : <IncorrectBar onClick={next}/>)
        }
        </>
    );
}

export default Level6
// ${showAnswer && balance.wrong ? 'border-2 border-danger' : ''}