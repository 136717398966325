import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

function ManageHomework() {

    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [homework, setHomework] = useState(null);
    const [selectedhome, setSelectedHome] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        fetchData();
    },[])

    const fetchData = async () => {
        setLoading(true);
        const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/homework/viewHomework/${user.className}${user.section}`, {
          headers: {
            "Content-type": "application/json",
            "x-auth-token": `${localStorage.getItem("token")}`,
          },
        });
        const data = await res.json();
        setHomework(data.results);
        setLoading(false);
      }
      

      const onClick =  (ass) => {
        setShow(true);
        setSelectedHome(ass);
      }

      const handleClose = () => {
        setShow(false);
        setSelectedHome(null);
      }

      const deleteHomework = async () => {
          setLoading(true);
          const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/homework/deleteHomework`, {
              headers: {
                  "Content-type": "application/json",
                  "x-auth-token": `${localStorage.getItem("token")}`,
              },
              method: 'post',
              body: JSON.stringify({
                assignmentID: selectedhome.assignmentID,
                topic: selectedhome.topic
              })
          });
          const data = await res.json();
          setLoading(false);
          setShow(false);
          fetchData();


      }

    return (
        <div className='d-flex flex-column gap-3'>
        <div className="d-flex mt-3 mx-3" style={{ backgroundColor: "white" }}>
            <div className="py-2 mx-2 fs-5" style={{ color: "black" }}>
              Manage Homework !
            </div>
          </div>
           <div className='px-3'>
           {
                loading ? <h3>Loading...</h3> :
                <table className='table table-light text-center'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Class</th>
                        <th>Section</th>
                        <th>Level</th>
                        <th>Questions</th>
                        <th>Topic</ th>
                        <th>Created</th>
                        <th>Delete?</th>
                       
                    </tr>
                </thead>
                <tbody className='align-center'>
                    {homework && homework.map((s, index) => (
                        <tr className="text-center" key={index}>
                            <td className="align-middle">{index + 1}</td>
                            <td className="align-middle">{s.class}</td>
                            <td className="align-middle">{s.section}</td>
                            <td className="align-middle">{s.level}</td>
                            <td className="align-middle">{s.numberOfQues}</td>
                            <td className="align-middle">{s.topic}</td>
                            <td className="align-middle">{new Date(s.createdAt).toLocaleDateString()}</td>
                            <td className="align-middle">
                                <button
                                className='btn btn-dark'
                                onClick={() => onClick(s)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            }
           </div>
           <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Homework</Modal.Title>
        </Modal.Header>
        <Modal.Body> Are you sure you want to delete this homework?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteHomework}>
           { loading ? 'Deleting...' : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
        </div>


      )
}

export default ManageHomework