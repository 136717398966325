import React, { useState } from "react";
import { useSpeechSynthesis, useSpeechRecognition } from "react-speech-kit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FaPause, FaPlay } from "react-icons/fa";
import CorrectBar from "../../Component/coreComponents/CorrectBar";
import IncorrectBar from "../../Component/coreComponents/IncorrectBar";
import UserButton from "../../Component/coreComponents/UserButton/UserButton";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from 'react-router-dom';

function SentenceFIve({ location }) {
  const [value, setValue] = useState("");
  const [wordOptions, setWordOptions] = useState([]);
  const [correctOption, setCorrectOption] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const { speak, speaking, cancel } = useSpeechSynthesis();
  const [showNextButton, setShowNextButton] = useState(false);
  const [listening, setListening] = useState(false);
  const [jumbledWord, setJumbledWord] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);

  const { listen, stop } = useSpeechRecognition({
    onResult: (result) => {
      checkAnswer(result);
    },
  });
  const sentences = [
    {
      word: "The quick brown fox jumps over the lazy dog",
      options: ["quick", "brown", "fox", "jumps"],
    },
    {
      word: "The quick brown fox jumps over the lazy dog",
      options: ["bird", "hand", "worth", "bush"],
    },
    {
      word: "The quick brown fox jumps over the lazy dog",
      options: ["actions", "speak", "louder", "words"],
    },
    {
      word: "The quick brown fox jumps over the lazy dog",
      options: ["gold", "glitters", "not", "all"],
    },
  ];

  const getRandomSentence = () => {
    const randomIndex = Math.floor(Math.random() * sentences.length);
    const sentence = sentences[randomIndex];
    return sentence;
  };
  const startListening = () => {
    const randomWord = getRandomSentence();
    const shuffledWord = shuffleString(randomWord.word);
    setWordOptions(randomWord.options);
    setCorrectOption(randomWord.word);
    speak({ text: `${randomWord.word}` });
    setJumbledWord(shuffledWord);
    setListening(true);
  };

  const shuffleString = (sentence) => {
    let words = sentence.split(" ");
    for (let i = words.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [words[i], words[j]] = [words[j], words[i]];
    }
    return words.join(" ");
  };

  const checkAnswer = (answer) => {
    if (answer.toLowerCase() === correctOption.toLowerCase()) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
    setCorrectAnswer(correctOption);
    setWordOptions([]);
    setCorrectOption(null);
    setValue("");
    setListening(false);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    checkAnswer(value);
    if (location.state.homework) {
      dispatch(updateHomeWork({
        module: location.state.topic, id: location.state.id, correct: isCorrect
      }))
    }
    else {
      await dispatch(submitResult({
        result: isCorrect ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
      }))
    }
    setAttempted(attempted + 1);

  };

  const handleStopClick = () => {
    cancel();
    setWordOptions([]);
    setCorrectOption(null);
    setValue("");
    setListening(false);
  };

  const handleNextClick = () => {
    if (location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
    }
    setIsCorrect(null);
    setCorrectAnswer(null);
    setShowNextButton(false);
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        {listening ? (
          <div>
            <div className="col-12 d-flex justify-content-center mt-5">
              <FaPause onClick={handleStopClick} />
            </div>
          </div>
        ) : (
          <div className="col-12 d-flex justify-content-center mt-5">
            <FaPlay onClick={startListening} />
          </div>
        )}
      </div>

      <form className="mt-5" onSubmit={handleSubmit}>
        <div className="form-group col-12 d-flex justify-content-center mt-5">
          <label htmlFor="answerInput"></label>
          <input
            type="text"
            className="form-control w-25"
            id="answerInput"
            placeholder="Type here"
            value={value}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-12 d-flex justify-content-center mt-5">
          <UserButton
            label="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Submit
          </UserButton>
        </div>
      </form>

      {isCorrect !== null && (
        <>
          {isCorrect ? (
            <CorrectBar
              correctAnswer={correctAnswer}
              onClick={handleNextClick}
            />
          ) : (
            <IncorrectBar
              correctAnswer={correctAnswer}
              onClick={handleNextClick}
            />
          )}
        </>
      )}
    </>
  );
}

export default SentenceFIve;
