import React, { useEffect, useState } from 'react'
import ShowAnswer from './ShowAnswer';
import { returnRandomNumber } from './Fraction'
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from 'react-router-dom';

// import {
//   sentence, addTemplates
// } from 'txtgen';


//simplified answer and non simplified both correct

function Fraction_level0({ location }
) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);

  let n, i, box = [];
  const [fraction, setFraction] = useState({ n: 0, i: 0 })
  const [boxes, setboxes] = useState([]);
  const [answer, setAnswer] = useState({ num: '', den: '' });
  const [cansubmit, setCansubmit] = useState(false);
  const [simpleF, setSimpleF] = useState({ n: 0, d: 0 });
  const [submitted, isSubmitted] = useState(false);
  const [show, setShow] = useState(false);
  const [answertype, setAnswertype] = useState(false);


  useEffect(() => {
    // const templates = [
    //   '{{a_noun}} is {{a_noun}} from the right perspective',
    //   'the {{noun}} of {{a_noun}} becomes {{an_adjective}} {{noun}}'
    // ]

    // addTemplates(templates)
    // 
    createFraction();
    return () => {

    }
  }, [])


  /** find greatest common divisor */
  function gcd(a, b) {
    return b === 0 ? a : gcd(b, a % b);
  }

  const next = () => {

    if (location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
    }
    setCansubmit(false);
    isSubmitted(false);
    setShow(false);
    setAnswer({ num: '', den: '' })
    setFraction({ n: '', i: '' })
    createFraction();

  }

  const createFraction = () => {
    box = [];
    n = returnRandomNumber(2, 20);
    i = returnRandomNumber(1, n);
    for (var x = 0; x < n; x++)
      box.push(x);

    const simplifiedval = gcd(n, i);
    const simpleN = n / simplifiedval;
    const simpleD = i / simplifiedval;
    
    setSimpleF({ n: simpleN, d: simpleD });
    setboxes(box);
    setFraction({ n, i });
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    setAnswer({
      ...answer,
      [name]: parseInt(value)
    });
    setCansubmit(true);

  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const { num, den } = answer;
    if ((num) === fraction.i && (den) === fraction.n) {
      setAnswertype(true);

    } else setAnswertype(false);
    isSubmitted(true);
    setShow(true);
    if (location.state.homework) {
      dispatch(updateHomeWork({
        module: location.state.topic, id: location.state.id, correct: answertype
      }))
    }
    else {
      await dispatch(submitResult({
        result: answertype ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
      }))
    }
    setAttempted(attempted + 1);

  }

  return (
    <>
      <div className='d-flex flex-column flex-wrap mt-5 align-items-center justify-content-center'>
        <div
          className='align-items-center justify-content-center'
          style={{
            maxWidth: '80%', display: 'grid',
            gridTemplateColumns: 'repeat(10, 1fr)',
            placeItems: 'center'
          }}>
          {
            boxes.map((box, index) =>
            (
              <>
                <div style={{ minHeight: '60px', minWidth: '60px' }}
                  className={`border border-dark ${index < fraction.i ? 'bg-secondary bg-opacity-25' : 'bg-light'}`}>
                </div>
              </>
            ))

          }
        </div>
        <div style={{
          fontSize: '20px'
        }}
          className='d-flex mt-4 gap-2  align-items-center justify-content-center'>
          <p>Write the fraction representation in picture:  </p>
          <span className='d-flex flex-column align-items-center justify-content-center'>
            <input
              name='num'
              onChange={onChange}
              type='number'
              value={answer.num}
              style={{ maxWidth: `${8}ch`, height: '4ch', border: 'none', textAlign: 'center' }}
            />
            <div style={{ minWidth: '10ch', border: '1px solid black' }}></div>
            <input
              name='den'
              type='number'
              onChange={onChange}
              value={answer.den}
              style={{ maxWidth: `${8}ch`, height: '4ch', border: 'none', textAlign: 'center' }} />
          </span>
        </div>
        <button
          disabled={!(!submitted && (answer.num && answer.den))}
          onClick={onSubmit}
          type='submit'
          className="btn btn-dark mb-3 mt-3 px-4"
        >
          Submit
        </button>



      </div>
      {
        show &&
        <ShowAnswer next={next} answer={answertype}>
          <div style={{ fontSize: '20px' }} className='d-flex gap-2  align-items-center justify-content-center'>
            <p>The fraction representation in picture is:  </p>
            <span className='d-flex flex-column align-items-center justify-content-center'>
              <input
                name='num'
                disabled
                onChange={onChange}
                type='number'
                value={fraction.i}
                style={{ maxWidth: `${8}ch`, height: '4ch', border: 'none', textAlign: 'center' }}
              />
              <div style={{ minWidth: '10ch', border: '1px solid black' }}></div>
              <input
                name='den'
                disabled
                type='number'
                onChange={onChange}
                value={fraction.n}
                style={{ maxWidth: `${8}ch`, height: '4ch', border: 'none', textAlign: 'center' }} />
            </span>
          </div>
        </ShowAnswer>
      }
    </>
  )
}

export default Fraction_level0