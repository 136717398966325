import React from 'react'

const IncorrectBarMagicSq = ({answer,...props}) => {
    const array = answer.length > 5 ? [[],[],[]] : [[],[]];
    
    let index = 0;
    for(let i =0; i<array.length;i++){
        for(let j=0;j<array.length;j++){
            array[i][j] = answer[index];
            index+=1;
        }

    }
    
  return (
    <div className=" mx-3">
      <nav className="d-flex navbar mb-2 incorrect">
        <div className="nav-item mx-2 fs-5" style={{ color: "white" }}>
          <i class="fa-solid fa-xmark mx-1"></i> Wrong !
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <button className="btn btn-light mx-2"
           onClick={props.onClick}
           >
            Next
          </button>
        </div>
      </nav>
    <div className="d-flex justify-content-center">

    <div>
          <table className="table table-bordered">
            <tbody>

              {
              array.map((item, i) => 
              ( <tr key={i}>
                {item.map((data,j) => 
                    (
                      <td
                       key={`${i}+${j}`}
                        // className={`form-control text-center`}
                        // style={{ height: 50, width: 50, fontSize: "30px"}}
                       >
                        {/* {data} */}
                        <input
                        type="number"
                        className="form-control text-center"
                        style={{ height: 40, width: 40, fontSize: "25px"}}
                        min="1"
                        // max={targetSum - 1}
                        value={data}
                        // onChange={(event) => handleGridChange(i, j, event)}
                      />
                      </td>)
                      
                      )}
              </tr>)
                )
              }
            </tbody>
          </table>
        </div>
    </div>
    </div>
  )
}

export default IncorrectBarMagicSq