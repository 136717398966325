import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import Navbar from "./Component/Navbar/Navbar";
import components from "./exports";
import html2canvas from 'html2canvas';

import "./app.css"
function App() {

  const [width, setWidth] = useState(window.innerWidth);
  const channel = new BroadcastChannel('my-app-channel');
  
  // channel.postMessage('another-tab');
  // // note that listener is added after posting the message

  const handleMessage = (event) => {
    
    if (event.data === 'ping') {
      channel.postMessage('pong');
    }
  };

  const ref = useRef(null);

  const takeScreenshot =async  () => {
    const element = ref.current; // Replace 'element-id' with the ID of the element you want to take a screenshot of
    let imageData;
    await html2canvas(element).then((canvas) => {
      const screenshot = canvas.toDataURL();
      imageData = screenshot;
      // Do something with the screenshot, like display it in an <img> tag
    });

    return imageData;

  }

  useEffect(() => {
    window.addEventListener('resize', (e) => {
      setWidth(window.innerWidth);
    })
    channel.addEventListener('message', handleMessage);

    return () => {
      channel.removeEventListener('message', handleMessage);
    };
  }, [])
  

  return (
    <BrowserRouter>
    <div
    // style={{
    //   minHeight: '100vh',
    //   minWidth: '100vw'
    // }}
     ref={ref}>
    <Navbar takeScreenshot={takeScreenshot}/>
     {
      width > 768 ? <>
       <Routes>
        {components.map((c) => (
          <Route key={c.path} exact path={c.path} element={<c.component />} />
        ))}
      </Routes>
      </> : <h1>Login with Desktop</h1>
     }
    </div>
    </BrowserRouter>
  );
}

export default App;