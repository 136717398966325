import React, { useState, useEffect, useRef } from 'react'
import WhiteBar from '../coreComponents/WhiteBar';
import { useLocation } from "react-router-dom";
import CorrectBar from '../coreComponents/CorrectBar';
import IncorrectBar from "../coreComponents/IncorrectBar";
import { level1, level2, level3, level4, level5, level6 } from './cartlist';
import Level6 from './Level6';

import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";

/**
 * 
  Level 0 : items with price 1-20; 2 items ; stationery 
  Level 1 : items with price 0-20;2-3 items;  small grocery 
  Level 2 : items with price 0-100; 2-3 items;  fruits and vegetables 
  Level 3: items with price 0-500; 2-4 items ; clothes, include remaining amount to return
  Level 4: items with price 0-1000; 2-4 items ; furniture, include remaining amount to return
  Level 5 : items with price 0-50000; 2-4 items ; electronics , include remaining amount to return 
  0 1 2 no remaining balance
  3 4 5 remaining balance
  highlight wrong thing

  https://www.figma.com/community/file/1219934284401468431/School-website orange 1
  https://www.figma.com/community/file/1105069539354994759/School-website with picture landing

 */


export const randomNum = (min, max, multiple) => {
  /** return random number between min and max */
 
  const num = Math.floor(Math.random() * (max - min + 1) + min);
  return multiple ?  Math.floor(num/100) * 100 : num;
}


function ShoppingCart() {
  const dispatch = useDispatch();

  const selectRefs = useRef([]);
  const location = useLocation();
  const level = parseInt(location.state.level);
  const [items, setItems] = useState([]);
  const [chooseItems, setChooseItems] = useState();
  const [currValue, setCurrValue] = useState();
  const [answer, setAnswer] = useState([]);
  const [total, setTotal] = useState(null);
  const [inputTotal, setinputTotal] = useState({ val: '', wrong: false });
  const [balance, setBalance] = useState({ val: '', wrong: false });
  const [show1, setShow1] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [answertype, setAnswerType] = useState(false);

  useEffect(() => {


    generateList();

    return () => {

    }
  }, [])

  const getItems = () => {

    switch (level) {
      case 0: return { items: [...level1, ...level2], n: randomNum(2, 2,false) };
      // items with price 0-20;2-3 items;  small grocery 
      case 1: return { items: level3, n: randomNum(2, 3,false) };
      // Level 3: items with price 0-500; 2-4 items ; clothes, include remaining amount to return
      case 2: return { items: level4, n: randomNum(2, 3,false) };
      case 3: return { items: level5, n: randomNum(2, 4,false) };;
      // Level 4: items with price 0-1000; 2-4 items ; furniture, include remaining amount to return
      case 4: return { items: level6, n: randomNum(2, 4,false) };
      case 5: return { items: level6, n: randomNum(2, 4,false)};
      // default: return { items: level6, n: randomNum(2, 4) }
    }

  }

  const generateList = () => {

    selectRefs.current.forEach((selectRef) => {
      if (selectRef) {
        selectRef.selectedIndex = 0;
      }
    });

    let mItem = [], ans = 0;

    const { items, n } = getItems();
    const itemS = [...items];
    answer.splice(0, answer.length)
    

    for (let i = 0; i < n; i++) {
      let index = randomNum(1, itemS.length - 1);
      const temp = {
        quantity: randomNum(1, 5),
        ...itemS[index],
        originindex: index
      };
      answer.push([]);
      mItem.push(temp);
      ans += (temp.quantity * temp.price)
      itemS.splice(index, 1);
      
    }

    //total is total amount and amount needed
    setTotal({ total: randomNum(ans, Math.pow(10, String(ans).length), true), ans: ans });
    setAnswer(answer)
    setItems(items);
    setCurrValue(answer);
    setChooseItems(mItem);
    
  }

  const onChangeSelect = (e, index) => {
    
    //
    const value = items.find(item => item.name === e.target.value)
    
    setCurrValue(currValue.map((a, i) => {
      if (i === index) return value
      else return a
    }))
  }

  const onChange = (e, index, b) => {
    const value = e.target.value;

    if (b) {
      setBalance(parseInt(value));
      return
    }

    setAnswer(answer.map((a, i) => {
      if (i === index) return { ...currValue[index], cost: value.length ? parseInt(value) : '' }
      return a
    }))

    
  }


  const onSubmit1 = async () => {

    if (!show1 && level >= 3) {
      setShow1(true);
      return
    }

    let wrong = false;

    setChooseItems(chooseItems.map((item, index) => {

      let originItem = answer[index], wrongPrice, wrongSelec;
      wrongSelec = wrongPrice = false;

      if (originItem.name !== item.name) {
        wrong = true;
        wrongSelec = true;
      }
      if (item.price * item.quantity !== originItem.cost) {
        wrong = true;
        wrongPrice = true;
      }
      return { ...item,        
        wrongPrice,
        wrongSelec }

    }));

    if (inputTotal.val !== total.ans) {
      wrong = true;
      setTotal({
        ...total,

      })
    }

    if (level > 2) {
      if (balance.val !== (total.total - total.ans)) {
        wrong = true;
        setBalance({
          ...balance,
          wrong: wrong
        })
      }
    }

    
    setShowAnswer(true);
    setAnswerType(!wrong);
    await dispatch(submitResult({result: answertype ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
    
  }


  const next = () => {

    setItems(null)
    setChooseItems(null)
    setCurrValue(null)
    setAnswer(null)
    setTotal(null)
    setinputTotal({ wrong: false, val: '' })
    setBalance({ wrong: false, val: '' })
    setShow1(false)
    setShowAnswer(false)
    setAnswerType(false)

    generateList();

  }

  return (
    <>
      <WhiteBar label={'Shopping Cart'} level={location.state.level} />
      {
        level < 5 ?
          <>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <div style={{ padding: '0 100px', gap: '100px' }} className='pt-3 d-flex justify-content-center align-items-center'>
                {/** draw table */}
                <div style={{ width: '50%', maxHeight: '80vh', overflowY: 'scroll' }} className='bg-light px-8'>
                  <table className="m-0 table-borderless table-light table border border-dark ">
                    <thead className='border border-dark' style={{ position: 'sticky'}}>
                      <tr>
                        <th className='border-end border-dark'></th>
                        <th className='border-end border-dark'>Name</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        items && items.map((item, index) => (
                          <>
                            <tr key={index}>
                              <td className='border-end border-dark'>{index + 1}</td>
                              <td className='border-end border-dark'>{item.name}</td>
                              <td>Rs. {item.price}</td>
                            </tr>
                          </>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
                {/** draw input form */}
                <div className='d-flex text-center justify-content-center flex-column align-items-center gap-2'>
                  <p className='m-0' style={{ fontSize: '25px' }}>Your have to buy {
                    chooseItems && chooseItems.map((item, index) => (
                      <>
                        {`${item.quantity} ${item.name}${index < chooseItems.length - 1 ? ',' : ''}   `}
                      </>
                    ))
                  }. How much will you pay?</p>
                  <table style={{ maxWidth: '80%' }} className="m-0 table-light table table-bordered border-dark">
                    <thead>
                      <tr>
                        <th>Items</th>
                        <th>Cost</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        chooseItems && chooseItems.map((item, index) => (
                          <>
                            <tr key={index}>
                              <td >
                                <select ref={(el) => (selectRefs.current[index] = el)}
                                  onChange={(e) => onChangeSelect(e, index)} name={`${item.name}select`}
                                  class={`form-select form-select mb-0 ${showAnswer && item.wrongSelec ? 'border-2 border-danger' : ''}`}
                                  aria-label=".form-select-lg example">
                                  <option defaultChecked>--select--</option>
                                  {
                                    items.map(i => <option value={i.name}>{i.name}</option>)
                                  }
                                </select>
                              </td>
                              <td>
                                <input
                                  className={`form-control m-0 ${showAnswer && item.wrongPrice ? 'border-2 border-danger' : ''}`}
                                  value={answer[index].cost || ''}
                                  onChange={(e) => {
                                    onChange(e, index);
                                  }} name={`${item.name}`}
                                />
                              </td>
                            </tr>
                          </>
                        ))
                      }
                      <tr>
                        <td><p style={{ fontSize: '20px' }} className='m-0'>Total</p></td>
                        <td>
                        <input name='total'
                        className={`form-control m-0 ${showAnswer && total.wrong ? 'border-2 border-danger' : ''}`}
                        value={inputTotal.val}
                        onChange={(e) => {
                          setinputTotal({ ...inputTotal, val: e.target.value.length ? parseInt(e.target.value) : '' })
                        }} /> </td>
                      </tr>
                    </tbody>
                  </table>
                  {/** print question 1 */}
                  {
                    (show1 && level >= 3) && (
                      <>
                        <div>
                          <p style={{ fontSize: '25px' }}>
                            If you have {total.total}. How much money will shopkeeper return?
                          </p>
                          <div className='d-flex flex-column align-items-center justify-content-center'>
                            <input
                              className={`form-control m-0 ${showAnswer && balance.wrong ? 'border-2 border-danger' : ''}`}
                              value={balance.val}
                              onChange={(e) => {
                                setBalance({ ...balance, val: e.target.value.length ? parseInt(e.target.value) : '' })
                              }} style={{ maxWidth: '200px' }} name={`balance`} />
                            {showAnswer && !answertype && (
                              <>
                                <p style={{ fontSize: '25px' }} className='text-danger'>
                                  You will have Rs. {total.total - total.ans} left, and you need Rs. {total.ans} to pay</p>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>

              <button className='btn btn-dark mt-3'
                onClick={onSubmit1}>
                Submit
              </button>

            </div>
          </> :
          <Level6 />
      }
      {/** show answer */}
      {
        showAnswer && (
          answertype ? <CorrectBar onClick={next} /> : <IncorrectBar onClick={next} />
        )
      }
    </>
  )
}

export default ShoppingCart