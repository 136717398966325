import React, { useEffect, useRef} from "react";
import Sketch from "react-p5";
import P5 from 'p5';
/**
 * p5 reference
 * https://p5js.org/reference/
 */



let x = 50;
let y = 50, bckg;
const SCALE = 50;
var startx, starty, dirx, diry
let draggedRects = [], areaGrid=[];
const opacity = 255 * 0.5;

var tempx, tempy, tempw, temph;

/**
 * create a function to fill grid
 * how to do that?
 */

function generateRectWithArea(num) {
	let width = Math.floor(Math.sqrt(num));
	let height = Math.floor(num / width);
	let area = width * height;
	
	while (area !== num) {
	  if (area > num) {
		if (Math.random() < 0.5) {
		  width--;
		} else {
		  height--;
		}
	  } else {
		if (Math.random() < 0.5) {
		  width++;
		} else {
		  height++;
		}
	  }
	  area = width * height;
	}
	
	return { width, height };
  }
  

const randomNum = (min, max) => {
	/** return random number between min and max */
	return Math.floor(Math.random() * (max - min + 1) + min);
}






export default  (props) => {

	const { size} = props;
	const canvasRef = useRef(null);

	

	const Sketch = (p5) => {
		p5.setup = () => {


			/**
			 * random with and height
			 * make sure its even
			 * lets see
			 */

			// use parent to render the canvas in this ref
			// (without that p5 will render the canvas outside of your component)
	
			p5.createCanvas(SCALE * size, SCALE * size)
			const { width, height } = p5;

			for(var i = 0; i< size; i++){
				areaGrid.push([]);
				for(var j= 0; j < size; j++){
					areaGrid[i][j]=0;
				}
			}

			let areas = generateAreas();
			

			//background grid
			bckg = p5.createGraphics(width, height);
			bckg.background(255);
	
			for(var x = 0; x <= size; x++){
	
				bckg.stroke(0, 0 ,0 , 255);
				bckg.strokeWeight(.25);
				bckg.line(x * SCALE, 0, x * SCALE, height);
				bckg.line(0, x * SCALE, width,  x * SCALE);
	
			}
	
		};
	
		p5.draw = () => {
			p5.background(255);
			p5.image(bckg, 0, 0, p5.width, p5.height);
			// p5.ellipse(x, y, 70, 70);
			
			
			 drawDraggableRect(x, y , startx, starty, p5);
			 drawDraggedRects(p5);
			// NOTE: Do not use setState in the draw function or in functions that are executed
			// in the draw function...
			// please use normal variables or class properties for these purposes
		};
	
		p5.mousePressed = () => {
			
			startx = Math.floor(p5.mouseX / SCALE);
			starty = Math.floor(p5.mouseY / SCALE);
			
			dirx = diry = x = y = 0;
		}

		p5.mouseDragged =  () => {
			x = Math.floor((p5.mouseX / SCALE));
			y = Math.floor((p5.mouseY / SCALE));
			
			//dir functions determine which direction use is dragging
			dir();
		}
		p5.mouseReleased = () => {
			draggedRects.push({
				x: tempx,
				y: tempy,
				w: tempw,
				h: temph
			})
		}
	}
	
	  

	function drawDraggedRects(p5){
		//** draws rects which are dragged  */
		if(draggedRects.length) {
			draggedRects.map(rect => {

				const { x, y, w, h} = rect;
				//p5.fill(0, 0, 230, opacity);
				p5.rect(x, y, w, h);
			})
		}
	}

	function generateAreas() {
		let filledArea = 0, totalArea = Math.pow(size, 2);
		let areas = [], maxArea = totalArea / 4;

		while(filledArea < totalArea){
			let w = randomNum(1, size);
			let h = randomNum(2, size);
			let area = w * h;

			if(filledArea + area <= totalArea && area < maxArea) {
				areas.push({w, h, area});
				filledArea += area
			} else if (area < maxArea){
				area = totalArea - filledArea;
				let { width, height} = generateRectWithArea(area);
				w = width;
				h = height;
				areas.push({w, h, area});
				filledArea+=area;
			}
			
		}

		areas.sort((a, b) => b.area - a.area);

		for (let area of areas) {
			let x, y;
			for (x = 0; x <= size - area.w; x++) {
				for (y = 0; y <= size - area.h; y++) {
					
					if (checkFit(x, y, area.w, area.h, area)) {
						fillGrid(x, y, area.w, area.h, area);
						area.x = x;
						area.y = y;
						break;
					}
				}
				if (y < size - area.h) {
					break;
				}
			}
		}

		

		return areas;
	}

	function checkFit(x, y, w, h, area) {
		for (let i = x; i < x + w; i++) {
			for (let j = y; j < y + h; j++) {
				if (areaGrid[i][j] !== 0) {
					
					return false;
				}
			}
		}
		return true;
	}
	
	function fillGrid( x, y, w, h, area) {
		for (let i = x; i < x + w; i++) {
			for (let j = y; j < y + h; j++) {
				areaGrid[i][j] = area;
			}
		}
	}

	//function to draw rectangles dragged by the user
	function drawDraggableRect(x, y, startx, starty, p5) {
	
		//draws the dragging rectangle
		tempx = startx * SCALE;
		tempy = starty * SCALE;
		tempw = ((startx - x) * dirx) * SCALE;
		temph = ((starty - y)* diry) * SCALE

		p5.stroke(0, 0, 230, opacity);
		p5.strokeWeight(4);
		p5.noFill();
		p5.rect(tempx, tempy, tempw, temph);

	}


	function dir(x, y, mouseX, mouseY){
		if(mouseX - x > 0)
		dirx = 1;
		else
		dirx = -1;
		if(mouseY - y > 0)
		diry = +1;
		else
		diry = -1;
	}

	useEffect(() => {
      
		const can = new P5(Sketch, canvasRef.current);
		 return () => {
		   can.remove()
		 }
	   }, [])

    return (
		<div ref={canvasRef}></div>
	)
}


// const findArea = () => {
// 	let filledArea = 0, totalArea = Math.pow(size, 2);
// 	let areas = [], maxArea = totalArea / 4;

// 	// while(filledArea < totalArea) {
// 		let w = randomNum(1, size);
// 		let h = randomNum(1, size);
// 		let area = w * h;

// 		if(filledArea + area <= totalArea && area < maxArea) {
// 			if(CanBePlaced(w, h, area)){
// 				areas.push({w, h, area})
// 				filledArea += area
// 			}
// 		} else if(area < maxArea) {
// 			area = totalArea - filledArea;
// 			let { width, height} = generateRectWithArea(area);
// 			w = width;
// 			h = height;
// 			if(CanBePlaced(w, h, area)){
// 				areas.push({w, h, area})
// 				filledArea += area
// 			}
// 	// 	}
		
// 		
// 	}

// 	
// }

// function CanBePlaced(w, h , area){
// 	let empty = false;
// 	for(var i = 0; i < size; i++) {
// 		for(var j = 0; j < size; j++){
// 			if(areaGrid[i][j] === 0){
// 				empty = checkEmpty(i, j, w, h);
// 			}
// 		}
// 	}
// 	
// 	return empty;
// }

// //function to check if the space is empty
// function checkEmpty(startx, starty, w, h) {
// 	
// 	if(startx + w >= size || starty + h >= size) return false
// 	for(var i = startx; i < (startx + w) - 1; i++) {
// 		for(var j = starty; j <( starty + h) -1 ; j++){
// 			
// 			if(areaGrid[i][j] !== 0){
				
// 				return false
// 			}
// 		}
// 	}
// 	return true;
// }

// const findArea = () => {
// 	let filledArea = 0, totalArea = Math.pow(size, 2);
// 	let areas = [], maxArea = totalArea / 4;

// 	// while(filledArea < totalArea) {
// 		let w = randomNum(1, size);
// 		let h = randomNum(1, size);
// 		let area = w * h;

// 		if(filledArea + area <= totalArea && area < maxArea) {
// 			if(CanBePlaced(w, h, area)){
// 				areas.push({w, h, area})
// 				filledArea += area
// 			}
// 		} else if(area < maxArea) {
// 			area = totalArea - filledArea;
// 			
// 			if(CanBePlaced(w, h, area)){
// 				areas.push({w, h, area})
// 				filledArea += area
// 			}
// 	// 	}
		
// 		
// 	}

// 	
// }

// function fillDefault() {
// 	for(var i = 0; i < size; i++){
// 		areaGrid.push([]);
// 		for(var j = 0; j < size; j++){
// 			areaGrid[i][j] = 0;
// 		}
// 	}
// 	
// } 



// const { mouseX, mouseY } = p5;
// 			// p5.mousePressed = (e) => {
// 			// 	startx = Math.floor((mouseX / SCALE));
// 			// 	starty = Math.floor((mouseY / SCALE));;
// 			// 	dirx = diry = x = y = 0;
// 			// }
	
// 			// p5.mouseDragged =  (event) => {
// 			// 	x = Math.floor((mouseX / SCALE))
// 			// 	y = Math.floor((mouseY / SCALE));
// 			// 	//dir functions determine which direction use is dragging
// 			// 	dir();
// 			// }
	
// 			// p5.mouseReleased = (event) =>{
// 			// 	/** store the dragged rect in the dragged rect array */
// 			// 	draggedRects.push({ 
// 			// 		x: tempx,
// 			// 		y: tempy,
// 			// 		w: tempw,
// 			// 		h: temph
// 			// 	})
// 			// 	startx = 0;
// 			// 	starty = 0;
// 			// 	x = 0;
// 			// 	y = 0;
	
// 			// }