import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APP_API } from "../utils/config";
import { RANDOM_WORDS } from "../utils/config";
import axios from "axios";
export const fetchWords = createAsyncThunk(
  "word/fetchWords",
  async function fetchData({ n, level, type }) {
    try {
      let url;
      if (type === "ListenAndWrite"||"scramble") {
        url = `${APP_API+RANDOM_WORDS}/${n}/${level}`;
      } else if (type === "ListenAndWriteSentence") {
        url = `${APP_API+RANDOM_WORDS}/${n}/${level}`;
      }
      
      const response = await axios.get(url, {
        headers: {
          "Content-type": "application/json",
          "x-auth-token": `${localStorage.getItem("token")}`,
        },
      });
      
      return response.data.wordDb;
    } catch (error) {
      
    }
  }
);



const wordSlice = createSlice({
  name: "word",
  initialState: {
    words: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWords.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWords.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.words = action.payload; // use "action.payload" instead of "action"
        
      })
      .addCase(fetchWords.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default wordSlice.reducer;