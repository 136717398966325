import React, { useState, useEffect } from 'react'
import { returnRandomNumber } from './Fraction';
import ShowAnswer from './ShowAnswer';
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from 'react-router-dom';


const MAX_NUM = 40;
const MAX_DEN = 20;


function Fraction_level4({ level, location }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);

  let tempf1, tempf2, sol;
  const [f1, setf1] = useState({ n: 0, d: 1 });
  const [f2, setf2] = useState({ n: 0, d: 1 });
  const [solution, setSolution] = useState({ n: 0, d: 1 });
  const [answer, setAnswer] = useState({ n: '', d: '' });
  const [submitted, isSubmitted] = useState(false);
  const [simpleF, setSimpleF] = useState({ n: 0, d: 0 });
  const [cansubmit, setCansubmit] = useState(false);
  const [show, setShow] = useState(false);
  const [answerType, setAnswerType] = useState(false);

  useEffect(() => {
    generateNumber();

    return () => {

    }
  }, [])

  /** find greatest common divisor */
  function gcd(a, b) {
    return b === 0 ? a : gcd(b, a % b);
  }

  const generateNumber = () => {

    tempf1 = tempf2 = {};
    tempf1 = returnRandomFraction();
    tempf2 = returnRandomFraction();
    sol = findSol(tempf1, tempf2);

    const simplifiedval = gcd(sol.n, sol.d);
    const simpleN = sol.n / simplifiedval;
    const simpleD = sol.d / simplifiedval;
    
    setSimpleF({ n: simpleN, d: simpleD });
    setf1(tempf1)
    setf2(tempf2)
    setSolution(sol)
    //
    // await add();
  }

  const findSol = (f1, f2) => {

    let tempsol = {}
    if (level === 4) {
      if (f1.w && f2.w) {
        tempsol = { n: f1.i * f2.i, d: f1.d * f2.d };


      }
      else if (f1.w) {
        tempsol = { n: f1.i * f2.n, d: f1.d * f2.d };

      }
      else if (f2.w) {
        tempsol = { n: f2.i * f1.n, d: f1.d * f2.d };

      }
      else {
        tempsol = { n: f2.n * f1.n, d: f1.d * f2.d };

      }
    } if (level === 5) {
      if (f1.w && f2.w) {
        tempsol = { n: f1.i * f2.d, d: f1.d * f2.i };
      }
      else if (f1.w) {
        tempsol = { n: f1.i * f2.d, d: f1.d * f2.n };

      }
      else if (f2.w) {
        tempsol = { n: f2.d * f1.n, d: f1.d * f2.i };

      }
      else {
        tempsol = { n: f2.d * f1.n, d: f1.d * f2.n };

      }
    }

    return tempsol;
  }

  /** refactor random fraction genrator
   * 
   */

  const returnRandomFraction = () => {
    let tempFrac = {}, w, n, d;
    n = returnRandomNumber(1, MAX_NUM);
    d = returnRandomNumber(1, MAX_DEN);
    if (returnRandomNumber(1, 3) === 3) {
      w = d < 10 ? returnRandomNumber(1, 10) : returnRandomNumber(1, 5);
    }
    let improper = n + (w * d);

    if (w && improper > MAX_NUM) return returnRandomFraction()

    tempFrac = {
      n: n, d: d, w: w, i: improper
    }

    return tempFrac;
  }



  const onChange = (e) => {
    const { name, value } = e.target;
    setAnswer({
      ...answer,
      [name]: value,
    })
    setCansubmit(true);
  }

  const onSubmit = async () => {
    
    if (parseInt(answer.n) === solution.n && parseInt(answer.d) === solution.d) {
      
      setAnswerType(true);
    }
    else setAnswerType(false);
    isSubmitted(true);
    setShow(true);
    await dispatch(submitResult({result: answerType ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))

    if(location.state.homework){
      dispatch(updateHomeWork({
       module: location.state.topic, id: location.state.id, correct: answerType}))    
    }
    else {
        await dispatch(submitResult({
        result: answerType ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
    }
    setAttempted(attempted + 1);
  }

  const next = () => {
    if(location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
    }
    setShow(false);
    isSubmitted(false);
    setAnswer({ n: '', d: '' });
    setf1({ n: 0, d: 1 });
    setf2({ n: 0, d: 1 });
    setSolution({ n: 0, d: 1 });
    setCansubmit(false);
    generateNumber();
  }


  return (
    <>
      <div style={{ fontSize: '30px' }} className='d-flex flex-column flex-wrap mt-5 align-items-center justify-content-center'>
        {/** render question */}
        <div className='d-flex gap-2 align-items-center justify-content-center'>
          <div className='d-flex align-items-center gap-2  bg-light  p-2'>
            {f1?.w ? f1?.w : null}
            <span className='text-center d-flex flex-column align-items-center justify-content-center'>
              <p className='m-0 p-0'>{f1?.n}</p>
              <div style={{ height: '2px', border: '1px solid black', width: '100%' }}></div>
              <p className='m-0 p-0'> {f1.d}</p>
            </span>
          </div>
          {level === 4 ? 'x' : '÷'}
          <div className='d-flex align-items-center gap-2  bg-light  p-2'>
            {f2.w && f2.w}
            <span className='text-center d-flex flex-column align-items-center justify-content-center'>
              <p className='m-0 p-0'>{f2.n}</p>
              <div style={{ height: '2px', border: '1px solid black', width: '100%' }}></div>
              <p className='m-0 p-0'> {f2.d}</p>
            </span>
          </div>
          {'='}
          { /** render input values */}
          <div className='d-flex align-items-center gap-2  bg-light  p-2'>
            <span className='text-center d-flex flex-column align-items-center justify-content-center'>
              <input
                style={{ width: `${String(solution.n).length}ch` }}
                type="number"
                disabled={submitted}
                onChange={onChange}
                value={answer.n}
                name={`n`}
                className="text-center align-items-center bg-light pair-input p-0 m-0"
              ></input>
              <div style={{ height: '2px', border: '1px solid black', width: '100%' }}></div>
              <input
                style={{ maxWidth: '50px' }}
                type="number"
                disabled={submitted}
                onChange={onChange}
                value={answer.d}
                name={`d`}
                className="text-center align-items-center bg-light pair-input p-0 m-0"
              ></input>
            </span>
          </div>

        </div>
        <button
          disabled={!cansubmit || !(answer.n && answer.d) || submitted}
          onClick={onSubmit}
          type='submit'
          className="btn btn-dark px-4 mt-4"
        >
          Submit
        </button>
      </div>
      {
        show && <ShowAnswer answer={answerType} next={next}>
          <div style={{ fontSize: '25px' }} className='d-flex gap-2 align-items-center justify-content-center'>
            <div className='d-flex align-items-center gap-2  bg-light  p-2'>
              {f1?.w ? f1.w : null}
              <span className='text-center d-flex flex-column align-items-center justify-content-center'>
                <p className='m-0 p-0'>{f1.n}</p>
                <div style={{ height: '2px', border: '1px solid black', width: '100%' }}></div>
                <p className='m-0 p-0'> {f1.d}</p>
              </span>
            </div>
            {level === 4 ? 'x' : '÷'}
            <div className='d-flex align-items-center gap-2  bg-light  p-2'>
              {f2.w && f2.w}
              <span className='text-center d-flex flex-column align-items-center justify-content-center'>
                <p className='m-0 p-0'>{f2.n}</p>
                <div style={{ height: '2px', border: '1px solid black', width: '100%' }}></div>
                <p className='m-0 p-0'> {f2.d}</p>
              </span>
            </div>
            {'='}
            <div className='d-flex align-items-center gap-2  bg-light  p-2'>
              <span className='text-center d-flex flex-column align-items-center justify-content-center'>
                <input
                  style={{ width: `${String(solution.n).length}ch` }}
                  type="number"
                  disabled={submitted}
                  onChange={onChange}
                  value={solution.n}
                  name={`n`}
                  className="text-center align-items-center bg-light pair-input p-0 m-0"
                ></input>
                <div style={{ height: '2px', border: '1px solid black', width: '100%' }}></div>
                <input
                  style={{ maxWidth: '50px' }}
                  type="number"
                  disabled={submitted}
                  onChange={onChange}
                  value={solution.d}
                  name={`d`}
                  className="text-center align-items-center bg-light pair-input p-0 m-0"
                ></input>
              </span>
            </div>
            {/** show simplified value */}
            
            {'or'}
            <div className='d-flex align-items-center gap-2  bg-light  p-2'>
              <span className='text-center d-flex flex-column align-items-center justify-content-center'>
                <input
                  style={{ maxWidth: '50px' }}
                  type="number"
                  disabled={submitted}
                  onChange={onChange}
                  value={simpleF.n}
                  name={`n`}
                  className="text-center align-items-center bg-light pair-input p-0 m-0"
                ></input>
                <div style={{ height: '2px', border: '1px solid black', width: '100%' }}></div>
                <input
                  style={{ maxWidth: '50px' }}
                  type="number"
                  disabled={submitted}
                  onChange={onChange}
                  value={simpleF.d}
                  name={`d`}
                  className="text-center align-items-center bg-light pair-input p-0 m-0"
                ></input>
              </span>
            </div>
          </div>
        </ShowAnswer>
      }
    </>
  )
}

export default Fraction_level4