import Login from "./Pages/Login/Login";
import Practice from "./Pages/Practice/Practice"
// import Practice from "./Component/Practice";
import Delete from "./Component/Forms/Delete";
import Homepage from "./Pages/homepage/Homepage";
import Homework from "./Pages/Homework/HomeWorkAss";
import AdminForm from "./Component/Forms/AdminForm";
import StudentForms from "./Component/Forms/StudentForm";
import Performance from "./Pages/Performance/Performance";
import CoverModal from "./Component/coreComponents/CoverModal";
import AddWord from "./Pages/Admin/AddWord";
import ShoppingCart from "./Component/Shopping Cart/ShoppingCart";

import Addition from "./Component/Addition/Addition";
import Subtraction from "./Component/Subtraction/Subtraction";
import PairPattern from "./Component/PairPattern/PairPattern";
import MentalMaths from "./Component/MentalMaths/MentalMaths";
import NumberNames from "./Component/NumberName/NumberName";

import MissingMultiplication from "./Component/MissingMultiplication/MissingMultiplication";
import Multiplication from "./Component/Multiplication/Multiplication";
import MagicSquare from "./Component/MagicSquare/MagicSquareThree";
import ArithmeticProgression from "./Component/SkipCounting/SkipCounting";
import MultiplicationFactors from "./Component/MultiplicationFactors/MultiplicationFactors";

import Tables from "./Component/MultiplicationTable/MultiplicationTable";
import Clock from "./Component/Clock/Clock";
import Division from "./Component/Division/Division";
import MissingDivision from "./Component/MissingDivision/MissingDivision";
import Shikaku from "./Component/Shikaku/Shikaku";

import NumberLine from "./Component/NumberLine/NumberLine";
import Add2Tables from "./Component/Add2Tables/Add2Tables";
import SkipCountingAP from "./Component/SkipCountingAP/SkipCountingAP";
import MagicTriangle from "./Component/MagicTriangle/MagicTriangle";
import ChangingMoney from "./Component/ChangingMoney/ChangingMoney";

import Fractions from "./Component/Fraction/Fraction";
import { withAuth } from "./Auth/withAuth";
import SkipCounting from "./Component/SkipCounting/SkipCounting";
import AreaAndPerimeter from "./Component/Area&Parameter/AreaPerimeter";
import ScrambleOne from "./English/Scramble/ScrambleOne";
import WordSearch from "./English/WordSearch/WordSearch";
import ListenWord from "./English/ListenAndWrite/ListenWordOne";
import ListenAndWrite from "./English/ListenAndWrite/ListenAndWrite";
import Sentence from "./English/ListenAndWriteSentence/Sentence";
import CrossWord from "./English/CrossWord/CrossWord";
import AddItemForm from "./Pages/Admin/AddItem";
import ViewHomeWork from './Pages/Homework/ViewHomeWork';
import ErrorReport from './Pages/Error/ErrorReport';
import ViewStudentsHomework from './Pages/Homework/ViewStudentsHomework';
import ManageHomework from "./Pages/Homework/ManageHomework";

const topics = [
    {comp: Addition, top: "Addition",},
    {comp: Subtraction, top: "Subtraction",},
    {comp: SkipCounting, top: "SkipCounting",},
    {comp: NumberNames, top: "NumberNames",},
    {comp: MagicTriangle, top: "MagicTriangle",},
    {comp: MagicSquare, top: "MagicSquare",},
    {comp: Clock, top: "Clock",},
    {comp: Tables, top: "Tables",},
    {comp: Multiplication, top: "Multiplication",},
    {comp: PairPattern, top: "PairPattern",},
    // {comp: Nonogram, top: " Nonogram",},
    {comp: Shikaku, top: "Shikaku",},
    {comp: SkipCountingAP, top: "SkipCountingAP",},
    {comp: ChangingMoney, top: "ChangingMoney",},
    // {comp: // ShoppingCart, top: "// ShoppingCart",},
    {comp: NumberLine, top: "NumberLine",},
    {comp: MentalMaths, top: "MentalMaths",},
    {comp: MissingMultiplication, top: "MissingMultiplication",},
    {comp: Division, top: "Division",},
    {comp: Fractions, top: "Fractions",},
    // {comp: // Kakuro, top: "// Kakuro",},
    {comp: Add2Tables, top: "Add2Tables",},
    {comp: MissingDivision, top: "MissingDivision",},
    {comp: AreaAndPerimeter,  top: "AreaAndPerimeter"},
    {comp: ScrambleOne,  top: "Scramble"},
    {comp: WordSearch,  top: "WordSearch"},
    {comp: ListenAndWrite,  top: "ListenAndWrite"},
    {comp: Sentence,  top: "ListenAndWriteSentence"},
    {comp: CrossWord,  top: "CrossWord"},
    {comp: ShoppingCart,  top: "ShoppingCart"}
    // {comp: AddWord,  top: "AddWord"}
  ]
const components = [
    { path: "/", component: withAuth(Login) },
    { path: "/homepage", component: withAuth(Homepage) },
    { path: "/homepage/performance", component: withAuth(Performance) },
    { path: "/homepage/register", component:withAuth( StudentForms) },
    { path: "/homepage/delete", component: withAuth(Delete )},
    { path: "/homepage/registerAdmin", component: withAuth(AdminForm )},
    { path: "/homepage/practice", component: withAuth(Practice) },
    { path: "/homepage/addWord", component: withAuth(AddWord) },
    { path: "/homepage/addItem", component: withAuth(AddItemForm) },
    { path: "/homepage/assignHomework", component: withAuth(Homework) },
    { path: "/homepage/homework", component: withAuth(ViewHomeWork) },
    { path: "/error/ErrorReport", component: withAuth(ErrorReport) },
    { path: "/homepage/viewHomework", component: withAuth(ViewStudentsHomework) },
    { path: "/homepage/manageHomework", component: withAuth(ManageHomework) },
  ];
  topics.map(({comp,top})=>{
    components.push({path: `/homepage/practice/${top}`, component:withAuth(comp)})
})


export default components