import React from 'react'

const Solution = ({a,b}) => {
    let content;
    // const type = (a,b) => {
        if (b === 99 || b===999) {

            if (a.toString().length === b.toString().length) {
                content = <div>
                    {a} is a {a.toString().length} digit number and {b} also has {b.toString().length} 9's in it.
                    <br/>
                    For LHS, subtract 1 from {a}, i.e. {a} - 1 = {a-1}
                    <br/>
                    For RHS, write complement of {a}, i.e. {b + 1 - a}
                    <br/>
                    So, the Answer is {a-1}{b+1-a}
                </div>
            } else if (a.toString().length < b.toString().length) {
                content = <div>
                    Here the multiplicand {a} has {a.toString().length} digits and multiplier {b} has {b.toString().length} digits,
                    <br/>
                    so in this case, there is {(b.toString().length - a.toString().length)} 9 extra in the multiplier.
                    <br/>
                    The answer can be arrived directly as:
                    <br/>
                    = {a-1}/{Math.pow(10,(b.toString().length - a.toString().length))-1}/{Math.pow(10,(a.toString().length))-a}
                    <br/>
                    = {a-1}{Math.pow(10,(b.toString().length - a.toString().length))-1}{Math.pow(10,(a.toString().length))-a}
                    
                </div>
            } else {
                const lhs1 = parseInt(a.toString().slice(0,a.toString().length - b.toString().length))
                const lhs2 = parseInt(a.toString().slice(a.toString().length - b.toString().length,a.toString().length))
                content = <div>
                    <h1>{a} X {b}</h1>
                    <p className='fs-4'>({a}-{lhs1 + 1})/({Math.pow(10,b.toString().length)}-{lhs2})</p>
                    <p>{a-lhs1 + 1}/{Math.pow(10,b.toString().length)-lhs2}</p>
                </div>
            }
        } else {
            // content = <div>sorry</div>
        let elevenArray =[];
        let solutionArray = [];
        const number = (b != 11 || b!=111) ? (a*(b/11)).toString().split("").reverse().join("") : a.toString().split("").reverse().join("");
        
        for(let i =0; i< number.toString().length + b.toString().length-1;i++){
            let sum = 0;
            let sumArray = [];
            let j = (i < b.toString().length) ? i : (i >= (number.toString().length) ? -i+number.toString().length +b.toString().length-2: b.toString().length - 1)
            // let j = i >= (a.toString().length - b.toString().length+1) ? a.toString().length - i: i
            
            
            while(j>=0){
                sumArray.push(number.toString().charAt(i < number.toString().length ? i-j : number.toString().length-j-1))
                sum = sum + parseInt(number.toString().charAt(i < number.toString().length ? i-j : number.toString().length-j-1))
                
                j= j -1;
            }
            if (elevenArray[i-1]) {
                sum = sum + parseInt(elevenArray[i-1].carry)
            }
            const digitCarry = {
                carry: sum.toString().length <= 1 ? 0 : sum.toString()[0],
                digit: (sum.toString().length <= 1 ? sum.toString()[0]:sum.toString()[1])
            }
            solutionArray.push(sumArray.reverse())
            elevenArray.push(digitCarry)
        }
        
        let finalArray = []
        content = <div>
            
            <div>
                {b===11 || b===111 ? "" :
                    <p>
                    &#40;{a} X {b/11}&#41; X {11}
                    </p>
                }
                    <p>
                        {number.toString().split("").reverse().join("")} X {11}
                    </p>
            </div>
            <div>
                {solutionArray.map(array=>{
                    return array.join('+')
                }).join('/')}
            </div>
            <div>
                {solutionArray.map(array=>{
                    return array.reduce(function(x,y) {
                        return parseInt(x) + parseInt(y)
                    },0)
                }).join('/')}
            </div>
            <div className='d-flex flex-row'>

                {elevenArray.reverse().map(({carry,digit})=>{
                    return <p><sub>{carry ? carry : ''}</sub>{digit}</p>
                })}
            </div>
            <div className='d-flex flex-row'>

                {elevenArray.map(({digit})=>{
                    return <p>{digit}</p>
                })}
            </div>
        </div>
        }
    // }
  return (
    <div>
        {content}
    </div>
  )
}

export default Solution