import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./PhotoScore.css";
import { useSelector } from "react-redux";

const PhotoScore = ({title1, title2, title3, role, score}) => {
const avatar= useSelector(state => state.user.avatar)
// const score= useSelector(state => state.result.score)
// const image = avatar.toString("base64");
// const image = btoa(String.fromCharCode(...new Uint8Array(avatar)));
  return (
    <>
      <div className="d-flex justify-content-center" style={{height: '150px'}}>
        <div className="detail d-flex align-items-center">
          <div className="photo d-flex justify-content-center my-1">
            {
                (role==="STUDENT" || role==="TEACHER") ?
                <div>
                  <img src={avatar} alt="image"/>
                </div>
                : ''

            }
          </div>
          <div className="d-flex flex-column name text-center justify-content-center align-items-center my-0">
            <p className="fs-2 my-0">{title1}</p>
            <p className="fs-4 my-0">{title2}</p>
            <p className="fs-4 my-0">{title3}</p>
          </div>
            <div className="marks d-flex flex-column justify-content-center mx-auto">
                {(score && !(role==='ADMIN' || role==='SUPERADMIN')) ?<span>

                    <div className="score d-flex flex-column justify-content-center mx-auto my-auto align-items-center">
                        <p className="p-0 my-0">
                            {score}
                        </p>
                        <h5 className="text-center p-0 my-0">Score</h5>
                    </div>
                </span>
                : ""
                }
            </div>
        </div>
      </div>
      <hr className="mb-3 opacity-10"/>
    </>
  );
};

export default PhotoScore;