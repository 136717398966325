import React from "react";

const UserButton = (props) => {
  return (
    <div>
      <button type="submit" class="btn btn-dark" onClick={props.onClick} disabled={props.disabled}>
        {props.label}
      </button>
    </div>
  );
};

export default UserButton;
