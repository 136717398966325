import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import UserButton from "../coreComponents/UserButton/UserButton";
import { Col } from "react-bootstrap";
import Navbar from "../Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import ClockModel from "./ClockModel";
import CorrectBar from "../coreComponents/CorrectBar";
import { submitResult } from "../../redux/scoreSlice";
import { useDispatch } from "react-redux";
import { updateHomeWork } from '../../redux/homeWorkSlice';
function Level1({location}) {
  const [date, setDate] = useState(new Date());
  const [hour, setHour] = useState(Math.floor(Math.random() * 12) + 1);
  const [minute, setMinute] = useState(Math.floor(Math.random() * 12) * 5);
  const [hourAnswer, setHourAnswer] = useState("");
  const [minuteAnswer, setMinuteAnswer] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState();
  const [attempted, setAttempted] = useState(location.state.attempted);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const parsedHourAnswer = parseInt(hourAnswer);
    const parsedMinuteAnswer = parseInt(minuteAnswer);
    setIsCorrect(parsedHourAnswer === hour && parsedMinuteAnswer === minute);
    setCorrectAnswer(`${hour} : ${minute === 0 ? "00" : minute}`);
    if(location.state.homework){
      dispatch(updateHomeWork({
       module: location.state.topic, id: location.state.id, correct: parsedHourAnswer === hour && parsedMinuteAnswer === minute}))    
  }
  else {
      await dispatch(submitResult({
       result: parsedHourAnswer === hour && parsedMinuteAnswer === minute ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
  }
  setAttempted(attempted + 1);
  };

  const handelReset = () => {
    setHourAnswer("");
    setMinuteAnswer("");
  };

  const handleNext = () => {
    
    if (location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
    }
    setHourAnswer("");
    setMinuteAnswer("");
    setHour(Math.floor(Math.random() * 12) + 1);
    setMinute(Math.floor(Math.random() * 12) * 5);

    setIsCorrect(null)
  };

  const incorrectBar = (
    <div className=" mx-3">
      <nav className="d-flex navbar incorrect">
        <div className="nav-item mx-2 fs-5" style={{ color: "white" }}>
          <i class="fa-solid fa-xmark"></i> Wrong !
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <button className="btn btn-light mx-2" onClick={handleNext}>
            Next
          </button>
        </div>
      </nav>
    </div>
  );

  return (
    <>
      <div className="d-flex justify-content-center">
        <h5>What is the current time?</h5>
      </div>
      <div className="d-flex">
        <div
         style={{ width: "40%" }} 
         className="text-center justify-content-end">
          <ClockModel hour={hour} minute={minute} />
        </div>
        <div
          className="d-flex ms-4 justify-content-start align-items-center"
          style={{ width: "60%" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="input-group mb-3">
              <input
                type="number"
                min={1}
                max={12}
                value={hourAnswer}
                onChange={(e) => setHourAnswer(e.target.value)}
                className="form-control text-center"
                style={{fontSize:'30px'}}
                placeholder="hh"
              />
              <div className="mx-1" style={{fontSize:'30px'}}>{`:`}</div>
              <input
                type="number"
                min={0}
                max={55}
                step={5}
                value={minuteAnswer}
                onChange={(e) => setMinuteAnswer(e.target.value)}
                className="form-control text-center"
                style={{fontSize:'30px'}}
                placeholder="mm"
              />
            </div>
          </form>
        </div>
      </div>
      <div
        className="d-flex justify-content-center gap-2"
        // style={{ marginTop: "-24px" }}
      >
        <UserButton
          className="btn btn-primary"
          label="Reset"
          onClick={handelReset}
        />

        <UserButton
          label="Submit"
          className="btn btn-primary"
          onClick={handleSubmit}
        />
      </div>
      {isCorrect && <CorrectBar onClick={handleNext}/>}
      {isCorrect === false && (
        <div>
          {/* The correct answer was {correctAnswer}. */}
          {incorrectBar}
          <div className="d-flex justify-content-center align-items-center">
           
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "70%" }}
            >
              <div className="d-flex container-color mt-3 mx-3 w-50 ">
                <Col xs={12}>
                  <div className="text-center">
                    <div className="py-2 mx-2"  style={{fontSize:'30px'}}>{correctAnswer}</div>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Level1;
