import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import './NumberLine.styles.css';
import ShowAnswer from './ShowAnswer';
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';


/**
 * We will use AP to challenge children brain , we will do this differently by using number line and AP.
Level 0 [3- 5 divisions] -  0 < A < 10 ; ; 1< d < 2      display 1st number and last number.  Show dot(circle) for the number  to be answered.  

Level 1 [3-6 divisions] - 
no number should be negative; 0< smallest number < 10; -3 <  d < 3 not equal to 0. 
display 1st number and last number.  Show dot(circle) for the number  to be answered. 

Level 2 [3-6 divisions] - 
no number should be negative; 0< smallest number < 30; -5 <  d < 5 not equal to 0. 
display 1st number and last number.  Show dot(circle) for the number  to be answered.

Level 3 [4-7 divisions] -  
–100 < A < 100 ; -10 < d < 10 not equal to 0. 
display 1st number and last number.  Show dot(circle) for the number  to be answered.

Level 4 [4-8 divisions] -  
–200 < A < 200 ;  -20 < d <20 not equal to 0. 
Display any 2 random numbers. Show dot for the number  to be answered

Level 5 [4-10 divisions ] - 
 –300 < A < 500  ; -30 < d < 30  not equal to 0. 
 Display any 2 random numbers. Show dot for the number  to be answered


 */


function NumberLine() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [attempted, setAttempted] = useState(location.state.attempted);
    

    const location = useLocation();
    const level = Number(location.state.level);
    const [seq, setSeq] = useState([]);
    const [missing, setMissing] = useState(0);
    const [answer, setAnswer] = useState('');
    const [numbers, setNumbers] = useState({a: 0, b: 0});
    const [answertype, setAnswertype] = useState(false);
    const [show, setShow] = useState(false);
    const [cansubmit, setCansubmit] = useState(false);
    const [submitted, isSubmitted] = useState(false);
    let divisions, a, d, tempseq = [];

    useEffect(() => {
        generateSeq();
        

    }, [])

    const generateSeq = () => {
        tempseq = [];
        //display first and last number


        switch (level) {
            case 0://[3- 5 divisions] -  0 < A < 10 ; ; 1< d < 2
                divisions = returnRandomNumber(3, 5);
                a = returnRandomNumber(0, 10);
                d = returnRandomNumber(1, 2);
                break;
            case 1:/**[3-6 divisions]
            0< smallest number < 10; -3 <  d < 3 not equal to 0.0 */
                divisions = returnRandomNumber(3, 6);
                a = returnRandomNumber(0, 10);
                d = returnRandomNumber(-3, 3);
                break;
            case 2:/* [3-6 divisions] 
            0< smallest number < 30; -5 <  d < 5 */
                divisions = returnRandomNumber(3, 6);
                a = returnRandomNumber(0, 30);
                d = returnRandomNumber(-5, 5);
                break;
            case 3:
                /**[4-7 divisions] -
            –100 < A < 100 ; -10 < d < 10 not equal to 0. */
                divisions = returnRandomNumber(4, 7);
                a = returnRandomNumber(-100, 100);
                d = returnRandomNumber(-10, 10);
                break;
            case 4:/** [4-8 divisions]
            –200 < A < 200 ;  -20 < d <20 not equal to 0 */
                divisions = returnRandomNumber(4, 8);
                a = returnRandomNumber(-200, 200);
                d = returnRandomNumber(-20, 20);
                break;
            case 5:/**[4-10 divisions ] 
            –300 < A < 500  ; -30 < d < 30*/
                divisions = returnRandomNumber(4, 10);
                a = returnRandomNumber(-300, 500);
                d = returnRandomNumber(-30, 30);
                break;
            default: break;
        }
        createSeq();
        
    }

    const createSeq = () => {
        for(var i = 0; i < divisions; i++){
            var x = a + ((i + 1) - 1)*d;
            
            tempseq[i]= x;
        }
        
        setSeq([...tempseq]);
        setMissing(returnRandomNumber(1, tempseq.length - 2));

    }
    const returnRandomNumber = (min, max) => {
        /** return random number between min and max */
        if(min >= 0)
        return Math.floor(Math.random() * (max - min + 1) + min);
        else {
            var random = Math.floor(Math.random() * (max - min)) + min;
            if (random == 0) return returnRandomNumber(min, max);
            return random;
        }
    }

    const reset = () => {
        setAnswer('');
        setCansubmit(false);
        isSubmitted(false);
        setShow(false);
    }

    const next= () => {
        if(location.state.homework && attempted >= location.state.ques) {
            alert('you have completed your homework');
            navigate('/homepage/homework');
        }
        reset();
        generateSeq();
    }
    const onChange = (e) => {
        /** changes the answers based on the input name */
        const { value } = e.target;
        setAnswer(value ? parseInt(value): '');
        setCansubmit(true);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if(seq[missing] === answer) {
            setAnswertype(true);
        } else setAnswertype(false);

        setShow(true);
        isSubmitted(true);
        setCansubmit(false);
        if(location.state.homework){
            dispatch(updateHomeWork({
             module: location.state.topic, id: location.state.id, correct: answertype}))    
        }
        else {
            await dispatch(submitResult({
             result: answertype ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
        }
        setAttempted(attempted + 1);
    }

  return (
    <div style={{ backgroundColor: "#f2f1f1" }}>
        {/* <Navbar /> */}
        <div className="d-flex container-color mt-3 mx-3">
            <Col xs={10}>
                <div className="d-flex flex-row ">
                    <div className="py-2 mx-2">
                        <i className="fa-solid fa-arrow-left-long" type="button" onClick={() => navigate(`/homepage/practice`)}></i>
                    </div>
                    <div className="py-2">
                        {`Number Line - Level ${level}`}
                    </div>
                </div>
            </Col>
        </div>
        <div className='d-flex flex-column 
        align-items-center justify-content-center mt-3 mb-5' style={{fontSize: '25px'}}>
            <p className='para-text mb-4'>
                The vertical dividers on the line divide it into equal parts.<br></br>
                Find the number located on the divider marked by green circle
            </p>
            {/** render the number line */}
           <div className='line-container'>
                <div className='line'></div>
                {
                   seq &&  seq.map((no, index) =>(
                    <div key={index} className='number-container'>
                        <div className="line-bar">
                            <div className={`${index === missing ? 'green-circle': 'hide'} ${index} ${missing}`}></div>
                        </div>
                       {
                        index === 0 || index === seq.length - 1 ?
                        <span>{no}</span> : null
                       }
                    </div>
                    ))
                }
           </div>
           <div className='align-self-center d-flex flex-column mt-2'>
           
           <div className="d-flex gap-4 justify-content-center">
           <input
            style={{ 
                maxWidth: '100px',
            }}
            type="number"
            disabled={submitted}
            onChange={onChange}
            value={answer}
            name={`answer`}
            className="text-center border border-dark align-items-center bg-light pair-inputm-0"
            ></input>
            <button
                disabled={!cansubmit || !answer}
                onClick={onSubmit}
                type='submit'
                className="btn btn-dark px-4 "
            >
                Submit
            </button>
        </div>
           </div>
        </div>
        { show && <ShowAnswer missing={missing} seq={seq} answer={answertype} next={next}/>}
    </div>
  )
}

export default NumberLine