import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { act } from "react-dom/test-utils";

const initialState = {
  firstName: "",
  lastName: "",
  role: "",
  className: "",
  section: "",
  email: "",
  avatar: "",
  loading: false,
  error: null,
  msg:""
};
// export const submitResult = createAsyncThunk("submitResult", async (body) => {
//   const response = await fetch(
//     process.env.REACT_APP_API_ADDRESS + "/api/student/practice",

//     {
//       method: "PATCH",
//       headers: {
//         "Content-type": "application/json",
//         "x-auth-token": `${localStorage.getItem("token")}`,
//       },
//       body: JSON.stringify(body),
//     }
//   );
//   const data = await response.json();
//   
//   localStorage.setItem("score", data.practiceData.score);
//   localStorage.setItem("attempted", data.practiceData.attempted);
//   return data;
// });
export const signupUser = createAsyncThunk("singupUser", async (body) => {
  // 
  const response = await fetch(
    process.env.REACT_APP_API_ADDRESS + "/api/superAdmin/register",

    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  
  // const res = await response.json();
  return response;

});

export const loginUser = createAsyncThunk("loginUser", async (body) => {
  // 
  const response = await fetch(process.env.REACT_APP_API_ADDRESS + "/api/superAdmin/login", {
    method: "POST",
    body: JSON.stringify({
      email: body.email,
      password: body.password,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  });
  const json = await response.json();
  // 
  if (json.token) {
    // return json
    return {
      token: json.token,
      firstName: json.user.firstName,
      lastName: json.user.lastName,
      role: json.user.role,
      organization: json.user.organization,
      className: json.user.className,
      section: json.user.section,
      avatar: json.user.avatar,
      msg: json.msg
    };
  } else {
    
    throw new Error(json.errors[0].msg);
  }
});

export const logoutUser = createAsyncThunk("logoutUser", async () => {
  localStorage.clear();
});

// export const resetScore= createAsyncThunk("resetScore", async () => {
//   const response = await fetch(
//     process.env.REACT_APP_API_ADDRESS + "/api/student/resetScore",

//     {
//       method: "PATCH",
//       headers: {
//         "Content-type": "application/json",
//         "x-auth-token": `${localStorage.getItem('token')}`
//       },
//     }
//   );
//   const data = await response.json();
//   
//   return data
// });

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addToken: (state, action) => {
      state.token = localStorage.getItem("token");
    },
    addUser: (state, action) => {
      state.user = localStorage.getItem("user");
    },
  },
  extraReducers: {
    [loginUser.pending]: (state, action) => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      const { email } = action.meta.arg;
      state.email = email;
      state.token = action.payload.token;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.className = action.payload.className;
      state.section = action.payload.section;
      state.organization = action.payload.organization;
      state.role = action.payload.role;
      state.avatar=action.payload.avatar;
      state._id=action.payload._id;
      state.msg=action.message;
      state.loading = false;
      localStorage.setItem("user", JSON.stringify(email));
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem(
        "name",
        action.payload.firstName + action.payload.lastName
      );
      localStorage.setItem(
        "className",
        action.payload.className + action.payload.section
      );
      localStorage.setItem("role", action.payload.role);
      localStorage.setItem("avatar", action.payload.avatar);
      // return {...state, ...action.payload};
    },

    [loginUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      
    },
    [logoutUser.fulfilled]: (state, action) => {
      state = initialState;
      return state;
    },
    // singup
    [signupUser.pending]: (state, action) => {
      state.loading = true;
      return state;
    },
    [signupUser.fulfilled]: (state, action) => {
      // state.name = action.payload.firstName;
      // state.lastName = action.payload.lastName;
      // state.role = action.payload.role;
      // state.className = action.payload.className;
      // state.section = action.payload.section;
      // state.email = action.payload.email;
      // state.msg = action.payload.msg;
      
      state.loading = false;
    

    },
    [signupUser.rejected]: (state, action) => {
      state.loading = true;
    },
  },
});

// const scoreSlice = createAsyncThunk({
//   name: 'score',
//   initialState: {
//     score: 0,
//     attempted: 0,
//     loading: false,
//     error: null
//   },
//   reducers: {
//     correct: (state, action) => {
//       state.score = state.score + 1,
//       state.attempted = state.attempted + 1
//     },
//     incorrect: (state, action) => {
//       state.attempted = state.attempted + 1
//     },
//   },
//   extraReducers: {
//     [submitResult.pending] : (state, action) => {
//       state.loading = true
//     },
//     [submitResult.fulfilled] : (state, action) => {
//       state.loading = false
//       
//     },
//     [submitResult.rejected] : (state, action) => {
//       state.loading = true
//     }
//   }
// })

export const { addToken, addUser } = authSlice.actions;
export default authSlice.reducer;

export const selectAuth = (state) => state.auth;
export const getState = (state) => state.auth;

export const selectToken = (state) => state.auth.token;

export const selectUser = (state) => state.auth.user;

export const selectLoading = (state) => state.auth.loading;

export const selectError = (state) => state.auth.error;

