import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import coin1 from "./Coin-1.png";
import coin2 from "./Coin-2.png";
import coin5 from "./Coin-5.png";
import Note10 from "./Note-10.png";
import Note20 from "./Note-20.png";
import Note50 from "./Note-50.png";
import Note100 from './Note-100.png';
import './ChangingMoney.css';

import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";

/**
 * Level 4 :- Amount upto 100; create the amount with 
 * the given denomination; denominations 1,2,5,10,20 (limited quantities of 
 * each denominations are available and are randomly decided)
 * Level  5 :- Amount upto 1000; create the amount with the given denomination;
 * denominations 1,2,5,10,20,50,100 (limited quantities of each denominations are 
 *  available and are randomly decided)
 */


const randomNum = (min, max) => {
    /** return random number between min and max */
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const Level4 = ({ level, location }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [attempted, setAttempted] = useState(location.state.attempted);
  
  
    const [amount, setAmount] = useState(0)
    const [answers, setAnswers] = useState({
        answer1: '', answer2: '', answer5: '', answer10: '', answer20: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [answertype, setAnswertype] = useState(false);
    const [quantities, setQuantities] = useState([]);
    const [coins, setcoins] = useState([]);
    useEffect(() => {
        generateQuantities();
    }, [])

    const generateQuantities = () => {
        if(level === 4){
          setcoins([coin1,
            coin2,
            coin5,
            Note10,
            Note20])
          setAmount(randomNum(1, 100));
          setQuantities([
              randomNum(2, 10),
              randomNum(3, 10),
              randomNum(2, 10),
              randomNum(2, 10),
              randomNum(2, 10)
          ])
        } 
        else if (level === 5) {
          setcoins([coin1,
            coin2,
            coin5,
            Note10,
            Note20,Note50, Note100])
          setAnswers({
            answer1: '', answer2: '', answer5: '', answer10: '', answer20: '', answer50: '', answer100: ''
        })
        /**
         * verify if payable 
         * total sum of quantities >= amount
         */
          setAmount(randomNum(1, 1000));
          setQuantities([
              randomNum(2, 10),
              randomNum(3, 10),
              randomNum(2, 10),
              randomNum(5, 10),
              randomNum(3, 15),
              randomNum(3, 20),
              randomNum(3, 15),
          ])
        }

        
    }

    const onChange = (e) => {
        const { name, value} = e.target;
        setAnswers({
            ...answers,
            [name]: value,
        })
    }

    const onSubmit = async () => {
      let totalAmt = 0;
      const { answer1, answer2, answer5, answer10, answer20, answer100 } = answers;
      setSubmitted(true);
      totalAmt += (
      (answer1.length > 0 ? parseInt(answer1): 0) * 1+
      (answer2.length ? parseInt(answer2): 0) * 2+
      (answer5.length ? parseInt(answer5): 0) * 5+
      (answer10.length ? parseInt(answer10): 0) * 10+
      (answer20.length ? parseInt(answer20): 0) * 20 +
      (level === 5 &&(answer100.length ? parseInt(answer100): 0) * 100 )
      )

      
        
      if(totalAmt === amount) setAnswertype(true);
      else setAnswertype(false)
      if(location.state.homework){
                  dispatch(updateHomeWork({
                  module: location.state.topic, id: location.state.id, correct: answertype}))    
              }
              else {
                  await dispatch(submitResult({
                  result: answertype ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
              }
        setAttempted(attempted + 1);


    }

    const next = () => {
      if(location.state.homework && attempted >= location.state.ques) {
        alert('you have completed your homework');
        navigate('/homepage/homework');
    }
    
      setSubmitted(false);
      setAnswers({
        answer1: '', answer2: '', answer5: '', answer10: '', answer20: '', answer50: '', answer100: ''
    })
      generateQuantities();
    }

    return (
        <>
        <div style={{maxWidth: '100vw'}} className='text-center d-flex flex-column justify-content-center align-items-center' >
        <p style={{ fontSize: "22px", fontWeight: "600" }}>How will you pay Rs {amount} ?</p>
        <div className="d-flex flex-column">
         <table className="money-table1" style={{maxWidth: '80%'}}>
           <tbody>
             <tr>
             <td></td>
             
               {coins.map((coin,index)=>{
                 return (<>
                   <td><img src={coin} alt=""/></td>
                 </>)
               })}
             </tr>
             <tr>
             <td><p>Available</p></td>
             {
                quantities && quantities.map(quantity => (
                    <td><p>{quantity}</p></td>
                ))
             }
               {/* {coin.map((coin,index)=>{
                 return (<>
                   
                 </>)
               })} */}
             </tr>
             <tr>
               <td>
                <p>To be paid</p>
               </td>
               <td>
                    <input
                    type='number' 
                    name={'answer1'}
                    value={answers.answer1}
                    onChange={onChange}
                    />
                </td>
               <td>
                    <input
                    type='number' 
                    name={'answer2'}
                    value={answers.answer2}
                    onChange={onChange}
                    //   value={answer1}
                    //   onChange={(e)=>setAnswer1(e.target.value)}
                    />
                </td>
               <td>
                    <input
                    type='number' 
                    name={'answer5'}
                    value={answers.answer5}
                    onChange={onChange}
                    //   value={answer1}
                    //   onChange={(e)=>setAnswer1(e.target.value)}
                    />
                </td>
               <td>
                    <input
                    type='number' 
                    name={'answer10'}
                    value={answers.answer10}
                    onChange={onChange}
                    //   value={answer1}
                    //   onChange={(e)=>setAnswer1(e.target.value)}
                    />
                </td>
               <td>
                    <input
                    type='number' 
                    name={'answer20'}
                    value={answers.answer20}
                    onChange={onChange}
                    //   value={answer1}
                    //   onChange={(e)=>setAnswer1(e.target.value)}
                    />
                </td>
                {
                  level === 5 &&
                  
                    (
                      <>
                      <td>
                        <input
                        type='number' 
                        name={'answer50'}
                        value={answers.answer50}
                        onChange={onChange}
                        //   value={answer1}
                        //   onChange={(e)=>setAnswer1(e.target.value)}
                        />
                    </td>
                    <td>
                        <input
                        type='number' 
                        name={'answer100'}
                        value={answers.answer100}
                        onChange={onChange}
                        //   value={answer1}
                        //   onChange={(e)=>setAnswer1(e.target.value)}
                        />
                    </td>
                    </>
                    )
                }
               </tr>
           </tbody>
         </table>
         
       </div>
       <button
          disabled={submitted}
          type="submit"
          className="btn mb-2 btn-dark mt-3"
          onClick={onSubmit}
        >
          Submit
        </button>
        </div>

       { submitted && (
         answertype ? <CorrectBar onClick={next}/>
         : <IncorrectBar onClick={next} label='hi'>hi</IncorrectBar>
       )
        }
        </>
    )
}