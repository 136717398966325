import React, { useState, useEffect } from "react";
import { useSpeechSynthesis, useSpeechRecognition } from "react-speech-kit";
import { useSelector } from "react-redux";
import CorrectBar from "../../Component/coreComponents/CorrectBar";
import IncorrectBar from "../../Component/coreComponents/IncorrectBar";
import UserButton from "../../Component/coreComponents/UserButton/UserButton";
import { FaPause, FaPlay } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from 'react-router-dom';



function ListenAndWriteTwo({ location }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);

  const [value, setValue] = useState("");
  const [wordOptions, setWordOptions] = useState([]);
  const [correctOption, setCorrectOption] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const { speak } = useSpeechSynthesis();
  const [isCorrect, setIsCorrect] = useState(false);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [currentWord, setCurrentWord] = useState(null);

  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => { },
  });

  const state = useSelector((state) => state.word.words);
  const word = state.map((word) => word.word);

  const shuffleLetters = (word) => {
    let shuffledWord = "";
    word = word.split("");
    while (word.length > 0) {
      shuffledWord += word.splice(Math.floor(Math.random() * word.length), 1);
    }
    return shuffledWord;
  };

  const getRandomWord = () => {
    const words = [{ word: word[Math.floor(Math.random() * word.length)] }];
    const options = [words[0].word];
    while (options.length < 4) {
      const option = shuffleLetters(words[0].word);
      if (!options.includes(option)) {
        options.push(option);
      }
    }
    return {
      word: words[0].word,
      options: options.sort(() => Math.random() - 0.5),
    };
  };

  
  const startListening = () => {
    const randomWord = currentWord || getRandomWord(); // Use the current word if available, else generate a new random word
    setCurrentWord(randomWord);
    setWordOptions(randomWord.options);
    setCorrectOption(randomWord.word);
    speak({ text: `${randomWord.word}` });
    listen();
    setShowNextButton(false);
  };

  const checkAnswer = () => {
    if (value.toLowerCase() === correctOption.toLowerCase()) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
    setAnswerSubmitted(true);
    setShowNextButton(true);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    checkAnswer();
    if (location.state.homework) {
      dispatch(updateHomeWork({
        module: location.state.topic, id: location.state.id, correct: isCorrect
      }))
    }
    else {
      await dispatch(submitResult({
        result: isCorrect ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
      }))
    }
    setAttempted(attempted + 1);

  };

  const handleStopClick = () => {
    stop();
    setWordOptions([]);
    setCorrectOption(null);
    setValue("");
    setSubmitted(false);
    setShowNextButton(false);
  };
  const handleNextClick = () => {
    if (location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
    }
    setIsCorrect(null);
    setAnswerSubmitted(false);
    stop();
    const randomWord = getRandomWord();
    setCurrentWord(randomWord);
    setWordOptions(randomWord.options);
    setCorrectOption(randomWord.word);
    setValue("");
    listen();
    setShowNextButton(false);
  };

  useEffect(() => {
    startListening();
  }, []);

  useEffect(() => {
    startListening();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center">
        {listening ? (
          <div>
            <div className="col-12 d-flex justify-content-center mt-5">
              <FaPause onClick={handleStopClick} />
            </div>
          </div>
        ) : (
          <div className="col-12 d-flex justify-content-center mt-5">
            <FaPlay onClick={startListening} />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-center">
        {wordOptions.length > 0 && (
          <div className="row mt-5">
            {wordOptions.map((option, index) => (
              <div key={index} className="col-md-6 mb-2 text-center">
                <div className="card bg-transparent border-0 ">
                  <div className="card-body">
                    <h5 className="card-title">{`${index + 1}. ${option}`}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div
        className="d-flex
justify-content-center"
      >
        {correctOption && (
          <form onSubmit={handleSubmit}>
            <div className="form-group mt-5">
              <input
                type="text"
                className="form-control"
                placeholder="Type your answer here"
                value={value}
                onChange={handleInputChange}
              />
            </div>
          </form>
        )}
      </div>
      <div className="d-flex justify-content-center mt-3 col-12">
        <UserButton label="submit" onClick={handleSubmit}>
          Submit
        </UserButton>
      </div>
      <div className="d-flex justify-content-center">
        {answerSubmitted && (
          <div className="col-12">
            {isCorrect ? (
              <CorrectBar onClick={handleNextClick} />
            ) : (
              <IncorrectBar onClick={handleNextClick} />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default ListenAndWriteTwo;
