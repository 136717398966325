import React from 'react'
import { useLocation } from 'react-router-dom';
import WhiteBar from '../coreComponents/WhiteBar';
import Navbar from '../Navbar/Navbar';
import Level0 from './Level0';
import Level1 from './Level1';
import Level2 from './Level2';
import Level3 from './Level3';
import Level4 from './Level4';
import Level5 from './Level5';

const Clock = () => {
    const location = useLocation();
    let content;
    
    switch (parseInt(location.state.level)) {
        case 0: 
            content = <Level0 location={location}/>            
            break;
        case 1: 
            content = <Level1 location={location}/>
            break;
        case 2: 
            content = <Level2 location={location}/>            
            break;
        case 3: 
            content = <Level3 location={location}/>            
            break;
        case 4: 
            content = <Level4 location={location}/>            
            break;
        case 5: 
            content = <Level5 location={location}/>            
            break;
    
        default:
            break;
    }
  return (
    <div>
        <WhiteBar label="Clock" level={(location.state.level)}/>
        {content}
    </div>
  )
}

export default Clock