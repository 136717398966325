import { useState } from "react";
import { Form, Row, Col, FormGroup, FormLabel, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../coreComponents/CustomButton";
import { signupUser } from "../../redux/authSlice";
import "./forms.css";
import { useLocation, useNavigate } from "react-router-dom";
import RegisterModal from "../coreComponents/RegisterModal";

const StudentForms = () => {
  const currentUser = useSelector((state) => state.user);
  const [isShow, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [organization, setOrganization] = useState(currentUser.organization);
  const [className, setClassName] = useState();
  const [section, setSection] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [avatar, setAvatar] = useState(currentUser.avatar);
  const [content, setContent] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();
    dispatch(
      signupUser({
        firstName,
        lastName,
        role,
        className,
        section,
        email,
        password,
        avatar,
        organization,
      })
    ).then((res)=>{
      
      setShow(true);
      if(res.payload.status === 200){
        setMessage({msg:"Successful Registration!",status:"success"});
      } else{
        setMessage({msg:"Registration failed!",status:"fail"});
      }
    })
  };
  return (
        <Form className="m-2 p-4" style={{ backgroundColor: "#f2f1f1"}}>
       <RegisterModal isShow={isShow} status={message} onClick={()=>navigate('/')} width={100} height={100} />
          <Row>
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input1">First Name</FormLabel>
                <Form.Control
                  type="text"
                  id="input1"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input2">Last Name</FormLabel>
                <Form.Control
                  type="text"
                  id="input2"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input4">Class</FormLabel>
                <Form.Control
                  type="number"
                  id="input4"
                  value={className}
                  onChange={(e) => setClassName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input5">Section</FormLabel>
                <Form.Control
                  as="select"
                  id="input5"
                  value={section}
                  onChange={(e) => setSection(e.target.value)}
                >
                  <option>select</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                </Form.Control>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input3">User Role</FormLabel>
                <Form.Control
                  as="select"
                  id="input3"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option>select</option>
                  <option value="TEACHER">Teacher</option>
                  <option value="STUDENT">Student</option>
                </Form.Control>
              </FormGroup>
            </Col>
            {/* <Col xs={6}>
              <FormGroup>
                <FormLabel for="input8">Upload avatar</FormLabel>
                <Form.Control
                  type="File"
                  id="input8"
                  onChange={(e) => setAvatar(e.target.files)}
                />
              </FormGroup>
            </Col> */}
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input6">User Id</FormLabel>
                <Form.Control
                  type="email"
                  id="input6"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input7">Password</FormLabel>
                <Form.Control
                  type="password"
                  id="input7"
                  className={
                    confirmPassword && confirmPassword !== password
                      ? "form-control is-invalid"
                      : ""
                  }
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input9">Confirm Password</FormLabel>
                <Form.Control
                  type="password"
                  id="input9"
                  className={
                    confirmPassword && confirmPassword !== password
                      ? "form-control is-invalid"
                      : ""
                  }
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5 text-center">
              <CustomButton
               onClick={handleRegister}
               disabled={!(firstName && lastName && className && section && email && password && confirmPassword && password === confirmPassword)}
               label="Submit" />
            </Col>
            <Col className="mt-5 text-center">
              <CustomButton
                onClick={() => navigate(`/homepage`)}
                label="Cancel"
              />
            </Col>
          </Row>
        </Form>
  );
};

export default StudentForms;
