import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Navbar/Navbar";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserButton from "../coreComponents/UserButton/UserButton";
import ClockModel from "./ClockModel";
import CorrectBar from "../coreComponents/CorrectBar";
import { submitResult } from "../../redux/scoreSlice";
import { useDispatch } from "react-redux";
import { updateHomeWork } from '../../redux/homeWorkSlice';

function Level0({location}) {
  const [hour, setHour] = useState(Math.floor(Math.random() * 12) + 1);
  const [answer, setAnswer] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [hourAnswer, setHourAnswer] = useState("");
  const [minuteAnswer, setMinuteAnswer] = useState("");
  const [minute, setMinute] = useState(0);
  const [attempted, setAttempted] = useState(location.state.attempted);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async  (event) => {
    event.preventDefault();
    const parsedHourAnswer = parseInt(hourAnswer);
    const parsedMinuteAnswer = parseInt(minuteAnswer);
    setIsCorrect(parsedHourAnswer === hour && parsedMinuteAnswer === minute);
    // setCorrectAnswer(hour);
    setCorrectAnswer(`${hour} : ${"00"}`);
    if(location.state.homework){
      dispatch(updateHomeWork({
       module: location.state.topic, id: location.state.id, correct: parsedHourAnswer === hour && parsedMinuteAnswer === minute }))    
    }
    else {
        await dispatch(submitResult({
        result: parsedHourAnswer === hour && parsedMinuteAnswer === minute  ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
    }
    setAttempted(attempted + 1);
  };

  const handleReset = () => {
    setHourAnswer("");
    setMinuteAnswer("");
  };

  const handleNext = () => {
    if(location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
  }
    setHourAnswer("");
    setMinuteAnswer("");
    setCorrectAnswer(null);
    setHour(Math.floor(Math.random() * 12) + 1);

    setIsCorrect(null)
  };
  const incorrectBar = (
    <div className=" mx-3">
      <nav className="d-flex navbar incorrect">
        <div className="nav-item mx-2 fs-5" style={{ color: "white" }}>
          <i class="fa-solid fa-xmark"></i> Wrong !
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <button className="btn btn-light mx-2" onClick={handleNext}>
            Next
          </button>
        </div>
      </nav>
    </div>
  );

  return (
    <div>
      <div className="d-flex justify-content-center mt-2">
        <h3>What is the Time In The Clock?</h3>
      </div>
      <div className="d-flex">
        <div style={{ width: "30%" }} className="text-center">
          <ClockModel hour={hour} minute={parseInt(0)}/>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "70%" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="input-group mb-3">
              <input
                type="number"
                min={1}
                max={12}
                value={hourAnswer}
                onChange={(e) => setHourAnswer(e.target.value)}
                className="form-control text-center"
                style={{fontSize:'30px'}}
                placeholder="hh"
              />
              <div className="mx-1" style={{fontSize:'30px'}}>{`:`}</div>
              <input
                type="number"
                min={0}
                max={55}
                step={5}
                value={minuteAnswer}
                onChange={(e) => setMinuteAnswer(e.target.value)}
                className="form-control text-center"
                style={{fontSize:'30px'}}
                placeholder="mm"
              />
            </div>
          </form>
        </div>
      </div>
      <div
        className="d-flex justify-content-center gap-3"
        style={{ marginTop: "-24px" }}
      >
        <div>
          <UserButton label="Submit" onClick={handleSubmit} disabled={!hourAnswer && !minuteAnswer}/>
        </div>
        <UserButton label="Reset" onClick={handleReset} />
      </div>
      {isCorrect && <CorrectBar onClick={handleNext}/>}
      {!isCorrect && correctAnswer !== null && <p>{incorrectBar}</p>}
      {!isCorrect && correctAnswer !== null && (
        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-center align-items-center">
            <h6 className="mt-3 mx-3">Correct Ans Is:</h6>
            <form className="w-50">
            <div className="d-flex container-color mt-3 mx-3 w-100 ">
                <Col xs={12}>
                  <div className="text-center w-100">
                    <h3 className="py-2 mx-2">{correctAnswer}</h3>
                  </div>
                </Col>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Level0;
