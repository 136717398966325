import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";
import WhiteBar from "../coreComponents/WhiteBar";
import UserButton from "../coreComponents/UserButton/UserButton";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { submitResult } from "../../redux/scoreSlice";
import { useDispatch } from "react-redux";
const Add2Tables = () => {
  const [multiplesA, setMultiplesA] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  ]);
  const [multiplesB, setMultiplesB] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  ]);
  const [multiplesC, setMultiplesC] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  ]);

  const randomTen = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const level0 = () => {
    return {
      a: 10,
      b: randomTen(1, 9)
    };
  };
  const level1 = () => {
    return {
      a: randomTen(1, 9) * 10,
      b: 1
    };
  };
  const level2 = () => {
    return {
      a: randomTen(2, 9) * 10,
      b: randomTen(2, 3)
    };
  };
  const level3 = () => {
    return {
      a: randomTen(2, 9) * 10,
      b: randomTen(4, 5)
    };
  };
  const level4 = () => {
    return {
      a: randomTen(2, 9) * 10,
      b: randomTen(6, 7)
    };
  };
  const level5 = () => {
    return {
      a: randomTen(2, 9) * 10,
      b: randomTen(8, 9)
    };
  };
  const level = () => {
    switch (parseInt(location.state.level)) {
      case 0:
        return {
          a: 10,
          b: randomTen(1, 9)
        };
        break;
      case 1:
        {
          return {
            a: randomTen(1, 9) * 10,
            b: 1
          };
        }
        break;
      case 2:
        return {
          a: randomTen(2, 9) * 10,
          b: randomTen(2, 3)
        };
        break;
      case 3:
        return {
          a: randomTen(2, 9) * 10,
          b: randomTen(4, 5)
        };
        break;
      case 4:
        return {
          a: randomTen(2, 9) * 10,
          b: randomTen(6, 7)
        };
        break;
      case 5:
        return {
          a: randomTen(2, 9) * 10,
          b: randomTen(8, 9)
        };
        break;
    }
  };
  const [attempted, setAttempted] = useState(location.state.attempted);
  const [numbers, setNumbers] = useState(level());
  const [submitted, setSubmitted] = useState(false);
  const [ifTrue, setIfTrue] = useState(false);
  const dispatch = useDispatch();

  const [checkArr,setcheckArr] = useState([false,false,false,false,false,false,false,false,false,false])
  //   const dispatch = useDispatch();
  const handleNxt = () => {
    setMultiplesA(["", "", "", "", "", "", "", "", "", ""]);
    setMultiplesB(["", "", "", "", "", "", "", "", "", ""]);
    setMultiplesB(["", "", "", "", "", "", "", "", "", ""]);
    setSubmitted(false);
    switch (parseInt(location.state.level)) {
      case 0:
        setNumbers(level0());
        break;
      case 1:
        setNumbers(level1());
        break;
      case 2:
        setNumbers(level2());
        break;
      case 3:
        setNumbers(level3());
        break;
      case 4:
        setNumbers(level4());
        break;
      case 5:
        setNumbers(level5());
        break;
    }
  };

  let multiplesOfA = [];
  for (let i = 1; i <= 10; i++) {
    multiplesOfA.push(i * numbers.a);
  }
  // 
  let multiplesOfB = [];
  for (let i = 1; i <= 10; i++) {
    multiplesOfB.push(i * numbers.b);
  }
  
  let multiplesOfC = [];
  for (let i = 1; i <= 10; i++) {
    multiplesOfC.push(i * (numbers.a + numbers.b));
  }
  

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    for(let i in multiplesA){
     for(let j in multiplesOfA){
       if(multiplesA[i] === multiplesOfA[j]){
         setcheckArr((checkArr) => {
           const newcheckArr = [...checkArr];
           newcheckArr[i] = true;
           return newcheckArr;
         });
       }
     }
    }
    
  //   if(location.state.homework){
  //     dispatch(updateHomeWork({
  //      module: location.state.topic, id: location.state.id, correct: answertype}))    
  // }
  // else {
  //     await dispatch(submitResult({
  //      result: answertype ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
  // }
  setAttempted(attempted + 1);
    // await dispatch(submitResult({result:(parseInt(answer) === numbers.a + numbers.b ? "CORRECT" : "INCORRECT"),email:localStorage.getItem('email')}))
    setSubmitted(true);
  };

  const handleFactorChangeA = React.useCallback((event) => {
    const index = parseInt(event.target.dataset.index, 10);
    let m = numbers.a * index;
    if (m === parseInt(event.target.value)) {
      setIfTrue(true);
    }
    
    setMultiplesA((multiples) => {
      const newMultiples = [...multiples];
      newMultiples[index] = parseInt(event.target.value);
      return newMultiples;
    });
  }, []);
  const handleFactorChangeB = React.useCallback((event) => {
    const index = parseInt(event.target.dataset.index, 10);
    setMultiplesB((multiples) => {
      const newMultiples = [...multiples];
      newMultiples[index] = parseInt(event.target.value);
      return newMultiples;
    });
  }, []);
  const handleFactorChangeC = React.useCallback((event) => {
    const index = parseInt(event.target.dataset.index, 10);
    setMultiplesC((multiples) => {
      const newMultiples = [...multiples];
      newMultiples[index] = parseInt(event.target.value);
      return newMultiples;
    });
  }, []);

  // const addInput = React.useCallback(
  //   () => setFactors((factors) => [...factors, ""]),
  //   []
  // );

  const incorrectBar = (
    <div className=" mx-3">
      <nav className="d-flex navbar mt-5 incorrect">
        <div className="nav-item mx-2 fs-5" style={{ color: "white" }}>
          <i class="fa-solid fa-xmark"></i> Wrong !
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <UserButton className="btn btn-light mx-2" onClick={handleNxt}>
            Next
          </UserButton>
        </div>
      </nav>
    </div>
  );

  const correctBar = (
    <nav className="d-flex navbar mt-5 mx-3 correct">
      <div className=" nav-item fs-3 mx-1 " style={{ color: "white" }}>
        <i class="fa-solid fa-check"></i> Correct!
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <UserButton className="btn btn-light mx-3" onClick={handleNxt}>
          Next
        </UserButton>
      </div>
    </nav>
  );
  return (
    <div style={{ backgroundColor: "#f2f1f1", height: "auto" }}>
      <WhiteBar label='Add 2 tables' level={location.state.level}/>
      <div
        className="container mt-1 "
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div
          className=""
          style={{ display: "flex", gap: "15%", justifyContent: "center" }}
        >
          <div
            className=""
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div style={{ textAlign: "center", marginBottom: "7%" }}>
              Table of {numbers.a}
            </div>
            <div className="" style={{ display: "flex", gap: "5%" }}>
              <div
                className=""
                style={{
                  display: "grid",
                  // gridTemplateColumns: "auto auto",
                  rowGap: "1%",
                  justifyContent: "space-between"
                  // width: "25rem"
                }}
              >
                {multiplesA.map((multiple, index) => {
                  return (
                    <div
                      className="d-flex"
                      key={index}
                      style={{ justifyContent: "space-between" }}
                    >
                      <div style={{ fontSize: "20px" }}>{numbers.a}</div>
                      <div className="mx-2" style={{ fontSize: "20px" }}>
                        X
                      </div>
                      <div style={{ fontSize: "20px" }}>{index + 1}</div>
                      <div className="mx-2" style={{ fontSize: "20px" }}>
                        =
                      </div>
                      <input
                        style={{
                          backgroundColor:
                            submitted ? (parseInt(multiplesA[index]) === parseInt(numbers.a*(index+1)) ? "green" : "red") : 'white'
                          ,
                          fontSize: "20px",
                          color: submitted ? "white" : "black",
                          width: "60px",
                          border: 'none'
                        }}
                        disabled={submitted}
                        type="number"
                        data-index={index}
                        className="text-center align-items-center"
                        value={multiple}
                        onChange={handleFactorChangeA}
                      ></input>
                      {submitted &&  parseInt(multiplesA[index]) !== parseInt((numbers.a)*(index+1)) &&<div
                      className="text-center align-items-center"
                      style={{
                        backgroundColor: "green",
                        fontSize: "20px",
                        color:"white",
                        width: "60px"
                      }}>{numbers.a*(index+1)}</div>}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div
            className=""
            style={{
              display: "grid",
              // gridTemplateColumns: "auto auto",
              rowGap: "1%",
              justifyContent: "space-between",
              width: "25rem"
            }}
          >
            <div style={{ textAlign: "center", marginBottom: "7%" }}>
              Table of {numbers.b}
            </div>
            {multiplesB.map((multiple, index) => {
              return (
                <div
                  className="d-flex"
                  key={index}
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: "20px" }}>{numbers.b}</div>
                  <div className="mx-2" style={{ fontSize: "20px" }}>
                    X
                  </div>
                  <div style={{ fontSize: "20px" }}>{index + 1}</div>
                  <div className="mx-2" style={{ fontSize: "20px" }}>
                    =
                  </div>
                  <input
                   style={{
                     backgroundColor:
                       submitted ? (parseInt(multiplesB[index]) === parseInt(numbers.b*(index+1)) ? "green" : "red") : 'white'
                     ,
                     fontSize: "20px",
                     color: submitted ? "white" : "black",
                     width: "60px",
                     border: 'none'
                   }}
                   disabled={submitted}
                   type="number"
                   data-index={index}
                   className="text-center align-items-center"
                   value={multiple}
                   onChange={handleFactorChangeB}
                 ></input>
                 {submitted && parseInt(multiplesB[index]) !== parseInt((numbers.b)*(index+1)) &&<div
                 className="text-center align-items-center"
                 style={{
                   backgroundColor: "green",
                   fontSize: "20px",
                   color:"white",
                   width: "60px"
                 }}>{numbers.b*(index+1)}</div>}
                </div>
              );
            })}
          </div>
          <div
            className=""
            style={{
              display: "grid",
              // gridTemplateColumns: "auto auto",
              rowGap: "1%",
              justifyContent: "space-between",
              width: "25rem"
            }}
          >
            <div style={{ textAlign: "center", marginBottom: "7%" }}>
              Table of {parseInt(numbers.a) + parseInt(numbers.b)}({numbers.a} +
              {numbers.b})
            </div>
            {multiplesC.map((multiple, index) => {
              return (
                <div
                  className="d-flex"
                  key={index}
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: "20px" }}>
                    {parseInt(numbers.a) + parseInt(numbers.b)}
                  </div>
                  <div className="mx-2" style={{ fontSize: "20px" }}>
                    X
                  </div>
                  <div style={{ fontSize: "20px" }}>{index + 1}</div>
                  <div className="mx-2" style={{ fontSize: "20px" }}>
                    =
                  </div>
                  <input
                    style={{
                      backgroundColor:
                        submitted ? (parseInt(multiplesC[index]) === parseInt((numbers.a+numbers.b)*(index+1)) ? "green" : "red") : 'white'
                      ,
                      fontSize: "20px",
                      color: submitted ? "white" : "black",
                      width: "60px",
                      border: 'none'
                    }}
                    disabled={submitted}
                    type="number"
                    data-index={index}
                    className="text-center align-items-center"
                    value={multiple}
                    onChange={handleFactorChangeC}
                  ></input>
                  {submitted && parseInt(multiplesC[index]) !== parseInt((numbers.a+numbers.b)*(index+1)) && <div
                 className="text-center align-items-center"
                 style={{
                   backgroundColor: "green",
                   fontSize: "20px",
                   color:"white",
                   width: "60px"
                 }}>{(numbers.a+numbers.b)*(index+1)}</div>}
                </div>
              );
            })}
          </div>
        </div>
        {/* <div
          className="d-flex justify-content-center"
          style={{ marginTop: "50px" }}
        > */}
          <UserButton
            disabled={submitted}
            type="submit"
            className="btn btn-dark mb-2"
            style={{ marginTop: "50px"}}
            onClick={handleSubmit}
          >
            Submit
          </UserButton>
        {/* </div> */}
      </div>
      {submitted === true
        ? multiplesA.length === multiplesOfA.length &&
          multiplesA.every((value, index) => value === multiplesOfA[index]) &&
          multiplesB.every((value, index) => value === multiplesOfB[index]) &&
          multiplesC.every((value, index) => value === multiplesOfC[index])
          ? <CorrectBar onClick={handleNxt} />
          : <IncorrectBar onClick={handleNxt}><p className="m-0 p-0">marked green.</p></IncorrectBar>
        : ""}
    </div>
  );
};

export default Add2Tables;
