import React from 'react'
import { useLocation } from 'react-router-dom'
import MentalMathLevel0 from './MentalMathLevel0';
import MentalMathLevel1 from './MentalMathLevel1';
import MentalMathLevel2 from './MentalMathLevel2';
import MentalMathLevel3 from './MentalMathLevel3';

const MentalMaths = () => {
    const location = useLocation();
    let content;
    switch (parseInt(location.state.level)) {
        case 0: {
            content = <MentalMathLevel0 location={location}/>
        }
            
            break;
        case 1: {
            content = <MentalMathLevel1 location={location}/>
        }
            
            break;
        case 2: {
            content = <MentalMathLevel2 location={location}/>
        }
            
            break;
        case 3: {
            content = <MentalMathLevel3 location={location}/>
        }
            
            break;
    
        default:
            break;
    }
  return (
    <div>
        {content}
    </div>
  )
}

export default MentalMaths