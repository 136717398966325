import React from 'react'
import { useNavigate } from 'react-router-dom'
import CustomButton from './coreComponents/CustomButton';

function MapButton({role}) {
    const navigate= useNavigate();
    const studentProps= [
        {label: 'Homework', onClick: 'homework' },
        {label: 'Practice', onClick: 'practice' },
    ]
    const adminProps = [
        // {label: 'Create Profile', onClick: role === 'ADMIN' ? 'register' : 'registerAdmin' },
        // {label: 'Delete Profile', onClick:'delete' },
        {label: 'View Performance', onClick: 'performance' },
        {label: 'Add word', onClick: 'AddWord' },
        {label: 'Add item', onClick: 'AddItem' },
    ]
    const teacherProps = [
        {label: 'Assign Homework', onClick: 'assignHomework' },
        {label: 'Manage Homework', onClick: 'manageHomework' },
        {label: 'View Homework', onClick: 'viewHomework' },
        {label: 'View Performance', onClick: 'performance' },
    ]
    const prop = role ==="STUDENT" ? studentProps : ((role ==="ADMIN" || role==='SUPERADMIN') ? adminProps : teacherProps)
  return (
    <div>
        {prop.map((prop) =>{
        // 
        return <CustomButton
         label={prop.label}
         onClick={() => navigate(`/homepage/${prop.onClick}`)}
         />
    })}
    </div>
  )
}

export default MapButton