const { Modal } = require("react-bootstrap")

const RegisterModal= (props) => {

return (<Modal show={props.isShow} className="d-flex" style={{alignItems:'center'}}>
  <Modal.Header  closeButton onClick={()=>props.onClick()}>
    <Modal.Title  style={{alignItems:'center'}}>{props.status.msg}</Modal.Title>
  </Modal.Header>
    <Modal.Body className="container" style={{alignItems:'center', height: 150, width: 250}}>
      <div style={{color: props.status.status ==='fail' ? 'red' : 'green', textAlign:'center'}}>
        <i className={props.status.status ==='fail' ? "fa-solid fa-triangle-exclamation fa-8x" : "fa-solid fa-square-check fa-8x"} ></i>
      </div>
    </Modal.Body>
</Modal>)
}
export default RegisterModal;
    