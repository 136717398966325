import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import './Fraction.styles.css';
import WhiteBar from '../coreComponents/WhiteBar';

import Fraction_level0 from './Fraction_level0';
import Fraction_level1 from './Fraction_level1';
import Fraction_level2 from './Fraction_level2';
import Fraction_level3 from './Fraction_level3';
import Fraction_level4 from './Fraction_level4';

/**
 * Level 0 :- make a box of (n = 2,30) square boxes and fill (i = 1,n) the child has to write the fraction form of this representation.  We can also have addition problem with same denominator in this. 

Level 1 :-   2 type of questions , 1 ) what is ½ of 4 ?  ( 1/(1 to 10) ) and upto 500. 
2) we make 4 squares and child has to highlight 2.  (upto 30 squares and answer is also natural number). 

Level 2; generate 4 fractions that the child has to arrange in ascending order. All 3 fraction type to be included. Max Numbers in numerator = 40, Max Numbers in numerator = 20, 

Level 3 :- addition & subtraction ; All 3 fraction type to be included.  Max Numbers in numerator = 40, Max Numbers in numerator = 20, 

Level 4 :- multiply ; All 3 fraction type to be included.  Max Numbers in numerator = 40, Max Numbers in numerator = 20, 

Level 5 :- divide ; All 3 fraction type to be included.  Max Numbers in numerator = 40, Max Numbers in numerator = 20, 

 */


export const  returnRandomNumber = (min, max) => {
    /** return random number between min and max */
    return Math.floor(Math.random() * (max - min + 1) + min);
}



function Fraction() {
    const location = useLocation();
    const level = parseInt(location.state.level);

    const renderLevel = () => {
        switch(level) {
            case 0: return <Fraction_level0 location={location}/> 
            case 1: return <Fraction_level1 location={location}/>
            case 2: return <Fraction_level2 location={location}/>
            case 3: return <Fraction_level3 location={location}/>
            case 4: return <Fraction_level4 location={location} level={level}/>
            case 5: return <Fraction_level4 location={location} level={level}/>
        }
    }

  return (
    <div  style={{ backgroundColor: "#f2f1f1" }} className='fraction'>
        <WhiteBar label='Fraction' level={location.state.level} />
        
        <div className="d-flex gap-4 flex-column justify-content-center">
            { renderLevel()}
        </div>
    </div>
  )
}

export default Fraction