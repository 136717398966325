import React from 'react'

const ClockModel = ({hour,minute}) => {
    const circle = (
        <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg" height="200px">
        <circle
          cx="30"
          cy="30"
          r="26"
          fill="none"
          stroke="#000"
          strokeWidth="0.5"
        />
        <circle
          cx="30"
          cy="30"
          r="1"
          fill="black"
          stroke="#000"
          strokeWidth="0.5"
        />
         {[...Array(12)].map((_, i) => {
        const angle = (i + 1) * 30 * (Math.PI / 180);
        const x = 30 + 21.8 * Math.sin(angle);
        const y = 30 - 21.8 * Math.cos(angle);
        return (
          <text
            key={i}
            x={x}
            y={y}
            textAnchor="middle"
            dominantBaseline="central"
            fontSize="4"
          >
            {i + 1}
          </text>
        );
      })}
         {[...Array(60)].map((_, i) => {
        const angle = (i + 1) * 6 * (Math.PI / 180);
        const d =  (i %  5) ==4 ? 23.5 : 24
        const x = 30 +  d* Math.sin(angle);
        const y = 30 - d * Math.cos(angle);
        return (
          <line
            key={i}
            x2={x}
            y2={y}
            x1={
              30 + 26 * Math.sin(angle)}
            y1={
              30 - 26 * Math.cos(angle)}
              stroke="#000"
              strokeWidth={(i %  5) ==4 ? .3 : .15}
              strokeLinecap="round"
          />
          );
        })}
        <line
          x1="30"
          y1="30"
          x2={
            30 +
            16 *
              Math.sin((hour - 0) * (Math.PI / 6) + (minute / 60) * (Math.PI / 6))
          }
          y2={
            30 -
            16 *
              Math.cos((hour - 0) * (Math.PI / 6) + (minute / 60) * (Math.PI / 6))
          }
          stroke="#000"
          strokeWidth="0.6"
          strokeLinecap="round"
        />
        <line
          x1="30"
          y1="30"
          x2={30 + 19 * Math.sin((minute / 60) * (Math.PI * 2))}
          y2={30 - 19 * Math.cos((minute / 60) * (Math.PI * 2))}
          stroke="#000"
          strokeWidth="0.4"
          strokeLinecap="round"
        />
      </svg>
      );
  return (
    <div>{circle}</div>
  )
}

export default ClockModel