import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useSelctor, useSelector } from 'react-redux';

/** magic triangle error
 * submit not working after reset
 * 
 */

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchHomework = createAsyncThunk(
    'homework/fetchHomework',
    async ({homework, email, user}) => {

      const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/assignment/viewHomework/${user.className}${user.section}`);
    //   if (!response.ok) {
    //     throw new Error('Failed to fetch homework data');
    //   }
      let { data } = await response.json();

      const studentres = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/assignment/studentHomework/${user.className}${user.section}`);
      const studentdata = await studentres.json();
      const studentHome = studentdata.groupedResults[email];


      let combinedData = [];

      try {

        combinedData = data.map((d, i) => {
          //combining data
          /** checking if student has submitted the homework if he did
           * use his attempted and corrected other the other one 
           */
          return {
            ...d,
            assignments: d.assignments.map ((a, index) => {
              let h = homework[i]?.assignments?.[index];
              let dh;
              if(studentHome?.length) 
              {
                dh = studentHome.find((item) => (item.assignmentID === d.assignmentID) && (item.topic === a.topic));
              }
  
              if(dh && h ){
                return {
                  ...a,
                  topic: a.topic.replace(/\s/g, ''),
                  attempted: h?.attempted ? h.attempted : 0,
                  completed: h?.completed ? h.completed : false,
                  correct: h?.correct ? h.correct : 0,
                }
              } else if(dh) {
                return {
                  ...a,
                  topic: a.topic.replace(/\s/g, ''),
                  attempted: dh.attempted ? dh.attempted : 0,
                  completed: dh.attempted >= parseInt(a.numberOfQues),
                  correct: dh?.correct ? dh.correct : 0,
                }
              } else {
                return {
                  ...a,
                  topic: a.topic.replace(/\s/g, ''),
                  attempted: h?.attempted ? h.attempted : 0,
                  completed: h?.completed ? h.completed : false,
                  correct: h?.correct ? h.correct : 0,
                }
              }
            })
          }
        })
    
      } catch (err) {
        console.error({err})
        // throw err

      }

      //

      return combinedData;
    }
);



export const homeworkSlice = createSlice({
    name: 'homework',
    initialState,
    reducers: {
        updateHomeWork(state, action) {
            const { id, module, correct} = action.payload;
            
            let temp = state.data.map((d, i) => {
                return {
                    ...d,
                    assignments: d.assignments.map((a, index)=> {
                        if(a.topic === module && d.assignmentID === id) 
                        return { ...a,
                            attempted: a.attempted < parseInt(a.numberOfQues) ? a.attempted + 1 : parseInt(a.numberOfQues),
                            correct: correct ? a.correct + 1 : a.correct,
                            completed: a.attempted + 1 >= parseInt(a.numberOfQues)
                        }
                        else return { ...a };
                    })
                }
            });
            
            return { ...state, data: temp }
        },
        clearHomework: (state) => {
          return { ...initialState }
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchHomework.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchHomework.fulfilled, (state, action) => {
            state.status = 'succeeded';
            
            state.data = action.payload;
          })
          .addCase(fetchHomework.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
          });
      },
  });
  
  export const { updateHomeWork, clearHomework } = homeworkSlice.actions;
  export default homeworkSlice.reducer;