import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./SkipCountingAP.css";
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';

const SkipCountingAP = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [attempted, setAttempted] = useState(location.state.attempted);
  const [answer, setAnswer] = useState("");
  let arr_len = location.state.level == 0 ? 8 : location.state.level == 1 ? 12 : 16;
  const [progression, setProgression] = useState(Array(arr_len).fill(""));

  const randomTen = (min, max) => {
    let n = Math.floor(Math.random() * (max - min + 1) + min);
    // 
    while (n === 0) {
      n = Math.floor(Math.random() * (max - min + 1) + min);
    }
    return n;
  };

  const levels = [
    {
      level: 0,
      numbers: {
        a: Math.floor(Math.random() * 10),
        d: Math.floor(1 + Math.random() * 1)
      }
    },
    {
      level: 1,
      numbers: {
        a: randomTen(0, 20),
        d: randomTen(-3, 3)
      }
    },
    {
      level: 2,
      numbers: {
        a: randomTen(0, 20),
        d: randomTen(-5, 5)
      }
    },
    {
      level: 3,
      numbers: {
        a: randomTen(-100, 100),
        d: randomTen(-5, 5)
      }
    },
    {
      level: 4,
      numbers: {
        a: randomTen(-200, 200),
        d: randomTen(-10, 10),
        arr: Array.from({ length: 16 }, () => randomTen(1, 16))
      }
    },
    {
      level: 5,
      numbers: {
        a: randomTen(-200, 200),
        d: randomTen(-5, 5),
        n: randomTen(0, 15),
        m: randomTen(0, 15)
      }
    }
  ];

  const [numbers, setNumbers] = useState(
    levels[parseInt(location.state.level)].numbers
  );
  const [submitted, setSubmitted] = useState(false);
  //   const dispatch = useDispatch();
  const handleNxt = () => {
    if(location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
  }
    setAnswer("");
    setProgression(Array(arr_len).fill(""));
    setSubmitted(false);
    setNumbers(levels[parseInt(location.state.level)].numbers);
  };

  const numberArray = [];
  let small1 = numbers.d < 0 ? numbers.a + -12 * numbers.d : numbers.a;
  let small2 = numbers.d < 0 ? numbers.a + -16 * numbers.d : numbers.a;
  let count =
    location.state.level <= 1 ? small1 : location.state.level == 2 ? small2 : numbers.a;
  for (let i = 0; i < arr_len; i++) {
    count += numbers.d;
    numberArray.push(count);
  }

  const handleCountChange = React.useCallback((event) => {
    const index = parseInt(event.target.dataset.index, 10);
    setProgression((progression) => {
      const newProgression = [...progression];
      location.state.level < 5
        ? (newProgression[0] = numberArray[0])
        : (newProgression[numbers.m] = numberArray[numbers.m]) &&
          (newProgression[numbers.n] = numberArray[numbers.n]);
      newProgression[index] = parseInt(event.target.value);
      return newProgression;
    });
  }, []);

  let sum = 0;
  location.state.level == 4 &&
    numberArray.forEach((element, index) => {
      if (numbers.arr.includes(index)) {
        sum += element;
      }
    });

  location.state.level == 5 && numbers.n === numbers.m && (numbers.m = numbers.n + 1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // await dispatch(submitResult({result:(parseInt(answer) === numbers.a + numbers.b ? "CORRECT" : "INCORRECT"),email:localStorage.getItem('email')}))
    setSubmitted(true);
    let answer = location.state.level == 4
    ? parseInt(answer) === sum
      ? correctBar
      : incorrectBar
    : progression.length === numberArray.length &&
      progression.every((value, index) => value === numberArray[index]);
    if(location.state.homework){
      dispatch(updateHomeWork({
       module: location.state.topic, id: location.state.id, correct: answer}))    
  }
  else {
      await dispatch(submitResult({
       result: answer ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
  }
  setAttempted(attempted + 1);

  };

  const incorrectBar = (
    <div className=" mx-3">
      <nav className="d-flex navbar mt-5 incorrect">
        <div className="nav-item mx-2 fs-5" style={{ color: "white" }}>
          <i class="fa-solid fa-xmark"></i> Wrong !
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <button className="btn btn-light mx-2" onClick={handleNxt}>
            Next
          </button>
        </div>
      </nav>
      <div
        className="container"
        style={{ display: "flex", marginTop: "3%", justifyContent: "center" }}
      >
        {location.state.level == 4 ? (
          <div className="location-4-answer">
            <div className="grids">
              {numberArray.map((count, index) => {
                return (
                  <input
                    style={{
                      backgroundColor: "white",
                      fontSize: "30px",
                      width: "85px",
                      border: "none"
                    }}
                    disabled={submitted}
                    type="number"
                    data-index={index}
                    className="text-center align-items-center"
                    defaultValue={count}
                  ></input>
                );
              })}
            </div>
            <div className="answer" style={{ width: "110%", marginTop: "15%" }}>
              <p>The sum of numbers in shaded boxes is </p>
              <input
                style={{
                  backgroundColor: "white",
                  fontSize: "30px",
                  width: "75px",
                  border: "none"
                }}
                disabled={submitted}
                type="number"
                className="text-center align-items-center"
                value={sum}
              ></input>
            </div>
          </div>
        ) : (
          <div className="grids">
            {numberArray.map((count, index) => {
              return (
                <input
                  style={{
                    backgroundColor: "white",
                    fontSize: "30px",
                    width: "85px",
                    border: "none"
                  }}
                  disabled={submitted}
                  type="number"
                  data-index={index}
                  className="text-center align-items-center"
                  defaultValue={count}
                ></input>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
  const correctBar = (
    <nav className="d-flex navbar mt-5 mx-3 correct">
      <div className=" nav-item fs-3 mx-1 " style={{ color: "white" }}>
        <i class="fa-solid fa-check"></i> Correct!
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <button className="btn btn-light mx-3" onClick={handleNxt}>
          Next
        </button>
      </div>
    </nav>
  );
  return (
    <div style={{ backgroundColor: "#f2f1f1", height: "auto" }}>
      {/* <Navbar /> */}
      <div className="d-flex container-color mt-3 mx-3">
        <Col xs={10}>
          <div className="d-flex flex-row ">
            <div className="py-2 mx-2">
              <i
                class="fa-solid fa-arrow-left-long"
                type="button"
                onClick={() => navigate(`/homepage/practice`)}
              ></i>
            </div>
            <div className="py-2">Skip Counting AP -</div>
            <div className="py-2">Level {location.state.level}</div>
          </div>
        </Col>
        {/* <Col xs={2}>
          <div className="d-flex flex-row py-2 justify-content-end mx-2">
            {result.score}/{result.attempted}
          </div>
        </Col> */}
      </div>
      {location.state.level < 5 && (
        <h3 style={{ textAlign: "center", marginTop: "2%" }}>
          Difference is {numbers.d}
        </h3>
      )}
      <div
        className="container"
        style={{ display: "flex", marginTop: "3%", justifyContent: "center" }}
      >
        <div className="grids">
          {progression.map((count, index) => {
            return (
              <input
                style={{
                  backgroundColor: `${
                    location.state.level == 4
                      ? numbers.arr.includes(index)
                        ? "grey"
                        : "white"
                      : "white"
                  }`,
                  fontSize: "30px",
                  width: "85px",
                  border: "none"
                }}
                disabled={submitted || index == numbers.n || index == numbers.m}
                type="number"
                data-index={index}
                onWheel={(e) => e.target.blur()}
                className="text-center align-items-center"
                value={
                  location.state.level == 5
                    ? numbers.m === index || numbers.n === index
                      ? numberArray[index]
                      : count
                    : index === 0
                    ? numberArray[0]
                    : count
                }
                onChange={handleCountChange}
              ></input>
            );
          })}
        </div>
      </div>
      {location.state.level == 4 && (
        <div className="answer">
          <p>The sum of numbers in shaded boxes is </p>
          <input
            style={{
              backgroundColor: "white",
              fontSize: "30px",
              width: "75px",
              border: "none"
            }}
            disabled={submitted}
            type="number"
            className="text-center align-items-center"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          ></input>
        </div>
      )}
      <div className="d-flex mt-5 justify-content-center">
        <button
          // disabled={submitted || !answer}
          type="submit"
          className="btn btn-dark mb-5"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>

      {submitted === true
        ? location.state.level == 4
          ? parseInt(answer) === sum
            ? correctBar
            : incorrectBar
          : progression.length === numberArray.length &&
            progression.every((value, index) => value === numberArray[index])
          ? correctBar
          : incorrectBar
        : ""}
    </div>
  );
};

export default SkipCountingAP;
