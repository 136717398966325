import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { submitResult } from "../../redux/scoreSlice";

import ShowAnswer from "./ShowAnswer";
import './PairPattern.styles.css';
import WhiteBar from "../coreComponents/WhiteBar";
import { useDispatch } from "react-redux";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from 'react-router-dom';

/**
 * 
 * Rules - 3 samples and then 1 problem question 
 * for levels 0 to 2 and  3 samples and then 3 problem question for levels 3 to 5
    Level 0 - (A - 0 to 10)  and difference  ( D of 1 and 2 ) (c = 1) ,
    Level 1 -  (A - 0 to 15)  and difference  ( D of 1,2,3,4,5 ) (c = 1),
    Level 2 -  (A - 0 to 20)  and difference  ( D of 1 to 10 ) (c = 1),
    Level 3 -  (A - 0 to 25)  and difference  ( D of 1,2 ) (c = 1,2),
    Level 4 -  (A - 0 to 30)  and  difference  ( D of 1, 2, 3, 3, 5 ) (c = 1,2),
    Level 5 -  (A - 0 to 30)  and  difference  ( D of 1 to 10) (c = 1,2,3,4, 5), 

 */


function PairPattern() {
    const [pairs, setPairs] = useState([]);
    const [answers, setAnswers] = useState({
        answer4: '',
        answer5: '',
        answer6: '',
    });
    const [answer, setCorrectAnswer] = useState(null);
    const [show, setShow] = useState(false);
    const [cansubmit, setCansubmit] = useState(false);
    const [submitted, isSubmitted] = useState(false);

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [attempted, setAttempted] = useState(location.state.attempted);

    var level = Number(location.state.level), a, d, c, tempPair = [],
        /**if level is less than 3, 3 inputs are needed */
        noOfinputs = level < 3 ? 1 : 3;

    useEffect(() => {
        //A, &  A*C + D
        createInitialval();
        createPairs();
        // 
    }, [])

    const onChange = (e) => {
        /** changes the answers based on the input name */
        const { name, value } = e.target;
        setAnswers({
            ...answers,
            [name]: parseInt(value),
        });
        setCansubmit(true);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        
        setCorrectAnswer(check());
        setShow(true);
        isSubmitted(true);
        if (location.state.homework) {
            dispatch(updateHomeWork({
                module: location.state.topic, id: location.state.id, correct: answer
            }))
        }
        else {
            await dispatch(submitResult({
                result: answer ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
            }))
        }
        setAttempted(attempted + 1);
    }

    const check = () => {
        /** destructure and check based on the no of inputs */
        const { answer4, answer5, answer6 } = answers;
        if (noOfinputs === 1) {
            
            if ((pairs[3].a2 === answer4))
                return true;
            else
                return false;
        }
        else if (noOfinputs === 3) {
            if (pairs[3].a2 === answer4 && pairs[4].a2 === answer5 && pairs[5].a2 === answer6)
                return true;
            else return false;
        }
    }

    const next = () => {

        if (location.state.homework && attempted >= location.state.ques) {
            alert('you have completed your homework');
            navigate('/homepage/homework');
        }
        createInitialval();
        createPairs();
        reset();
        isSubmitted(false);
    }

    const reset = () => {

        setAnswers({
            answer4: '',
            answer5: '',
            answer6: '',
        });
        isSubmitted(false);
        setShow(false);
        setCansubmit(false);
    }


    const returnRandomNumber = (min, max) => {
        /** return random number between min and max */
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const createInitialval = () => {
        switch (level) {
            case 0:
                // Level 0 - (A - 0 to 10)  and difference  ( D of 1 and 2 ) (c = 1)
                a = returnRandomNumber(0, 10);
                d = returnRandomNumber(1, 2);
                c = 1;
                break;
            case 1:
                //Level 1 -  (A - 0 to 15)  and difference  ( D of 1,2,3,4,5 ) (c = 1),
                a = returnRandomNumber(0, 15);
                d = returnRandomNumber(1, 5);
                c = 1;
                break;
            case 2:
                //Level 2 -  (A - 0 to 20)  and difference  ( D of 1 to 10 ) (c = 1),
                a = returnRandomNumber(0, 20);
                d = returnRandomNumber(1, 10);
                c = 1;
                break;
            case 3:
                //Level 3 -  (A - 0 to 25)  and difference  ( D of 1,2 ) (c = 1,2),
                a = returnRandomNumber(0, 25);
                d = returnRandomNumber(1, 2);
                c = returnRandomNumber(1, 2);
                break;
            case 4:
                //Level 4 -  (A - 0 to 30)  and  difference  ( D of 1, 2, 3, 3, 5 ) (c = 1,2),
                a = returnRandomNumber(0, 30);
                d = returnRandomNumber(1, 5);
                c = returnRandomNumber(1, 2);
                break;
            case 5:
                // Level 5 -  (A - 0 to 30)  and  difference  ( D of 1 to 10) (c = 1,2,3,4, 5), 
                a = returnRandomNumber(0, 30);
                d = returnRandomNumber(1, 10);
                c = returnRandomNumber(1, 5);
                break;
            default:
                a = 1;
                d = 2;
                c = 3;
        }

    }

    const createPairs = () => {

        const pairLength = noOfinputs === 3 ? 6 : 4;
        for (var i = 0; i < pairLength; i++) {
            var a2 = (a * c) + d;
            tempPair[i] = { a1: a, a2: a2 };
            a = a2;
        }

        setPairs([...tempPair]);

    }

    const renderPairs = (pair, index) => {
        /** conditionally renders pairs */
        const style = {
            maxWidth: `${pairs[pairs.length - 1].a2.toString().length}ch`,
        }
        return index < (pairs.length - noOfinputs) ?
            <>
                <li key={index} className='d-flex gap-2 m-0'>
                    <span className="px-3 text-center flex-0.5 py-1 bg-light list-item">{pair.a1}</span>
                    <span className="px-3 text-center flex-0.5 py-1 bg-light list-item">{pair.a2}</span>
                </li>
            </>
            :
            <>
                <li key={index} className='d-flex gap-2 m-0' >
                    <span className="px-3 text-center flex-0.5 py-1 bg-light list-item">{pair.a1}</span>
                    <span className="list-border p-0 text-center flex-0.5 py-1 bg-light list-item "  >
                        <input
                            onChange={onChange}
                            style={style}
                            type="number"
                            disabled={submitted}
                            value={answers[`answer${index + 1}`]}
                            name={`answer${index + 1}`}
                            className="text-center align-items-center bg-light pair-input p-0 m-0 list-item"
                        ></input>
                    </span>
                </li>
            </>

    }

    return (

        <div style={{ backgroundColor: "#f2f1f1" }}>
            {/* <Navbar /> */}
            <WhiteBar label="Pair Pattern" level={location.state.level} />
            <div className="container mt-2 d-flex justify-content-center align-items-center flex-column ">
                <ul
                    className={`${noOfinputs === 1 ? 'list-grid-small' : 'list-grid'}
                    p-0 mt-4 mx-5 align-items-center justify-content-center`}
                    style={{ fontSize: '30px', listStyleType: 'none' }}>
                    {
                        pairs && pairs.map((pair, index) => (
                            renderPairs(pair, index, noOfinputs)
                        ))
                    }
                </ul>
                <div className="d-flex gap-4 justify-content-center">
                    <button
                        disabled={!cansubmit || !(noOfinputs === 1 ? answers.answer4 : (answers.answer4 && answers.answer5 && answers.answer6))}
                        onClick={reset}
                        type='submit'
                        className="btn btn-secondary mb-3 mt-5 px-4"
                    >
                        Reset
                    </button>
                    <button
                        disabled={!cansubmit || !(noOfinputs === 1 ? answers.answer4 : (answers.answer4 && answers.answer5 && answers.answer6))}
                        onClick={onSubmit}
                        type='submit'
                        className="btn btn-dark mb-3 mt-5 px-4"
                    >
                        Submit
                    </button>
                </div>
            </div>
            {show && <ShowAnswer next={next} inputs={noOfinputs} answer={answer} pairs={pairs} />}
        </div>
    )
}

export default PairPattern;