import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import ShowAnswer from './ShowAnswer';
import WhiteBar from '../coreComponents/WhiteBar';
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';

/**
 * 
 * We have to take a number which is not prime and then divide it randomly by one of its factors.
    Level 0 :- bigger number 0-10
    Level 1 :- bigger number 10-30
    Level 2 :- bigger number 30-60
    Level 3 :- bigger number 60-100
    Level 4 :- bigger number 100-200
    Level 5 :- bigger number 200-1000

 */

function Division() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let factors = [], a;

    //const { homework, homeworksubmit, ques} = location.state;
    
    const level = Number(location.state.level);
    const [answer, setAnswer] = useState('');
    const [numbers, setNumbers] = useState({a: 0, b: 0});
    const [answertype, setAnswertype] = useState(false);
    const [show, setShow] = useState(false);
    const [cansubmit, setCansubmit] = useState(false);
    const [submitted, isSubmitted] = useState(false);
    const [attempted, setAttempted] = useState(location.state.attempted);
    useEffect(() => {
        generateNumber();
       
        //if(homeworkSubmit) homeworkSubmit()

    }, [])

    const returnRandomNumber = (min, max) => {
        /** return random number between min and max */
        return Math.floor(Math.random() * (max - min + 1) + min);
    }


    const getfactors =  (num) => {

        factors = [];

        for(var i = 2; i < num; i++){

            if(num % i === 0) {
                factors.push(i);
            }
        }
       return factors[returnRandomNumber(0, factors.length - 1)];
    }



    const checkPrime = (a) => {
        if(a > 2) {
            for(var i = 2; i < a; i++){
                if(a % i === 0) 
                return false;
            }
            return true
        }
        else return true;

    }

    const generateNumber =  () => {

        switch(level){
            case 0://    Level 0 :- bigger number 0-10
            a = returnRandomNumber(0, 10);
            while(checkPrime(a)) {
                a = returnRandomNumber(0, 10);
            }
                break;
            case 1: //Level 1 :- bigger number 10-30
            a = returnRandomNumber(10, 0);
            while(checkPrime(a)) {
                a = returnRandomNumber(10, 30);
            }
                break;
            case 2://Level 2 :- bigger number 30-60
            a = returnRandomNumber(30, 60);
            while(checkPrime(a)) {
                a = returnRandomNumber(30, 60);
            }
                break;
            case 3://   Level 3 :- bigger number 60-100
            a = returnRandomNumber(60, 100);
            while(checkPrime(a)) {
                a = returnRandomNumber(60, 100)
            }
                break;
            case 4://Level 4 :- bigger number 100-200
            a = returnRandomNumber(100, 200);
            while(checkPrime(a)) {
                a = returnRandomNumber(100, 200)
            }
                break;
            case 5://Level 5 :- bigger number 200-1000
            a = returnRandomNumber(200, 1000);
            while(checkPrime(a)) {
                a = returnRandomNumber(200, 1000)
            }
                break;
                default: a = 0; break;
        }
        setNumbers({ a: a, b: getfactors(a)});

    }

    const reset = () => {
        setAnswer('');
        setCansubmit(false);
        isSubmitted(false);
        setShow(false);
    }
    
    const onChange = (e) => {
        /** changes the answers based on the input name */
        const { value } = e.target;
        setAnswer(value ? parseInt(value): '');
        setCansubmit(true);
    }

    const onSubmit = async e => {
        e.preventDefault();
        if(numbers.a / numbers.b === answer) {
            setAnswertype(true);
        } else setAnswertype(false);
        setShow(true);
        isSubmitted(true);
        setCansubmit(false);


        if(location.state.homework){
            dispatch(updateHomeWork({
             module: location.state.topic, id: location.state.id, correct: numbers.a / numbers.b === answer}))    
        }
        else {
            await dispatch(submitResult({
             result: answertype ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
        }
        setAttempted(attempted + 1);
    }

    const next = () => {
        
        if(location.state.homework && attempted >= location.state.ques) {
            alert('you have completed your homework');
            navigate('/homepage/homework');
        }
        generateNumber();
        setAnswer('');
        setCansubmit(false);
        isSubmitted(false);
        setShow(false);
    }



  return (
    <div style={{ backgroundColor: "#f2f1f1" }}>
        {/* <Navbar /> */}
        <WhiteBar label="Division" level={location.state.level}/>
        <div className="container mt-2 d-flex justify-content-center align-items-center flex-column ">
            <div className='d-flex gap-3 mt-4' style = {{ fontSize: '40px'}}>

                <p>{numbers.a}</p> <p>÷</p> <p>{numbers.b}</p> { ' = ' }
                <span className="p-0 text-center flex-0.5 py-1 bg-light"  >
                    <input
                    style={{ maxWidth: '100px'}}
                    type="number"
                    disabled={submitted}
                    onChange={onChange}
                    value={answer}
                    name={`answer`}
                    className="text-center align-items-center bg-light pair-input p-0 m-0"
                    ></input>
                </span>
            </div>
        <div className="d-flex gap-4 justify-content-center">
            <button
                disabled={!cansubmit  || !answer}
                onClick={reset}
                type='submit'
                className="btn btn-secondary mb-3 mt-5 px-4"
            >
                Reset
            </button>
            <button
                disabled={!cansubmit || !answer}
                onClick={onSubmit}
                type='submit'
                className="btn btn-dark mb-3 mt-5 px-4"
            >
                Submit
            </button>
        </div>
        </div>
       { show && <ShowAnswer numbers={numbers} answer={answertype} next={next}/>}
    </div>
  )
}

export default Division