import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import WhiteBar from "../../Component/coreComponents/WhiteBar";
import Navbar from "../../Component/Navbar/Navbar";
import { fetchWords } from "../redux/wordSlice";
import { type } from "@testing-library/user-event/dist/type";
import SentenceOne from "./SentenceOne";
import SentenceTwo from "./SentenceTwo";
import SentenceThree from "./SentenceThree";
import SentenceFour from "./SentenceFour";
import SentenceFIve from "./SentenceFive";

const Sentence = () => {
  const words = useSelector((state) => state.word.words);
  const dispatch = useDispatch();
  const location = useLocation();
  let level;
  let n;
  let type="Sentence"
  const a=true
  switch (parseInt(location.state.level)) {
    case 0:
      n = 10;
      level="'easy'"
      break;
    case 1:
      n = 10;
      level="'easy'"
      break;
    case 2:
      n = 10;
      level="'medium'"
      break;
    case 3:
      n = 10;
      level="'medium'"
      break;
    case 4:
      n = 10;
      level="'medium'"
      break;
    default:
      n = 5;
      break;
  }

  useEffect(() => {
 
    dispatch(fetchWords({ n, level,type }));

  }, [dispatch, n, level,type]);

  let content;

  switch (parseInt(location.state.level)) {
    case 0:
      content = <SentenceOne location={location} n={n} level={level} />;
      break;
    case 1:
      content = <SentenceTwo location={location}  n={n} level={level} />;
      break;
    case 2:
      content = <SentenceThree location={location}  n={n} level={level} />;
      break;
    case 3:
      content = <SentenceFour location={location}  n={n} level={level} />;
      break;
    case 4:
      content = <SentenceFIve location={location}  n={n} level={level} />;
      break;
    default:
      break;
  }

  return (
    <div>
      <WhiteBar label="Sentence" level={parseInt(location.state.level)} />
      {content}
    </div>
  );
};

export default Sentence;
