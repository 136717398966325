import { Col } from 'react-bootstrap';
import React from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'

const WhiteBar = (props) => {
    const navigate = useNavigate();
    const result = useSelector((state=>state.result))
  return (
  <div className="d-flex flex-row mt-3 mx-3 container-color">

    <Col xs={10}>
      <div className="d-flex flex-row ">
        <div className="py-2 mx-2">
            <i class="fa-solid fa-arrow-left-long" type="button" onClick={()=> navigate(-1)}></i>
        </div>
        <div className="py-2">
           {props.label}
        </div>
        {props.level?.toString() && <div className="py-2">
            - Level {props.level}
        </div>}
      </div>
    </Col>
    <Col xs={2}>
      <div className="d-flex flex-row py-2 justify-content-end mx-2">
          {result.score}/{result.attempted}
      </div>
    </Col>
  </div>
  )
}

export default WhiteBar