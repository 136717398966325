import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetScore } from '../redux/scoreSlice';

export const withAuth = (Component) => {
  return (props) => {
    const user = useSelector(state => state.user)
    const token = user.token;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
      if (!token) {
        navigate('/');
      }
    }, [token, navigate,dispatch,user]);

    return <Component {...props}/>;
  };
};