import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { act } from "react-dom/test-utils";
import { logoutUser } from "./authSlice";

const initialState = {
    score: 0,
    email: null,
    attempted: 0,
    time: null,
    loading: false,
    error: null
  };

export const submitResult= createAsyncThunk("submitResult", async (body) => {
  const response = await fetch(
    process.env.REACT_APP_API_ADDRESS + "/api/student/practice",

    {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${localStorage.getItem('token')}`
      },
      body: JSON.stringify(body),
    }
  );
  return response.json();
});

export const resetScore= createAsyncThunk("resetScore", async () => {
  const response = await fetch(
    process.env.REACT_APP_API_ADDRESS + "/api/student/resetScore",

    {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${localStorage.getItem('token')}`
      },
    }
  );
//   
  return await response.json();
});
const scoreSlice = createSlice({
  name: 'score',
  initialState,
  reducers: {
    correct: (state, action) => {
      state.score = state.score + 1;
      state.attempted = state.attempted + 1
    },
    incorrect: (state, action) => {
      state.attempted = state.attempted + 1
    },
  },
  extraReducers: {
    [submitResult.pending] : (state, action) => {
      state.loading = true
    },
    [submitResult.fulfilled] : (state, action) => {

      state.loading = false
      state.score = action.payload.practiceData.score;
      state.attempted = action.payload.practiceData.attempted;
      state.email = action.payload.practiceData.email;
      state.time = action.payload.practiceData.updatedAt;
    },
    [logoutUser.fulfilled]: (state, action) => {
        state = initialState;
        return state;
    },
    [resetScore.fulfilled] : (state, action) => {
      
      state.loading = false
      state.score = action.payload.practiceData.score;
      state.email = action.payload.practiceData.email;
      state.attempted = action.payload.practiceData.attempted;
      state.time = action.payload.practiceData.updatedAt;
    },
    [submitResult.rejected] : (state, action) => {
      state.loading = true
    }
  }
})
export const { correct, incorrect } = scoreSlice.actions;
export default scoreSlice.reducer;

// // export const selectAuth = (state) => state.auth;

// // export const selectToken = (state) => state.auth.token;

// // export const selectUser = (state) => state.auth.user;

// // export const selectLoading = (state) => state.auth.loading;

// // export const selectError = (state) => state.auth.error;
