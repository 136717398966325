import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import WhiteBar from '../coreComponents/WhiteBar';
import Navbar from "../Navbar/Navbar";
import P5Component from './Sketch.jsx';

function Shikaku() {
    const location = useLocation();
    const navigate = useNavigate();
    const level = Number(location.state.level);

    useEffect(() => {
        return
    }, [])

  return (
    <div style={{ backgroundColor: "#f2f1f1" }}>
        {/* <Navbar /> */}
        <WhiteBar label='Shikaku' level={location.state.level} />
        <div className='d-flex align-items-center justify-content-center mt-5'>
            <P5Component size = {6}/>
        </div>
    </div>
  )
}

export default Shikaku