import React, { useEffect, useRef } from "react";
import P5 from 'p5';


export default (props) => {

    const { grid, dim, answerGrid, setAnswer } = props;
    const size = 30;
    const offesetx = size, offsety = size;
    const containerRef = useRef(null);
    let background;
    //let dir= 'across';
    let firstCell = null;

    const Sketch = (p5) => {
        p5.setup = () => {
            p5.createCanvas(size * (dim.col + 2), size *( dim.row + 2));
            p5.background(255);
            //
            background = p5.createGraphics(p5.width, p5.height);
            background.background(255);
            //drawing matrix
           if(grid){
            for(var i = 0; i < dim.row; i++){
                for(var j = 0; j < dim.col; j++){
                    //
                    const val = grid[i][j];
                    const letter = grid[i][j].val || grid[i][j];
                    //

                    p5.strokeWeight(.5);
                    if(typeof(val) === 'object'){
                        background.push();
                        background.textSize(10);
                        background.stroke(0,0,0);
                        background.rect(((j * size) ) + offesetx ,((i * size) ) + offsety, size, size);
                        background.text(String(val.position), ((j * size) + (size / 4)) + offesetx,((i * size) + (size / 4)) + offsety)
                        background.pop()
                    }
                    else if(letter !== '-') {
                        background.stroke(0);
                        background.rect(((j * size)) + offesetx ,((i * size)) + offsety, size, size);
                        //p5.text(letter, ((j * size) + (size / 2)) + offesetx ,((i * size) + (size / 2)) + offsety)
                    }
                    
                }
               }
    
           }
           //
        }

        p5.draw = () => {
            p5.background(255);
            p5.image(background, 0, 0, p5.width, p5.height);

            showAnswerGrid();
        }

        const showAnswerGrid = () => {
            if(firstCell ){
                if(firstCell.row <= dim.col && firstCell.col <= dim.row){
                    const x = firstCell.row;
                    const y = firstCell.col;
                   // 
                    if(grid[y - 1][x - 1] !== '-')
                    {
                        p5.noFill();
                        p5.strokeWeight(2);
                        p5.stroke(255, 125,125);
                        p5.rect(firstCell.row * size, firstCell.col * size, size, size)
                    }
                }
                
            }
            for(var i = 0; i < dim.row; i++){
                for(var j = 0; j < dim.col; j++){
                   // 
                    if(answerGrid[i][j] !== '-') {
                        //p5.push();
                        p5.strokeWeight(1);
                        p5.noFill();
                        p5.stroke(255, 125, 125);
                        p5.text(answerGrid[i][j], ((j * size) + (size / 2)) + offesetx ,((i * size) + (size / 2)) + offsety)
                        //p5.pop();
                    }                
                }
               }
        }

        p5.keyPressed = () => {
            
            if(p5.keyCode >= 65 && p5.keyCode <= 90) {
                if (firstCell) {
                    let x = firstCell.row;
                    let y = firstCell.col;
                    //
                    if (grid[y - 1][x - 1] && grid[y - 1][x - 1] !== '-') {
                        p5.noFill();
                        p5.strokeWeight(2);
                        p5.stroke(125, 125, 255);
                        p5.rect(x * size, y * size, size, size)
                        firstCell = setAnswer(x, y, String.fromCharCode(p5.keyCode));
                       // 
                        // answerGrid[y - 1][x - 1] = String.fromCharCode(p5.keyCode);
                                
                        // switch (dir) {
                        //     case 'across':
                        //         firstCell = { row: x + 1, col: y };
                        //         break;
                        //     case 'down': 
                        //         firstCell = { row: x, col: y + 1};
                        //         break;
                        //     default: break;
                        //}
                    }
                }
            }

            //
        }

        p5.mousePressed = () => {
            //
            let x = Math.floor(p5.mouseX / size);
            let y = Math.floor(p5.mouseY / size);
           // 

            if(grid[y -1][x -1] && grid[y -1][x -1] !== '-'){


                if(grid[y -1][x -1] && grid[y -1][x -1] !== '-'){
                    firstCell = { row: x, col: y}
                    p5.noFill();
                    p5.strokeWeight(2);
                    p5.stroke(255, 125,125);
                    p5.rect(x * size, y * size, size, size)
                }
            }
        }


    }

    useEffect(() => {
      
     const can = new P5(Sketch, containerRef.current);
      return () => {
        can.remove()
      }
    }, [])
    


	return <div ref={containerRef}></div>;

}