import React from "react";
import { Children } from "react";
/**
 * onClick for next question and children for solution
 * no need to print "The correct solution is"
 */

const IncorrectBar = (props) => {
  return (
    <div className=" mx-3 mt-2">
      <nav className="d-flex navbar mb-2 incorrect">
        <div className="nav-item mx-2 fs-5" style={{ color: "white" }}>
          <i class="fa-solid fa-xmark mx-1"></i> Wrong !
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <button className="btn btn-light mx-2" onClick={props.onClick}>
            Next
          </button>
        </div>
      </nav>
      <div className="d-flex justify-content-center flex-column">
        <p className="align-self-start m-0">The correct solution is: </p>
        {props.children}
      </div>
    </div>
  );
};

export default IncorrectBar;
