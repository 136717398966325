import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const IncorrectBar = (props) => {
  const [expectedArray, setExpectedArray] = useState([]);
  const [expected, setExpected] = useState([]);
  const [expectedlast, setExpectedLast] = useState([]);
  
  useEffect(() => {
    setExpectedArray(props.Answer.slice(0, 3));
    setExpected(props.Answer.slice(3, 8));
    setExpectedLast(props.Answer.slice(8, 13));
  }, [props.Answer]);
  return (
    <div className=" mx-3">
      {props.message === "Incorrect" && (
        <nav className="d-flex navbar mb-2 incorrect">
          <div className="nav-item mx-2 fs-5" style={{ color: "white" }}>
            <i class="fa-solid fa-xmark"></i> Wrong !
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button className="btn btn-light mx-2" onClick={props.handleNext}>
              Next
            </button>
          </div>
        </nav>
      )}
      <div
        style={{
          marginLeft: "11%",
        }}
      >
        <div className="form-group ">
          {expectedArray.map((term, index) => (
            <input
              readOnly
              key={index}
              type="text"
              value={term}
              style={{
                width: "70px",
                marginRight: "10px",
                marginBottom: "10px",
                height: "50px",
              }}
              className="text-center border border-2"
            />
          ))}
          <div />
          <br />
          <div
            className="form-group"
            style={{
              display: "flex",
              marginLeft: "16.5%",
              marginBottom: "18px",
            }}
          >
            {expected.map((term, index) => (
              <input
                key={index}
                type="text"
                value={term}
                style={{
                  width: "70px",
                  marginRight: "10px",

                  height: "50px",
                }}
                className="text-center border border-2"
              />
            ))}
          </div>

          <div
            className="form-group text-center"
            style={{ display: "flex", marginLeft: "49.5%" }}
          >
            {expectedlast.map((term, index) => (
              <input
                key={index}
                type="text"
                value={term}
                style={{
                  width: "70px",
                  marginRight: "10px",

                  height: "50px",
                }}
                className="text-center border border-2"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export function Correct(props) {
  return (
    <>
      <nav className="d-flex navbar mt-5 mx-3 correct">
        <div className=" nav-item fs-3 mx-1 " style={{ color: "white" }}>
          <i class="fa-solid fa-check"></i> Correct!
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <button className="btn btn-light mx-3" onClick={props.handleNext}>
            Next
          </button>
        </div>
      </nav>
    </>
  );
}
export default IncorrectBar;
