import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import WhiteBar from "../../Component/coreComponents/WhiteBar";
import CorrectBar from "../../Component/coreComponents/CorrectBar";
import IncorrectBar from "../../Component/coreComponents/IncorrectBar";
import CustomButton from "../../Component/coreComponents/CustomButton";
import { Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { fetchWords } from "../redux/wordSlice";
import UserButton from "../../Component/coreComponents/UserButton/UserButton";
import { submitResult } from "../../redux/scoreSlice";
const levels = [
  {
    level: 0,
    words: [],
    category: [],
    n: 5,
    level: "'easy'",
    type: "scramble",
  },
  {
    level: 1,
    words: [],
    category: [],
    n: 5,
    level: "'easy'",
    type: "scramble",
  },
  {
    level: 2,
    words: [],
    category: [],
    n: 5,
    level: "'easy'",
    type: "scramble",
  },
  {
    level: 3,
    words: [],
    category: [],
    n: 5,
    level: "'easy'",
    type: "scramble",
  },
  {
    level: 4,
    words: [],
    category: [],
    n: 5,
    level: "'easy'",
    type: "scramble",
  },
  {
    level: 5,
    words: [],
    category: [],
    n: 5,
    level: "'easy'",
    type: "scramble",
  },
];

const ScrambleOne = () => {
  const dispatch = useDispatch();
  const words = useSelector((state) => state.word.words);

  // words from database
  const wordList = words.map((wordObj) => wordObj.word);

  const randomWords = wordList;
  
  const categorytList = words.map((wordObj) => wordObj.category);
  
  
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedLevel, setSelectedLevel] = useState(levels[location.state.level]);
  const [attempted, setAttempted] = useState(location.state.attempted);
  // Replace the words property with wordList
  const updatedLevels = levels.map((level) => {
    return {
      ...level,
      words: randomWords,
      category: categorytList,
    };
  });

  const [WORDS, SETWORDS] = useState(updatedLevels[0].words);

  const [category, setCategory] = useState(
    updatedLevels[location.state.level].category
  );
  const [scrambledWords, setScrambledWords] = useState([]);
  const [guesses, setGuesses] = useState(WORDS.map(() => ""));
  const [correctAnswers, setCorrectAnswers] = useState(WORDS.map(() => ""));
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);
  


  const handleGuess = async () => {
    const newCorrectAnswers = [...correctAnswers];
    const newIncorrectAnswers = [];
    guesses.forEach((guess, index) => {
      if (guess.toLowerCase() === WORDS[index].toLowerCase()) {
        newCorrectAnswers[index] = WORDS[index];
      } else {
        newIncorrectAnswers.push(WORDS[index]);
      }
    });
    const answer = newIncorrectAnswers.length ? true : false;

    if(location.state.homework){
      dispatch(updateHomeWork({
      module: location.state.topic, id: location.state.id, correct: answer}))    
    }
    else {
      await dispatch(submitResult({
      result: answer ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
    }
  setAttempted(attempted + 1);
    setCorrectAnswers(newCorrectAnswers);
    setIncorrectAnswers(newIncorrectAnswers);
  };

  const handleReset = () => {
    if(location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
  }
    const scrambled = WORDS.map((word) =>
      word
        .split("")
        .sort(() => Math.random() - 0.5)
        .join("")
    );
    setScrambledWords(scrambled);
    setGuesses(WORDS.map(() => ""));
    setCorrectAnswers(WORDS.map(() => ""));
    setIncorrectAnswers([]);
  };

  useEffect(() => {
    const { n, level, type } = selectedLevel;

    dispatch(fetchWords({ n, level, type }));
    // Scramble the words
    const scrambled = WORDS.map((word) =>
      word
        .split("")
        .sort(() => Math.random() - 0.5)
        .join("")
    );
    setScrambledWords(scrambled.sort());
    
  }, [dispatch, selectedLevel]);

  const isCorrect = (index) => {
    return correctAnswers[index] !== "";
  };

  const isIncorrect = (index) => {
    return incorrectAnswers.includes(WORDS[index]);
  };

  const allCorrect = correctAnswers.every((answer) => answer !== "");

  const example = scrambledWords.sort((a, b) => {
    return a - b;
  });
  const suggestions = (
    <div
      className="d-flex flex-row mt-3"
      style={{
        width: "50%",
        margin: "0 auto",
        border: "1px solid black",
        padding: "5px",
      }}
    >
      <Col xs={12}>
        <div className="d-flex justify-content-between align-items-center" style={{maxWidth: "100%"}}>
          {WORDS.map((word) => {
            return <h6 className="py-1 mx-2">{word}</h6>;
          })}
        </div>
      </Col>
    </div>
  );
  const hint = (
    <div>
      <ul className="list-group list-group-flush">
        {correctAnswers.map((word, index) => (
          <li
            className="list-group-item border-0 mb-2 mx-2 bg-transparent"
            key={index}
          >
            <h8 className="word">{`(Hint: ${category[index]})`}</h8>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <>
      <WhiteBar label="ScrambleGame" level={location.state.level} />
      {selectedLevel.level === "'easy'" && <div>{suggestions}</div>}
      <div className="container mt-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-4 d-flex justify-content-end">
              <div>
                <ul className="list-group list-group-flush ">
                  {scrambledWords.map((word, index) => (
                    <li
                      className="list-group-item border-0 mb-2 bg-transparent"
                      key={index}
                    >
                      <span className="word">{word}</span>
                    </li>
                  ))}
                </ul>
              </div>
              {selectedLevel.level === 1 && <div> {hint}</div>}
            </div>
            <div
              className="col-8 d-flex justify-content-center"
              style={{ paddingLeft: "131px" }}
            >
              <div className="d-flex flex-column justify-content-start align-items-center h-100 w-50">
                {scrambledWords.map((word, index) => (
                  <div
                    className="form-group w-100 d-flex justify-content-between align-items-center"
                    key={index}
                  >
                    <input
                      type="text"
                      className={`form-control mb-2 w-100 ${
                        isIncorrect(index) ? "is-invalid" : ""
                      }`}
                      id={`input-${index}`}
                      value={guesses[index]}
                      onChange={(e) => {
                        const newGuesses = [...guesses];
                        newGuesses[index] = e.target.value;

                        setGuesses(newGuesses);
                      }}
                    />
                    <div className="mx-2 col-3 d-flex justify-content-center">
                      {isIncorrect(index) && (
                        <h6 className="correct-answer">{WORDS[index]}</h6>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center gap-2 ">
        <UserButton
          label="Reset"
          onClick={handleReset}
          className="btn btn-primary btn-lg mt-3"
        />
        <UserButton
          label="Submit"
          onClick={handleGuess}
          className="btn btn-primary btn-lg mt-3"
        />
      </div>
      <div className="col-lg-12">
        {allCorrect && <CorrectBar onClick={handleReset} />}
        {incorrectAnswers.length > 0 && <IncorrectBar /> && (
          <div>
            <IncorrectBar onClick={handleReset} />
          </div>
        )}
      </div>
    </>
  );
};
export default ScrambleOne;
