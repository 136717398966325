import React, { useEffect, useState } from 'react'
import { returnRandomNumber } from './Fraction';
import ShowAnswer from './ShowAnswer';

import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from 'react-router-dom';




const MAX_SIZE = 6;




function Fraction_level1({ location }
) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [attempted, setAttempted] = useState(location.state.attempted);

    let a, b, c, box, boxorinput;
    const [boxes, setBoxes] = useState([]);
    const [numbers, setNumbers] = useState({ a: 0, b: 0, c: 0, f: 0 })
    const [selectedBox, setSelectedBox] = useState(0);
    const [cansubmit, setCansubmit] = useState(false);
    const [answertype, setAnswerType] = useState(false);
    const [type, setType] = useState(1)
    const [show, setShow] = useState(false);
    const [submitted, isSubmitted] = useState(false);
    const [answer, setAnswer] = useState('');
    useEffect(() => {
        generateNumbers();
        return () => {

        }
    }, [])
    const generateNumbers = () => {
        box = [];
        let factorS = [];
        boxorinput = returnRandomNumber(1, 2);
        setType(boxorinput);

        if (boxorinput === 1) {
            a = returnRandomNumber(1, MAX_SIZE);
            b = returnRandomNumber(1, MAX_SIZE);
            c = a * b;
            for (var x = 0; x < c; x++) {
                box.push(0);
            }
            for (let i = 2; i <= c; i++) {
                // check if number is a factor
                if ((c) % i == 0) {
                    factorS.push(i);
                }
            }

            setSelectedBox(0);
            setBoxes(box);
            setNumbers(prevNumbers => {
                const temp = returnRandomNumber(0, factorS.length - 1);
                return { a: a, b: b, c: c, f: factorS[temp] }
            });

        }
        else {
            a = returnRandomNumber(1, 500);
            for (let i = 2; i <= a; i++) {
                // check if number is a factor
                if ((a) % i == 0) {
                    factorS.push(i);
                }
            }
            factorS.sort();
            factorS = factorS.filter(x => {
                return x <= 10
            })
            if (factorS.length === 0) { factorS = [1, a] }
            setNumbers(prevNumbers => {
                
                const temp = returnRandomNumber(0, factorS.length - 1);
                return { a: a, f: factorS[temp] }
            });
        }
        
    }

    const onClick = (index) => {
        if (boxes[index] === 1) {
            boxes[index] = 0;
            setSelectedBox(selectedBox - 1);
            if (selectedBox - 1 === 0) {
                setCansubmit(false);
            }
        }
        else if (boxes[index] === 0) {
            boxes[index] = 1;
            setSelectedBox(selectedBox + 1);
            setCansubmit(true);
        }
        setBoxes([...boxes]);
    }
    const onSubmit = async () => {
        if (type === 1) {
            if (selectedBox === numbers.c / numbers.f)
                setAnswerType(true);
            else setAnswerType(false);
        } else {
            if (numbers.a / numbers.f === parseInt(answer)) {
                setAnswerType(true);
            }
            else setAnswerType(false);
        }

        setShow(true);
        isSubmitted(true);


        if (location.state.homework) {
            dispatch(updateHomeWork({
                module: location.state.topic, id: location.state.id, correct: answertype
            }))
        }
        else {
            await dispatch(submitResult({
                result: answertype ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
            }))
        }
        setAttempted(attempted + 1);

    }
    const next = () => {
        if (location.state.homework && attempted >= location.state.ques) {
            alert('you have completed your homework');
            navigate('/homepage/homework');
        }
        isSubmitted(false);
        setShow(false);
        setSelectedBox(0);
        setCansubmit(false);
        setNumbers({ a: 0, b: 0, c: 0, f: 0 });
        setAnswer('');
        generateNumbers();
    }
    const onChange = (e) => {
        const { value } = e.target;
        setAnswer(value ? parseInt(value) : '');
        setCansubmit(true);
        
    }
    return (
        <>
            {
                // numbers.a > 6 ? InputFractoin() : Square()
                type === 1 ? <Square /> : <InputFractoin />
            }
            {
                show &&
                <ShowAnswer next={next} answer={answertype}>
                    <p className='align-self-center' style={{ fontSize: '30px' }}>
                        1/{numbers.f} of {type !== 1 ? numbers.a : numbers.c} is {type === 1 ? numbers.c / numbers.f : numbers.a / numbers.f}
                    </p>
                </ShowAnswer>
            }
        </>
    )
    function InputFractoin() {
        return <>
            <div style={{ fontSize: '30px' }} className='d-flex flex-column flex-wrap mt-5 align-items-center justify-content-center'>
                <p>What is 1/{numbers.f} of {numbers.a}?</p>
                <span className="p-0 text-center flex-0.5 py-1 bg-light">
                    <input
                        style={{ maxWidth: '100px' }}
                        type="number"
                        disabled={submitted}
                        onChange={onChange}
                        value={answer}
                        name={`answer`}
                        className="text-center align-items-center bg-light pair-input p-0 m-0"
                    />
                </span>
                <button
                    disabled={!cansubmit || submitted || !answer}
                    onClick={onSubmit}
                    type='submit'
                    className="btn btn-dark mb-3 mt-5 px-4"
                >
                    Submit
                </button>
            </div>
        </>;
    }
    function Square() {
        return <>
            <div style={{ fontSize: '30px' }} className='d-flex flex-column flex-wrap mt-2 align-items-center justify-content-center'>
                <p>What is 1/{numbers.f} of {numbers.c}? Select the required no of boxes</p>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${numbers.a}, 1fr)`,
                    gridTemplateRows: `repeat(${numbers.b}, 1fr)`
                }}>
                    {boxes.map((box, index) => <div key={index} style={{ minWidth: '60px', minHeight: '60px' }}
                        className={`border border ${boxes[index] === 1 ? 'bg-danger' : 'bg-light'}`}
                        onClick={() => { onClick(index); }}></div>
                    )}
                </div>
                <button
                    disabled={!cansubmit || submitted}
                    onClick={onSubmit}
                    type='submit'
                    className="btn btn-dark mb-3 mt-2 px-4"
                >
                    Submit
                </button>
            </div>
        </>;
    }
}
export default Fraction_level1
