import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import UserButton from "../coreComponents/UserButton/UserButton";
import { Col } from "react-bootstrap";
import Navbar from "../Navbar/Navbar";
import ClockModel from "./ClockModel";
import CorrectBar from "../coreComponents/CorrectBar";
import { submitResult } from "../../redux/scoreSlice";
import { useDispatch } from "react-redux";
import { updateHomeWork } from '../../redux/homeWorkSlice';

function Level3({ location }) {
  const [hour, setHour] = useState(Math.floor(Math.random() * 12) + 1);
  const [minute, setMinute] = useState(Math.floor(Math.random() * 60));
  const [amPm, setAmPm] = useState("AM");
  const [answers, setAnswers] = useState([
    { label: "Hour", value: "" },
    { label: "Minute", value: "" },
    { label: "AM/PM", value: "" },
  ]);
  const [isCorrect, setIsCorrect] = useState(null);
  const questionType = [
    { AM: "Read the clock, if it is morning" },
    { PM: "Read the clock, if it is evening" },
    { militaryAM: "What is the time in the clock as per military time?, If it is morning" },
    { militaryPM: "What is the time in the clock as per military time?, If it is evening" },
  ]
  const [type, setType] = useState(questionType[Math.floor(Math.random() * 4)]);
  const dispatch = useDispatch();
  const [attempted, setAttempted] = useState(location.state.attempted);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (Object.keys(type)[0] !== "militaryPM") {
      setIsCorrect(false);
      if ((Object.keys(type)[0] === "militaryAM" && hour === 12 ? 0 : hour) === parseInt(answers[0].value) && minute === parseInt(answers[1].value) && (Object.keys(type)[0] === "militaryAM" ? true : Object.keys(type)[0] === (answers[2].value))) {
        setIsCorrect(true);
      }
    } else {
      setIsCorrect(false);
      if ((12 + hour) === parseInt(answers[0].value) && minute === parseInt(answers[1].value)) {
        setIsCorrect(true);
      }

    }
    if (location.state.homework) {
      dispatch(updateHomeWork({
        module: location.state.topic, id: location.state.id, correct: isCorrect
      }))
    }
    else {
      await dispatch(submitResult({
        result: isCorrect ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
      }))
    }
    setAttempted(attempted + 1);
  };

  const handleReset = () => {

    setAnswers([
      { label: "Hour", value: "" },
      { label: "Minute", value: "" },
      { label: "AM/PM", value: "" },
    ]);
  };

  const handleNext = () => {
    
    if (location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
    }
    setHour(Math.floor(Math.random() * 12) + 1);
    setMinute(Math.floor(Math.random() * 60));
    setType(questionType[Math.floor(Math.random() * 4)])
    setAnswers([
      { label: "Hour", value: "" },
      { label: "Minute", value: "" },
      { label: "AM/PM", value: "" },
    ]);
    setIsCorrect(null);
  };

  const incorrectBar = (
    <div className=" mx-3">
      <nav className="d-flex navbar incorrect">
        <div className="nav-item mx-2 fs-5" style={{ color: "white" }}>
          <i class="fa-solid fa-xmark"></i> Wrong !
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <button className="btn btn-light mx-2" onClick={handleNext}>
            Next
          </button>
        </div>
      </nav>
    </div>
  );

  return (
    <>
      <div className="d-flex justify-content-center">
        <h5>{Object.values(type)[0]}</h5>
      </div>
      <div className="d-flex">
        <div style={{ width: "41%" }} className="text-center">
          <ClockModel hour={hour} minute={minute} />
        </div>
        <div
          className="d-flex flex-row align-items-center justify-content-start"
          style={{ width: "59%" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="input-group mb-3">
              <input
                type="number"
                className="form-control text-center"
                style={{ fontSize: '30px', width: "50px" }}
                id="hour"
                value={answers[0].value}
                placeholder="hh"
                onChange={(event) => {
                  const newAnswers = [...answers];
                  newAnswers[0].value = event.target.value;
                  setAnswers(newAnswers);
                }}
              />
              <div className="mx-1" style={{ fontSize: '30px' }}>{`:`}</div>

              <input
                type="number"
                className="form-control text-center"
                style={{ fontSize: '30px', width: "50px" }}
                id="minute"
                value={answers[1].value}
                placeholder="mm"
                onChange={(event) => {
                  const newAnswers = [...answers];
                  newAnswers[1].value = event.target.value;
                  setAnswers(newAnswers);
                }}
              />

              {
                (Object.keys(type)[0] === "AM" ||
                  Object.keys(type)[0] === "PM") ?
                  <select
                    className="form-control text-center ms-1 form-select"
                    // style={{fontSize:'30px',width:"50px"}}
                    id="amPm"
                    value={answers[2].value}
                    onChange={(event) => {
                      const newAnswers = [...answers];
                      newAnswers[2].value = event.target.value;
                      setAnswers(newAnswers);
                    }}
                  >
                    <option></option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                  :
                  <div className="form-control text-center ms-1" style={{ fontSize: "30px" }}>

                    hrs
                  </div>
              }
            </div>
          </form>
        </div>
      </div>
      <div
        className="d-flex justify-content-center gap-2"
        style={{ marginTop: "-24px" }}
      >
        <UserButton label="Reset" onClick={handleReset} />
        <UserButton label="Submit" onClick={handleSubmit} />
      </div>
      {isCorrect && <CorrectBar onClick={handleNext} />}
      {isCorrect === false && (
        <div>
          {incorrectBar}
          <div className="d-flex justify-content-center">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "70%" }}
            >
              <div className="d-flex container-color mt-3 mx-3 w-50">
                <Col xs={12}>
                  <div className="text-center">
                    <h3 className="py-2 mx-2">{`${Object.keys(type)[0] !== "militaryPM" ? hour : 12 + hour} : ${minute} ${Object.keys(type)[0] === "PM" || Object.keys(type)[0] === "AM" ? Object.keys(type)[0] : "hrs"}`}</h3>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Level3;
