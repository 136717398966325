import React, { useState, useEffect} from 'react'
import { useSelector } from 'react-redux';


const Performance = () => {
  const user = useSelector(state=>state.user);
  
  if (user.role === 'ADMIN')
    return <>
      <AdminPerformance />
    </>
  else if (user.role === 'TEACHER')
    return <>
      <StudentPerformance user= {user} />
    </>
    else return <> <h1>Lol... Sneaky huh...</h1></>
}

export default Performance

const StudentPerformance = ({user}) => {

  const [loading, setLoading] = useState(false);
  const [student_perform, setStudentPerform] = useState(null);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/performance/viewPerformance/${user.className}${user.section}`,{
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    
    setStudentPerform(data.results);
    setLoading(false);
  }
  


  return (
    <div style={{ backgroundColor: "#f2f1f1", height: "700px" }}>
        {/* <Navbar /> */}
      <div className="d-flex mt-3 mx-3" style={{ backgroundColor: "white" }}>
        <div className="py-2 mx-2 fs-5" style={{ color: "black" }}>
          Performance !
        </div>
      </div >
      <div style={{minWidth: '100$'}} className='p-3'>
      {
        loading ? <h3>Loading...</h3> : student_perform && Student(student_perform)
      }
      </div>
    </div>
  )
}

const AdminPerformance =  () => {
  
    const [showStudent, setShowStudent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [teacherData, setTeacherData] = useState(null);
    const [student_perform, setStudentPerform] = useState(null);

    useEffect(() => {
      fetchData();
    }, [])

    
  const fetchData = async () => {
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/performance/viewAllPerformance`, {
      headers: {
        "Content-type": "application/json",
        "x-auth-token": `${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    
    setTeacherData(data.response);
    setLoading(false);
  }
  

    const onClick = async (classs, section) => {
      setShowStudent(!showStudent);
      if(!showStudent){
        setLoading(true);
        const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/performance/viewPerformance/${classs}${section}`,{
          headers: {
            "Content-type": "application/json",
            "x-auth-token": `${localStorage.getItem("token")}`,
          },
        });
        const data = await res.json();
        
        setStudentPerform(data.results);
        setLoading(false);
      }
    }
  return (
    <div style={{ backgroundColor: "#f2f1f1", height: "700px" }}>
        {/* <Navbar /> */}
      <div className="d-flex mt-3 mx-3" style={{ backgroundColor: "white" }}>
        <div className="py-2 mx-2 fs-5" style={{ color: "black" }}>
          Performance !
        </div>
      </div>
        <div style={{minWidth: '100$'}} className='p-3'>
          {
            loading ? <h3>Loading ...</h3>: 
            showStudent ? (student_perform && Student(student_perform, onClick)) : (teacherData &&  Class(teacherData, onClick))
          }
        </div>
    </div>
  )
}
 

function Student(dataStudent, onClick) {
  return <table className='table table-light text-center'>
    <thead>
      <tr>
        <th>S.no</th>
        <th>Name</th>
        <th>Score</th>
        <th>Questions Attempted</th>
        <th>Last attempt</th>
      </tr>
    </thead>
    <tbody>
      {dataStudent && dataStudent.map((s, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td onClick={onClick} className='class-hover'>{s.email}</td>
          <td>{s.score}</td>
          <td>{s.attempted}</td>
          <td>{new Date(s.updatedAt).toLocaleDateString()}</td>
        </tr>
      ))}
    </tbody>
  </table>;
}

function Class(dataClass, onClick) {
  
  return <table className='table table-light text-center'>
    <thead>
      <tr>
      <th>S.no</th>
        <th>Email</th>
        <th>Class</th>
        <th>Secton</th>
        <th>Average</th>
        <th>Total Practice Attempts</th>
        <th>Homework Given</th>
      </tr>
    </thead>
    <tbody>
      {dataClass && dataClass.map((c, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td onClick={() => onClick(c.CLASS, c.SECTION)} className='class-hover'>{`${c.email}`}</td>
          <td>{c.CLASS}</td>
          <td>{c.SECTION}</td>
          <td>{c.average}</td>
          <td>{c.total_attempted}</td>
          <td>{c.total_quesgiven}</td>
        </tr>
      ))}
    </tbody>
  </table>;
}
