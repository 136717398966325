import React, { useEffect, useState, useRef } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { submitResult } from "../../redux/scoreSlice";
import "./addition.css";
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectAdditonBar from "../coreComponents/IncorrectAdditonBar";
import WhiteBar from "../coreComponents/WhiteBar";
import UserButton from "../coreComponents/UserButton/UserButton";
import { updateHomeWork } from '../../redux/homeWorkSlice';

const Addition = () => {
  const inputRef = useRef(null);
  const [carry1, setCarry1] = useState();
  const [carry2, setCarry2] = useState();
  const [a, setA] = useState();
  const [b, setB] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const result = useSelector(state => state.result);
  const [attempted, setAttempted] = useState(location.state.attempted);
  const levels = [
    { level: 0, numbers:{
      a: Math.floor(Math.random() * 10),
      b: Math.floor(Math.random() * 10),
    }},
    { level: 1, numbers:{
      a: Math.floor(Math.random() * 10),
      b: Math.floor(Math.random() * 20),
    }},
    { level: 2, numbers:{
      a: Math.floor(Math.random() * 100),
      b: Math.floor(Math.random() * 100),
    }},
    { level: 3, numbers:{
      a: Math.floor(Math.random() * 899 + 100),
      b: Math.floor(Math.random() * 899 + 100),
    }},
    { level: 4, numbers:{
      a: Math.floor(Math.random() * 8999 + 1000),
      b: Math.floor(Math.random() * 8999 + 1000),
    }},
    { level: 5, numbers:{
      a: Math.floor(Math.random() * 899999 + 10000),
      b: Math.floor(Math.random() * 899999 + 10000),
    }},
  ]
  const [numbers, setNumbers] = useState(levels[parseInt(location.state.level)].numbers);
  const [answer, setAnswer] = useState();
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  
  const handleNxt = () => {
        

    if(location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
    }
    setAnswer("");
    setSubmitted(false);
    setNumbers(levels[parseInt(location.state.level)].numbers);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(
      submitResult({
        result:(parseInt(answer) === numbers.a + numbers.b ? "CORRECT" : "INCORRECT"),
        email:localStorage.getItem('email')
      })
      )
      
    if(location.state.homework){
      dispatch(updateHomeWork({
      module: location.state.topic, id: location.state.id, correct: parseInt(answer) === numbers.a + numbers.b }))    
    }
    else {
      await dispatch(submitResult({
      result: parseInt(answer) === numbers.a + numbers.b  ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
    }
    setAttempted(attempted + 1);

    setSubmitted(true);
  };


  useEffect(() => {
    inputRef.current.focus();
    setA(parseInt(numbers.a));
    setB(parseInt(numbers.b));
  
    let array = [a, b];
    let carry1 = 0;
    let carry2 = 0;
    
    if (a && b && a.toString().length === 2 && b.toString().length === 2) {
      
      for (let i = 0; i < array.length; i++) {
        let lastDigit = array[i] % 10;
        carry1 += lastDigit;
      }
      carry1 = Math.floor(carry1 / 10);
    }
    
    if (a && b && a.toString().length === 3 && b.toString().length === 3) {
      for (let i = 0; i < array.length; i++) {
        let lastDigit = array[i] % 10;
        let secondDigit = Math.floor((array[i] / 10) % 10);
        carry1 += lastDigit;
        carry2 += secondDigit;
      }
      carry1 = Math.floor(carry1 / 10);
      carry2 = Math.floor(carry2 / 10);
    }
    
    setCarry1(carry1);
    setCarry2(carry2);

  }, [a, b,answer]);
  return (
    <div>
      <WhiteBar label="Addition" level={location.state.level}/>
      <div className="container mt-4 ">
        <form onSubmit={handleSubmit}>

        <div className="d-flex flex-row justify-content-center">
          <Col className="d-flex flex-row justify-content-end">
          <div style={{fontSize:'40px'}}>{numbers.a}</div>
          <div className="mx-2" style={{fontSize:'40px'}}>+</div>
          <div style={{fontSize:'40px'}}>{numbers.b}</div>
          <div className="mx-2" style={{fontSize:'40px'}}>=</div>
          </Col>
          <Col className="d-flex text-center align-items-center">
          <input
            style={{ backgroundColor: "white", fontSize:'30px', width: 25*`${((numbers.a+numbers.b).toString().length)}`+10+"px", minWidth: "60px"}}
            disabled={submitted}
            type="number"
            ref={inputRef} 
            className="border-1 text-center"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          ></input>
          </Col>
        </div>
        <div className="d-flex mt-5 justify-content-center">
          <UserButton
            disabled={submitted || !answer}
            label="submit"
            className="btn btn-dark mb-5"
            onClick={handleSubmit}
          >
            Submit
          </UserButton>
        </div>
        </form>
      </div>
      {submitted === true
        ? parseInt(answer) === numbers.a + numbers.b
          ? <CorrectBar onClick={handleNxt}/>
          : <IncorrectAdditonBar addition="true" carry={{carry1:carry1,carry2:carry2}} numbers={numbers} onClick={handleNxt}/>
        : ""}
    </div>
  );
};

export default Addition;
