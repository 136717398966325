import React, { useState } from "react";

const selectedTopics = (user) => {
  
  switch (parseInt(user.className)) {
    case 1:
      return {
        math: [
          "Addition",
          "Subtraction",
          "Skip Counting",
          "Number Names",
          "Magic Triangle",
          "Magic Square",
          "Clock",
          "Pair Pattern",
          // "Nonogram",
          // 'Shikaku',
          "Skip Counting AP",
          "Changing Money",
           "Shopping Cart"
        ],
        eng: ["Scramble","Word search","Cross word", "Listen And Write ", "Listen And Write Sentence"],
      };
      break;
    case 2:
      return {
        math: [
          "Addition",
          "Subtraction",
          "Skip Counting",
          "Number Names",
          "Magic Triangle",
          "Magic Square",
          "Clock",
          "Tables",
          "Multiplication",
          "Pair Pattern",
          // "Nonogram",
          // 'Shikaku',
          "Skip Counting AP",
          "Changing Money",
          // "Shopping Cart",
          "Number Line",
        ],
        eng: ["Scramble","Word search","Cross word", "Listen And Write ", "Listen And Write Sentence"],
      };
      break;
    case 3:
      return {
        math: [
          "Addition",
          "Subtraction",
          "Skip Counting",
          "Number Names",
          "Magic Triangle",
          "Magic Square",
          "Clock",
          "Tables",
          "Multiplication",
          "Pair Pattern",
          // "Nonogram",
          'Shikaku',
          "Skip Counting AP",
          "Changing Money",
          "Shopping Cart",
          "Number Line",
          "Mental Maths",
          "Missing Multiplication",
          "Division",
          "Fractions",
          // "Kakuro"
        ],
        eng: ["Scramble","Word search","Cross word", "Listen And Write ", "Listen And Write Sentence"],
      };
      break;
    case 4:
      case 5:
      return {
        math: [
          "Addition",
          "Subtraction",
          "Skip Counting",
          "Number Names",
          "Magic Triangle",
          "Magic Square",
          "Clock",
          "Tables",
          "Multiplication",
          "Pair Pattern",
          // "Nonogram",
          // 'Shikaku',
          "Skip Counting AP",
          "Changing Money",
          // "Shopping Cart",
          "Number Line",
          "Mental Maths",
          "Missing Multiplication",
          "Division",
          "Fractions",
          // "Kakuro",
          "Add 2 Tables",
          "Missing Division",
          "Area and Perimeter",
        ],
        eng: ["Scramble","Word search","Cross word", "Listen And Write ", "Listen And Write Sentence"],
      };
      break;
      default:
        return {
        math: [
          "Addition",
          "Subtraction",
          "Skip Counting",
          "Number Names",
          "Magic Triangle",
          "Magic Square",
          "Clock",
          "Tables",
          "Multiplication",
          "Pair Pattern",
          // "Nonogram",
          'Shikaku',
          "Skip Counting AP",
          "Changing Money",
          "Shopping Cart",
          "Number Line",
          "Mental Maths",
          "Missing Multiplication",
          "Division",
          "Fractions",
          // "Kakuro",
          "Add 2 Tables",
          "Missing Division",
          "Area and Perimeter",
        ],
        eng: ["Scramble","Word search","Cross word", "Listen And Write ", "Listen And Write Sentence"],
      };
    }
  };
export default selectedTopics;
