import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import WhiteBar from '../coreComponents/WhiteBar';
import coin1 from "./Coin-1.png";
import coin2 from "./Coin-2.png";
import coin5 from "./Coin-5.png";
import Note10 from "./Note-10.png";
import Note20 from "./Note-20.png";
import Note50 from "./Note-50.png";
import Note100 from "./Note-100.png";
// import { getState, submitResult } from "../../redux/authSlice";\
import "./ChangingMoney.css";
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';


const Level0 = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);
  const denominations = [20, 10, 5, 2, 1];

  const moneyGenerator = (min, max) => {
    const denominations = [20, 10, 5, 2, 1];
    const maxTotal = Math.floor(Math.random() * max + 1)
    let total = 0;
    let result = [];

    let i = 0;
    for (i = 0; i <= denominations.length; i++) {
      const denomination = denominations[i];
      let value = Math.floor(Math.random() * (max / (denominations.length - i)));

      while (total + denomination * value > maxTotal) {
        value = Math.floor(Math.random() * (max / (denominations.length - i)));
      }

      if (total + denomination * value <= maxTotal) {
        result.push(value);
        total += denomination * value;
      }
    }

    return result;
  };
  const location = useLocation();
  const coin = [coin1, coin2, coin5, Note10, Note20];
  const [numbers, setNumbers] = useState(moneyGenerator(0, 100).reverse());
  const [answer, setAnswer] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (location.state.homework) {
      dispatch(updateHomeWork({
        module: location.state.topic, id: location.state.id, correct: answer
      }))
    }
    else {
      await dispatch(submitResult({
        result: answer ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
      }))
    }
    setAttempted(attempted + 1);


  };
  const handleNxt = () => {


    if (location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
    }
    setAnswer("");
    setSubmitted(false);
    setNumbers(moneyGenerator(0, 100).reverse());
  };
  return (
    <div>

      <div
        className="container"
        style={{
          display: "flex",
          marginTop: "3%",
          alignItems: "center",
          justifyContent: "space-evenly"
        }}
      >
        <p style={{ fontSize: "22px", fontWeight: "600" }}>Money You have</p>
        <table className="money-table">
          <tbody>
            <tr>
              {coin.map((coin, index) => {
                return (<>
                  {numbers[index] ? <td><img src={coin} alt="" /></td> : ''}
                </>)
              })}
            </tr>
            <tr>
              {numbers.map((num) => {
                return (
                  <>
                    {num ? <td>{num}</td> : ''}
                  </>
                )
              })}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="answer">
        <p style={{ fontSize: "22px", fontWeight: "600" }}>
          How much money do you have?{" "}
        </p>
        <input
          style={{
            backgroundColor: "white",
            fontSize: "30px",
            width: "75px",
            border: "none"
          }}
          disabled={submitted}
          type="number"
          className="text-center align-items-center"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
        />
      </div>
      <div className="d-flex mt-5 justify-content-center">
        <button
          disabled={submitted || !answer}
          type="submit"
          className="btn btn-dark mb-5"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      {submitted === true
        ? parseInt(answer) === numbers[4] * denominations[0] + numbers[3] * denominations[1] + numbers[2] * denominations[2] + numbers[1] * denominations[3] + numbers[0] * denominations[4]
          ? <CorrectBar onClick={handleNxt} />
          : <IncorrectBar onClick={handleNxt} label='hi'>hi</IncorrectBar>
        : ""}
    </div>
  )
}

export default Level0