import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { submitResult } from "../../redux/scoreSlice";
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectAdditonBar from "../coreComponents/IncorrectAdditonBar";
import WhiteBar from "../coreComponents/WhiteBar";
import Navbar from "../Navbar/Navbar";
import UserButton from "../coreComponents/UserButton/UserButton";
import { updateHomeWork } from '../../redux/homeWorkSlice';


import "./subtraction.css"
const Subtraction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const result = useSelector(state => state.result);
  const levels = [
    {
      level: 0, numbers: {
        a: Math.floor(Math.random() * 10),
        b: Math.floor(Math.random() * 10),
      }
    },
    {
      level: 1, numbers: {
        a: Math.floor(Math.random() * 20),
        b: Math.floor(Math.random() * 10),
      }
    },
    {
      level: 2, numbers: {
        a: Math.floor(Math.random() * 50),
        b: Math.floor(Math.random() * 50),
      }
    },
    {
      level: 3, numbers: {
        a: Math.floor(Math.random() * 90 + 10),
        b: Math.floor(Math.random() * 90 + 10),
      }
    },
    {
      level: 4, numbers: {
        a: Math.floor(Math.random() * 899 + 100),
        b: Math.floor(Math.random() * 899 + 100),
      }
    },
    {
      level: 5, numbers: {
        a: Math.floor(Math.random() * 89999 + 1000),
        b: Math.floor(Math.random() * 89999 + 1000),
      }
    },
  ]
  const [numbers, setNumbers] = useState(levels[parseInt(location.state.level)].numbers);
  const [answer, setAnswer] = useState();
  const [attempted, setAttempted] = useState(location.state.attempted);

  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();
  const handleNxt = () => {
    if(location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
  }
    setAnswer('');
    setSubmitted(false);
    setNumbers(levels[parseInt(location.state.level)].numbers);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (location.state.homework) {
      dispatch(updateHomeWork({
        module: location.state.topic, id: location.state.id, correct: parseInt(answer) === Math.abs(numbers.a - numbers.b)
      }))
    }
    else {
      await dispatch(submitResult({
        result: parseInt(answer) === Math.abs(numbers.a - numbers.b) ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
      }))
    }
    setAttempted(attempted + 1);

    setSubmitted(true);
  }
  return (
    <div>
      <WhiteBar label="Subtraction" level={location.state.level} />
      <div className="container mt-4 ">
        <form onSubmit={handleSubmit}>

          <div className="d-flex justify-content-center">
            <Col className="d-flex flex-row justify-content-end">
              <div style={{ fontSize: '40px' }}>{numbers.a >= numbers.b ? numbers.a : numbers.b}</div>
              <div className="mx-1" style={{ fontSize: '40px' }}>-</div>
              <div style={{ fontSize: '40px' }}>{numbers.a >= numbers.b ? numbers.b : numbers.a}</div>
              <div className="mx-1" style={{ fontSize: '40px' }}>=</div>
            </Col>
            <Col className="d-flex text-center align-items-center">

              <input style={{ backgroundColor: "white", width: 25 * `${((numbers.a + numbers.b).toString().length)}` + 10 + "px", minWidth: "60px", fontSize: '40px' }} disabled={submitted} type="number" className="text-center align-items-center border-0" value={answer} onChange={(e) => setAnswer(e.target.value)}></input>
            </Col>
          </div>
          <div className="d-flex mt-5 justify-content-center">
            <UserButton disabled={submitted || !answer} label="submit" className="btn btn-dark" onClick={handleSubmit}>Submit</UserButton>
          </div>
        </form>
      </div>
      {submitted === true ? (parseInt(answer) === parseInt(numbers.a >= numbers.b ? numbers.a : numbers.b) - parseInt(numbers.a >= numbers.b ? numbers.b : numbers.a) ? <CorrectBar onClick={handleNxt} /> : <IncorrectAdditonBar addition="false" numbers={numbers} onClick={handleNxt} />) : ''}




    </div>
  )
}

export default Subtraction;