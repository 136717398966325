import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomButton from "../coreComponents/CustomButton";
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";
import { useDispatch, useSelector } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from "react-router-dom";

function Circle({location}) {
  const dispatch = useDispatch();
    
  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);
  const [radius, setRadius] = useState( Math.floor(Math.random() * 30) + 1);
  const [area, setArea] = useState("");
  const [circumference, setCircumference] = useState();
  const [isCorrect, setIsCorrect] = useState(null);

  const checkAnswers = async () => {
    if (
      area === Math.round(Math.PI * Math.pow(radius, 2)) &&
      circumference === Math.round(2 * Math.PI * radius)
    ) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }

    if(location.state.homework){
      dispatch(updateHomeWork({
       module: location.state.topic, id: location.state.id, correct: isCorrect}))    
      }
      else {
          await dispatch(submitResult({
          result: isCorrect ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
      }
      setAttempted(attempted + 1);
};
const handleNext=()=>{
  if(location.state.homework && attempted >= location.state.ques) {
    alert('you have completed your homework');
    navigate('/homepage/homework');
  }
  setIsCorrect(null)
  setRadius(24)
  setArea("")
  setCircumference("")


}
  return (
<>
<Container>
<h6>{radius}</h6>
      <Row>
        <Col xs={12} md={5} className="mt-2">
          <div
            style={{
              backgroundColor: "white",
              width: "200px",
              height: "200px",
              borderRadius: "200px",
            }}
          ></div>
        </Col>
        <Col  xs={12} md={7} className="mt-5 d-flex">
 
          <Form>
          <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label>Area</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter area"
                value={area}
                onChange={(e) => setArea(parseInt(e.target.value))}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center">
              <Form.Label>Circumference</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter circumference"
                value={circumference}
                onChange={(e) => setCircumference(parseInt(e.target.value))}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <div className="text-center">
          <CustomButton label="Submit" onClick={checkAnswers}></CustomButton>
        </div>
    </Container>
    {isCorrect === true ? (
       <CorrectBar/>
      ) : (
       ""
      )}
      {isCorrect === false?(
        <Col className="text-center mt-2" onClick={handleNext}>
      <IncorrectBar/>
          <h4>Correct Answers</h4>
          <p>Area: {Math.round(Math.PI * Math.pow(radius, 2))}</p>
          <p>Circumference: {Math.round(2 * Math.PI * radius)}</p>
        </Col>
      ):("")}
    
</>
  );
}

export default Circle;
