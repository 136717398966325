import React, { useState, useEffect} from 'react'
import { useSelector } from 'react-redux';

function ViewStudentsHomework() {

    const user = useSelector((state) => state.user);
    
    const [studentData, setStudentData] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    useEffect(() => {

        gethomework();

    },[])

    const gethomework = async () => {

        const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/assignment/studentHomework/${user.className}${user.section}`);
        const data = await res.json();

        
        setStudentData(data.groupedResults);

    }

    const onChange = (e) => {

        setSelectedStudent(studentData[e.target.value]);
        
    }

  return (
    <>
    <div className="d-flex mt-3 mx-3" style={{ backgroundColor: "white" }}>
        <div className="py-2 mx-2 fs-5" style={{ color: "black" }}>
          Student Homework !
        </div>
      </div>
    {
        user.role === 'TEACHER' ? 
        <div className='d-flex flex-column gap-3 mt-3 mx-3'>
        
     {
        studentData &&  <Dropdown label={selectedStudent ?selectedStudent[0]?.email : 'students'} data={studentData.email} onChange={onChange} />
     }
     {
        selectedStudent ? <>
        <StudentTable studentData={selectedStudent}/>
        </> :
        <h3 className='text-center'>Students Homework</h3>
     }
    </div> :
    <h4 className='text-center'> Sneaky huh ... 🤫</h4>
    }
    </>
  )
}


function StudentTable({studentData}){
    return <table className='table table-light text-center'>
        <thead>
            <tr>
                <th>S.no</th>
                <th>Name</th>
                <th>Topic</th>
                <th>Level</th>
                <th>Number of Questions</th>
                <th>Attempted</th>
                <th>Correct</th>
            </tr>
        </thead>
        <tbody>
            {studentData && studentData.map((item, index) => (
                <tr key={item.assignmentID}>
                    <td className='align-middle'>{index + 1}</td>
                    <td className='align-middle'>{item.email}</td>
                    <td className='align-middle'>{item.topic}</td>
                    <td className='align-middle'>{item.level}</td>
                    <td className='align-middle'>{item.numberOfQues}</td>
                    <td className='align-middle'>{item.attempted}</td>
                    <td className='align-middle'>{item.correct}</td>
                </tr>
            ))}
        </tbody>
    </table>
}

function Dropdown({label, data, onChange}) {
    return (
        <>
         <select
         style={{ maxWidth: '200px'}}
        className='btn btn-dark dropdown-toggle'
        value={label}
        onChange={onChange}
        >
        <option >select topics</option>
        {data.map((email) => {
            return (
            <option value={email}>{email}</option>
            )
        })}
        </select>
        </>
    )
}

export default ViewStudentsHomework;