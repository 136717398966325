import React, { useEffect, useState } from "react";
import "./navbar.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate} from "react-router-dom";
import { logoutUser } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearHomework } from "../../redux/homeWorkSlice";
import { Modal, Button } from "react-bootstrap";
import boosterlogo from '../../assets/Asset 3.png';
import logo  from '../../assets/Asset 6.png';

/** magic triangle */
const Navbar = ({takeScreenshot}) => {

  const user = useSelector(state => state.user)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('token')
    const [imageData, setImage] = useState('');
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [show, setshow] = useState(false);
    const [text, setText] = useState('');
    const [response, setResponse] = useState(null);

    useEffect(() => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate('/');
      }
    }, [token, navigate]);


    const handleLogout = () => {
        dispatch(clearHomework());
      dispatch(logoutUser()).then(() => {
        navigate("/")
      })
    };

    const handleclose = () => {
        setshow(false);
        setLoading(false);
        setText('');
        setResponse(null);

    }

    const onSubmit = async () => {

      const date = new Date();
      const formattedDate = date.toLocaleDateString('en-GB').replace(/\//g, '-');
      const currentTime = date.toLocaleTimeString('en-US');
      const formattedTime = currentTime.replace(/:/g, '-');
      const filename = `${user.email}-${formattedDate}at${formattedTime}`;

      if(text.length ===0) {
        alert('Enter error description');
        return;
      }

      
      
      setUploading(true);

      const res =  await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/error/uploadError`, {
        method: 'POST',
        headers: {
          "Content-type": "application/json",
          "x-auth-token": `${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          data: imageData,
          description: text,
          filename: filename,
          email: user.email
        })
      });
      const data = await res.json();
      setResponse(data);
      

      setTimeout(() => {
        handleclose();
      }, 1000)
      setUploading(false);


    }

    const onchange = (e) => {
        setText(e.target.value);
    }

    const processImage = async () => {
        setshow(true);
        setLoading(true);
        const image = await takeScreenshot();
        
        setImage(image);
        setLoading(false);
    }


    return (
        <nav className="navbar navbar-light nav-color">
        <a className="navbar-brand align-items-center justify-content-center mx-4 d-flex gap-3 " onClick={()=>navigate('/')}>
            <img
            src={logo}
            style={{
              maxHeight: '34px',
              maxWidth: '34px',
            }} 
            />
            <img
            src={boosterlogo}
            style={{
              marginTop: '3px',
              maxHeight: '24px',
            }} 
            />
        </a>
        <ul className="nav justify-content-end">
            <li className="nav-item">
                <a className="nav-link">
                    <i className="fa-solid fa-house" type="button" onClick={()=>navigate(`/homepage`)}></i>
                </a>
            </li>
            <li className="nav-item">
            <button className="btn" onClick = {() => { processImage() }}>
                Report Error
            </button>
            </li>
            <li className="nav-item mx-2">
                {
                    token ?
                    <button onClick={handleLogout} className="btn">
                        Logout
                    </button> :
                    <button className="btn">
                        Welcome
                    </button>
                }
            </li>
        </ul>
        { show && <NavbarModal 
        onChange={onchange}
        text={text}
        image={imageData} 
        uploading={uploading}
        res={response}
        loading={loading}
        onSubmit ={onSubmit}
        handleClose={handleclose} />}
         
    </nav>
    )
}

function NavbarModal({handleClose, uploading, res, onChange, image, onSubmit, text, loading}) {


    return (
    <>
    <form>
      <Modal show={true} onHide={handleClose} >
      <Modal.Header closeButton>
        <Modal.Title>
            Define Error
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="text">Text:</label>
          <textarea
          required
            className="mb-2 form-control"
            id="text"
            rows="3"
            value={text}
            onChange={onChange}
          />
        </div>
        {
            loading ? <h2>Loading...</h2> :
                <div className="form-group">
                    <h5>Screenshot of Error</h5>
                    <img 
                    style={{
                        maxWidth: '100%'
                    }}
                    src={image} />
                </div>
        }
        {
          res && <p className={`text-left ${ res?.status ? 'text-success' : 'text-danger' }`}>
            { res?.status ? 'Error Reported Successfully' : 'There was some error'}
          </p>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button 
        disabled={uploading}
        variant="primary" onClick={onSubmit}>
        { uploading ? 'Saving...' : 'Submit'}
        </Button>
      </Modal.Footer>
    </Modal>
    </form>
    </>   
    );
    
}

export default Navbar;