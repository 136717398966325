import React from 'react'
import CorrectBar from '../../Component/coreComponents/CorrectBar';
import IncorrectBar from "../../Component/coreComponents/IncorrectBar";

function ShowAnswer({ answer, next, children }) {
  return (
    <>
    {
        (answer ?
        <CorrectBar onClick={next}/> : 
        <IncorrectBar onClick={next}>
            {children}
        </IncorrectBar> )
    }
    </>
  )
}

export default ShowAnswer