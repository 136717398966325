import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import UserButton from "../coreComponents/UserButton/UserButton";
import { Col } from "react-bootstrap";
import Navbar from "../Navbar/Navbar";
import ClockModel from "./ClockModel";
import CorrectBar from "../coreComponents/CorrectBar";
import { submitResult } from "../../redux/scoreSlice";
import { useDispatch } from "react-redux";
import { updateHomeWork } from '../../redux/homeWorkSlice';


function Level4({ location }) {
  const [hour, setHour] = useState(Math.floor(Math.random() * 12) + 1);
  const [minute, setMinute] = useState(Math.floor(Math.random() * 60));
  const [hourRandom, setHourRandom] = useState(Math.floor(Math.random() * 12) + 1);
  const [minuteRandom, setMinuteRandom] = useState(Math.floor(Math.random() * 60));
  const amPm = ['AM', 'PM'];
  const [correctAnswers, setCorrectAnswers] = useState()
  const [answers, setAnswers] = useState([
    { label: "Hour", value: "" },
    { label: "Minute", value: "" },
    { label: "AM/PM", value: "" },
  ]);
  const [isCorrect, setIsCorrect] = useState(null);
  const questionType = [
    { end: `Activity started at the time shown in the clock and lasted for ${hourRandom} hours` + (minuteRandom !== 0 ? ` and ${minuteRandom} mins` : ``) + `. At what time did it end ?` },
    { start: `Activity ended at the time shown in the clock and lasted for ${hourRandom} hours` + (minuteRandom !== 0 ? ` and ${minuteRandom} mins` : ``) + `. At what time did it start ?` },
  ]
  const [type, setType] = useState({
    ampm: amPm[Math.floor(Math.random() * 2)],
    question: Math.floor(Math.random() * 2)
  });
  const dispatch = useDispatch();
  const [attempted, setAttempted] = useState(location.state.attempted);

  const navigate = useNavigate();


  const handleSubmit = async (event) => {
    event.preventDefault();
    const correctMins = type.question ? (minute + minuteRandom) % 60 : (minute + 60 - minuteRandom) % 60;
    const correctHrs = type.question ? hour % 12 + hourRandom + parseInt((minute + minuteRandom) / 60) : hour % 12 - hourRandom + parseInt((minute + 60 - minuteRandom) / 60) + 23;
    if (parseInt(correctHrs / 12) % 2) {
      setCorrectAnswers({ hour: correctHrs % 12 + (!Boolean(correctHrs % 12)) * 12, minute: correctMins, ampm: Object.values(type)[0] === 'AM' ? 'PM' : 'AM' })
      setIsCorrect(parseInt(answers[1].value) === correctMins && parseInt(answers[0].value) === correctHrs % 12 + (!Boolean(correctHrs % 12)) * 12 && answers[2].value !== Object.values(type)[0])
    } else {
      setCorrectAnswers({ hour: correctHrs % 12 + (!Boolean(correctHrs % 12)) * 12, minute: correctMins, ampm: Object.values(type)[0] !== 'AM' ? 'PM' : 'AM' })
      setIsCorrect(parseInt(answers[1].value) === correctMins && parseInt(answers[0].value) === correctHrs % 12 + (!Boolean(correctHrs % 12)) * 12 && answers[2].value === Object.values(type)[0])

    }

    if (location.state.homework) {
      dispatch(updateHomeWork({
        module: location.state.topic, id: location.state.id, correct: isCorrect
      }))
    }
    else {
      await dispatch(submitResult({
        result: isCorrect ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
      }))
    }
    setAttempted(attempted + 1);
  };

  const handleReset = () => {
    if(location.state.homework && attempted >= location.state.ques) {
            alert('you have completed your homework');
            navigate('/homepage/homework');
        }
    setAnswers([
      { label: "Hour", value: "" },
      { label: "Minute", value: "" },
      { label: "AM/PM", value: "" },
    ]);
  };

  const handleNext = () => {
    setHour(Math.floor(Math.random() * 12) + 1);
    setMinute(Math.floor(Math.random() * 60));
    setHourRandom(Math.floor(Math.random() * 12) + 1);
    setMinuteRandom(Math.floor(Math.random() * 60));
    setType({
      ampm: amPm[Math.floor(Math.random() * 2)],
      question: Math.floor(Math.random() * 2)
    })
    setAnswers([
      { label: "Hour", value: "" },
      { label: "Minute", value: "" },
      { label: "AM/PM", value: "" },
    ]);
    setIsCorrect(null);
  };

  const correctBar = (
    <nav className="d-flex navbar mt-1 mx-3 correct">
      <div className=" nav-item fs-3 mx-1 " style={{ color: "white" }}>
        <i class="fa-solid fa-check"></i> Correct!
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <button className="btn btn-light mx-3" onClick={handleNext}>
          Next
        </button>
      </div>
    </nav>
  );

  const incorrectBar = (
    <div className=" mx-3">
      <nav className="d-flex navbar incorrect">
        <div className="nav-item mx-2 fs-5" style={{ color: "white" }}>
          <i class="fa-solid fa-xmark"></i> Wrong !
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <button className="btn btn-light mx-2" onClick={handleNext}>
            Next
          </button>
        </div>
      </nav>
    </div>
  );

  return (
    <>
      <div className="d-flex justify-content-center">
        <h5 className="my-2">{
          type.question ?
            `Activity started at the time shown in the clock and lasted for ${hourRandom} hours` + (minuteRandom !== 0 ? ` and ${minuteRandom} mins` : ``) + `. At what time did it end ?`
            :
            `Activity ended at the time shown in the clock and lasted for ${hourRandom} hours` + (minuteRandom !== 0 ? ` and ${minuteRandom} mins` : ``) + `. At what time did it start ?`
        }</h5>
      </div>
      <div className="d-flex">
        <div style={{ width: "30%" }} className="text-center">
          <ClockModel hour={hour} minute={minute} />
          {Object.values(type)[0]}
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: "70%" }}
        >
          <form onSubmit={handleSubmit}>
            <div className="form-group d-flex gap-1 w-50">
              <input
                type="number"
                className="form-control"
                id="hour"
                value={answers[0].value}
                onChange={(event) => {
                  const newAnswers = [...answers];
                  newAnswers[0].value = event.target.value;
                  setAnswers(newAnswers);
                }}
              />

              <input
                type="number"
                className="form-control"
                id="minute"
                value={answers[1].value}
                onChange={(event) => {
                  const newAnswers = [...answers];
                  newAnswers[1].value = event.target.value;
                  setAnswers(newAnswers);
                }}
              />
              <select
                className="form-control"
                id="amPm"
                value={answers[2].value}
                onChange={(event) => {
                  const newAnswers = [...answers];
                  newAnswers[2].value = event.target.value;
                  setAnswers(newAnswers);
                }}
              >
                <option>Select</option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div
        className="d-flex justify-content-center gap-2"
        style={{ marginTop: "-24px" }}
      >
        <UserButton label="Reset" onClick={handleReset} />
        <UserButton label="Submit" onClick={handleSubmit} />
      </div>
      {isCorrect && <CorrectBar onClick={handleNext} />}
      {isCorrect === false && (
        <div>
          {incorrectBar}
          <div className="d-flex justify-content-center">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "70%" }}
            >
              <div className="d-flex container-color mt-3 mx-3 w-50">
                <Col xs={12}>
                  <div className="text-center">
                    <h3 className="py-2 mx-2">{`${correctAnswers.hour} : ${correctAnswers.minute} ${correctAnswers.ampm}`}</h3>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Level4;
