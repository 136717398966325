import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap"
import "./Style.css";
import UserButton from "../coreComponents/UserButton/UserButton";
import WhiteBar from "../coreComponents/WhiteBar";
import IncorrectBar from "../coreComponents/IncorrectBar";
import CorrectBar from "../coreComponents/CorrectBar";
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';

const NumberNameGame = () => {
  const dispatch = useDispatch();

  const [questionType, setQuestionType] = useState("name");
  const [number, setNumber] = useState(1);
  const [answer, setAnswer] = useState("");
  const [result, setResult] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const [attempted, setAttempted] = useState(location.state.attempted);

  const level = () => {
    switch (parseInt(location.state.level)) {
      case 0:
        setQuestionType(Math.random() < 0.5 ? "name" : "number");
        setNumber(Math.floor(Math.random() * 20) + 1);
        break;
      case 1:
        setQuestionType(Math.random() < 0.5 ? "name" : "number");
        setNumber(Math.floor(Math.random() * 100) + 1);
        break;
      case 2:
        setQuestionType(Math.random() < 0.5 ? "name" : "number");
        setNumber(Math.floor(Math.random() * 1000) + 100);
        break;
      case 3:
        setQuestionType(Math.random() < 0.5 ? "name" : "number");
        setNumber(Math.floor(Math.random() * 10000) + 1000);
        break;
      case 4:
        setQuestionType(Math.random() < 0.5 ? "name" : "number");
        setNumber(Math.floor(Math.random() * 100000) + 10000);
        break;
      case 5:
        setQuestionType(Math.random() < 0.5 ? "name" : "number");
        setNumber(Math.floor(Math.random() * 1000000) + 100000);
        break;
      case 6:
        setQuestionType(Math.random() < 0.5 ? "name" : "number");
        setNumber(Math.floor(Math.random() * 10000000) + 1000000);
        break;
    }
  };
  useEffect(() => {
    level(location.state.level);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isCorrect = false;
    if (questionType === "name") {
      isCorrect = checkAnswerByName(number, answer);
    } else if (questionType === "number") {
      isCorrect = checkAnswerByNumber(number, answer);
    }
    setResult(isCorrect ? "Correct" : "Incorrect");
    if(location.state.homework){
      dispatch(updateHomeWork({
       module: location.state.topic, id: location.state.id, correct: isCorrect}))    
  }
  else {
      await dispatch(submitResult({
       result: isCorrect ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
  }
  setAttempted(attempted + 1);


  };

  const numberToWords = require("number-to-words");
  const lakhs = require("convert-rupees-into-words");
  const convert = lakhs(number)
    .replace(/Rupees\s*/i, "")
    .trim()
    .toLowerCase();
  const checkAnswerByName = (number, answer) => {
    const name = numberToWords.toWords(number).replace(/[,.-]/g, "");

    
    const lakh = lakhs(number)
      .replace(/Rupees\s*/i, "")
      .trim()
      .toLowerCase();

    if (number < 999999) {
      
      return lakh.toLowerCase() === answer.toLowerCase();
    } else {
      return name.toLowerCase() === answer.toLowerCase();
    }
  };

  const checkAnswerByNumber = (number, answer) => {
    
    

    return number == answer;
  };

  // rendering the questions

  const renderQuestion = () => {
    let question = "";
    if (questionType === "number") {
      if (number < 999999) {
        question = ` ${lakhs(number)
          .replace(/Rupees\s*/i, "")
          .replace(/\srupee\s*/i, "")}`;
      } else {
        question = ` ${numberToWords.toWords(number)}`;
      }
    } else if (questionType === "name") {
      question = ` ${number}`;
    }
    return question;
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const capitalizedValue =
      inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setAnswer(capitalizedValue);
  };

  const handleNext = () => {
    if(location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
  }
    setAnswer("");
    setResult("");
    level();
  };

  const handleReset = () => {
    setAnswer("");
  };

  return (
    <div style={{ backgroundColor: "#f2f1f1", height: "auto" }}>
      {/* <Navbar /> */}
      <WhiteBar label="Number Name" level={location.state.level} />
      <div className="container">
        <form onSubmit={handleSubmit} className="mt-3">
          <div className="mb-5">
            {questionType === "number" ? (
              <h4>Write the Corresponding numeral</h4>
            ) : (
              <h4>Write The Number In Words</h4>
            )}
          </div>

          <div className="d-flex justify-content-around">
            <div className="mb-5 text-center">
              <h5> {renderQuestion()}</h5>
            </div>

            <div
              className={
                number < 999
                  ? "form-group text-center w-25"
                  : "form-group text-center w-50" && questionType === "name"
                  ? "form-group text-center w-50"
                  : "form-group text-center w-25"
              }
            >
              <input
                type={questionType === "name" ? "text" : "number"}
                className="form-control text-center "
                placeholder="Enter your answer"
                value={answer}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center mb-2 gap-3">
            <UserButton
              className="btn btn-success"
              onClick={handleReset}
              label="Reset"
            />
            <UserButton
              onClick={handleSubmit}
              className="btn btn-primary btn-block "
              label="Submit"
              disabled={!answer}
            />
          </div>
        </form>
      </div>
      <div className=" mt-2">
        {result === "Correct" && <CorrectBar onClick={handleNext} />}
      </div>
      <div className=" mt-2">
        {result === "Incorrect" && (
          <IncorrectBar onClick={handleNext}>
            <div className=" mt-3">
              <div>
                <div className="d-flex justify-content-around">
                  <h3>Question:{renderQuestion()}</h3>
                  <input
                    value={
                      questionType === "number"
                        ? number.toString()
                        : number < 999999
                        ? lakhs(number)
                            .replace(/Rupees\s*/i, "")
                            .replace(/\srupee\s*/i, "")
                            .charAt(0)
                            .toUpperCase() +
                          lakhs(number)
                            .replace(/Rupees\s*/i, "")
                            .replace(/\srupee\s*/i, "")
                            .slice(1)
                            .toLowerCase()
                        : numberToWords
                            .toWords(number)
                            .replace(/[,.-]/g, "")
                            .charAt(0)
                            .toUpperCase() +
                          numberToWords
                            .toWords(number)
                            .replace(/[,.-]/g, "")
                            .slice(1)
                            .toLowerCase()
                    }
                    className={
                      number < 999
                        ? "w-50 form-control text-center w-50 mb-5"
                        : "w-50 form-control text-center"
                    }
                    readOnly
                  />
                </div>
              </div>
            </div>
          </IncorrectBar>
        )}
      </div>
    </div>
  );
};

export default NumberNameGame;
