import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import P5Component from './sketch.jsx'
import { matrixGenerator } from './generateMatrix';
import ShowAnswer from './ShowAnswer.jsx';
import WhiteBar from '../../Component/coreComponents/WhiteBar.jsx';
import { useSelector, useDispatch } from "react-redux";
import { fetchWords } from '../redux/wordSlice.jsx';
import { submitResult } from "../../redux/scoreSlice";


// const words =['CANVAS',
//     'CODE', 'FUN', 'FUNCTION', 'GAMES', 'KIDS', 'PLAY', 'SHARE', 'VARIABLE', 'JAVASCRIPT']; //JAVASCRIPT
// const size = 20;
// //const words = ['KIDS', 'PLAY', 'SHARE', 'VARIABLE',]
const directions = [
    [1, 0], //column
    // [-1, 0], //column reverse
    [0, 1], //row
    [1, 1], //diagonal
]

//use selector words
// use dispatch fetchWords

function WordSearch() {
    let s, w, validDir, maxSel;
    let tempw = useSelector(state => state.word.words);
    const dispatch = useDispatch();
    const location = useLocation();
    const level = Number(location.state.level);
    const [wordMatrix, setMatrix] = useState(null);
    const [selections, setSelections] = useState([]);
    const [words, setWords] = useState([]);
    const [size, setSize] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [answer, setAnswer] = useState([]);
    const [notfound, setNotFound] = useState([]);
    const [coOrds, setCoords] = useState([]);
    const [answertype, setAnswertype] = useState(false);
    const [show, setShow] = useState(false);
    const [meaning, setMeaning] = useState([]);


    useEffect(() => {
        generateMatrixParams();
        //getWords(10, "'easy', 'medium'");
        
        return () => {

        }
    }, [])

    const getWords = async (n, level) => {
        
        await dispatch(fetchWords({n, level}))
        
    }


    /** now we gonna do this
     * fill the matrix with these words 
     * in following order h v d reverse
     * lets see how we do this
     */


    const generateMatrixParams = async () => {
        switch(level){
            case 0://Level 0 : 15*15  matrix , 10 words  , horizontal or vertical placement.  Word list easy ;
                s = 15;
                await getWords(10, "'easy'");
                w = tempw.map(a => a.word.toUpperCase())
                validDir = [directions[0], directions[1]];
                break;
            case 1://Level 1 : 15*15 matrix , 10 words, horizontal, vertical or 
            //diagonal placement , word list easy 
                s = 15;
                w = await getWords(10, "'easy'");
                w = tempw.map(a => a.word.toUpperCase())
                validDir = directions;
                break;
            case 2://Level 2 : 18*18 matrix, 12 words, horizontal, vertical or
            //diagonal placement , word list easy + medium
                s = 18;
                w = await getWords(10, "'easy', 'medium'");
                w = tempw.map(a => a.word.toUpperCase());
                validDir = directions;
                break;
            case 3://Level 3 :  13*13 matrix, 10 words, horizontal, vertical or 
            //diagonal placement , meaning of the word ; word list easy;
                s = 13;
                w = await getWords(10, "'easy'");
                setMeaning(tempw.map(a=> a.meaning))
                w = tempw.map(a => a.word.toUpperCase());
                validDir = directions;
                break;
            case 4://Level 4 : 20*20 matrix, 12 words, horizontal, vertical or 
            //diagonal placement , word list medium + difficult
                s = 20;
                w = await getWords(10, "'medium', 'hard'");
                setMeaning(tempw.map(a=> a.meaning))
                w = tempw.map(a => a.word.toUpperCase());
                validDir = directions;
                break;
            case 5://Level 5 :  15*15 matrix, 10 words, horizontal, 
            //vertical or diagonal placement , meaning of the word ; word list medium + difficult;
                s = 15;
                w = await getWords(10, "'medium','hard'");
                setMeaning(tempw.map(a=> a.meaning))
                w = tempw.map(a => a.word.toUpperCase());
                validDir = directions;
                break;
            default: break;
        }

        
        generateMatrix();
    }


    const generateMatrix = async () => {

        let tmatrix = [];
        /** sorting words so the biggest word gets placed first */
        w = w.sort((a,b) => b.length - a.length)

        //filling matrix with '.'
        for (let i = 0; i < s; i++) {

            tmatrix.push([]);
            for (let j = 0; j < s; j++) {
                /** capital letters code go from 65 to 91 */
                tmatrix[i][j] = '.';//{ val: '.', filled: false }; // String.fromCharCode(randomNum(100, 90));
            }
        }

        console.time('started');

        let { matrix, coords } = await matrixGenerator(tmatrix, w, s, validDir);

        setMatrix(() => { return matrix});
        setSize(() => { return s})
        setWords(() => { return w});
        setCoords(() => { return coords})

        //
        
        console.timeEnd('started');

    }


    const reset = () => {
        setSubmitted(false);
        
        //somehow only this works
        selections.splice(0, selections.length);
        //setSelections([])
        setSelections(selections);

    }

    const add = (selection) => {

       // 
       
        if(selection && selections.length < words.length && !submitted){
            setSubmitted(false);
           
           selections.push({selection, type: 'default'});
           setSelections(selections);
        //    setSelections([
        //     ...selections,
        //     selection
        //    ])
        }   
          
    }

    const onSubmit = async () => {

        const temp = words.map(word => { return { word, found: false}}) 

        

        //selections.splice(0, selections.length)
        //answer.splice(0, answer.length)
        setAnswer(selections.map(({selection, type}) => {
            let selectWord = '', found;
            
            selection.map( ({ chr }) => {
                selectWord = `${selectWord}${chr}`
            })
            
            words.map( (word, index) => {
                if(word === selectWord){
                    type = 'found';
                    found = true;
                    
                    temp[index].found = true;
                } else {
                    //notfound.push({word: selectWord, coords: coOrds.get(selectWord)});
                }
            })
            if(found){
                return { selection, type: 'found'}
            }
            return { selection, type: 'wrong'}
        }));

        const wordsnotfound = [];
        for(let i = 0; i < temp.length; i++){
            if(!temp[i].found)
                wordsnotfound.push( { word: temp[i].word, coords: coOrds.get(temp[i].word) });
        }
        setNotFound(() => wordsnotfound);
        setSubmitted(() => true);
        if(wordsnotfound.length > 0) setAnswertype(false);
        else setAnswertype(true);

        setShow(true);
        await dispatch(submitResult({result: answertype ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
    }

    const next= () => {
        generateMatrixParams();
        setShow(false);
        setAnswertype(false);
        setSubmitted(false);
        setSelections([])
    }
    
    return (
        <>
        <WhiteBar label="Word Search" level ={location.state.level}/>           
            <div className='d-flex flex-column align-items-center mt-4 justify-content-center'>
                <div className='d-flex justify-content-around align-items-center' style={{ width: '100%'}}>
                <ul className='d-flex flex-column gap-2 m-0 p-0' style={{ fontSize: '25px'}}>
                    {
                        level < 3 ? words && words.map((word, index) => (
                            <li style={{listStyleType: 'decimal'}} key={index}><p className='my-0 mx-2'>{word}</p></li>
                        ))
                        :
                        meaning && meaning.map((meaning, index) => (
                            <li style={{listStyleType: 'decimal'}} key={index}><p className='my-0 mx-2'>{meaning}</p></li>
                        ))
                    }
                </ul>
               <span>
               { wordMatrix && <P5Component
               key={[words, submitted]}
               add = {add} 
               selections={selections} 
               size={size} 
               submitted={submitted}
               answer = {answer}
               notFound = { notfound }
               words={wordMatrix}/> }
                {/* <p style={{fontSize: '20px'}} className='m-0 p-0'>{text}</p> */}
               </span>
                </div>
                <div className='d-flex gap-4'>
                <button
                    disabled={submitted}
                    onClick={reset}
                    //type='submit'
                    className="btn btn-secondary mb-3 mt-2 px-4"
                >
                    Reset
                </button>
                <button
                    onClick={onSubmit}
                    disabled ={ submitted }
                    //type='submit'
                    className="btn btn-dark mb-3 mt-2 px-4"
                >
                    Submit
                </button>
                </div>
            </div>

            {
                show && <ShowAnswer answer={answertype} next={next} />
            }
        </>
    )
}

export default WordSearch