import React, {useState} from 'react';
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from 'react-router-dom';


const MentalMathLevel0 = ({location}) => {
    //Addition and subtraction without carry
    const level0 = () => {
        const withoutCarryPair = () => {
            const numb = Math.floor(Math.random()*10);
            const lessThanComplement = Math.floor(Math.random()*(10-numb));
            return [numb, lessThanComplement <= numb ? lessThanComplement : Math.floor(Math.random()*(numb))]
        }
        const nDigit = (n) => {
            let n1=0;
            let n2=0;
            for (let i =0; i < n; i++) {
                const pair = withoutCarryPair();
                
                n1 = n1*(Math.pow(10,i)) + pair[0];
                n2 = n2*(Math.pow(10,i)) + pair[1];
            }
            return [n1, n2]
        }
        const numberPair = nDigit(Math.floor(Math.random()*2)+1);
        return ({
            a: numberPair[0],
            b: numberPair[1]
        })
    }
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [attempted, setAttempted] = useState(location.state.attempted);
    const [numbers, setNumbers] = useState(level0());
    const [answer, setAnswer] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [operator, setOperator] = useState(Math.floor(Math.random()*2));

    const handleNxt = () => {
        setAnswer('');
        setSubmitted(false);
        setOperator(Math.floor(Math.random()*2));
        setNumbers(level0());
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        if(location.state.homework){
            dispatch(updateHomeWork({
             module: location.state.topic, id: location.state.id, correct: parseInt(answer) === (operator ? (numbers.a + numbers.b) : Math.abs(numbers.a - numbers.b))}))    
        }
        else {
            await dispatch(submitResult({
             result: parseInt(answer) === (operator ? (numbers.a + numbers.b) : Math.abs(numbers.a - numbers.b)) ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
        }
        setAttempted(attempted + 1);

    }

    const incorrectBar = (
        <div className=" mx-3">
            
            <nav className="d-flex navbar mt-5 incorrect">
                <div className="nav-item mx-2 fs-5" style={{color: "white"}}><i class="fa-solid fa-xmark"></i> Wrong !</div>
                <div className="d-flex justify-content-end align-items-center">
                    <button className="btn btn-light mx-2" onClick={handleNxt}>Next</button>
                </div>
            </nav>
            <div className="mx-1 mt-2">
                The correct solution is
                <div className="d-flex justify-content-center">
                <div className="display-1 ">{numbers.a}</div>
                <div className="display-1 ">{operator ? '+' : '-'}</div>
                <div className="display-1 ">{numbers.b}</div>
                <div className="display-1 ">=</div>
                <div style={{backgroundColor: "white"}} className="input display-1 text-center mb-5">{numbers.a + numbers.b}</div>
            </div>
            </div>
        </div>)
        const correctBar = (
            <nav className="d-flex navbar mt-5 mx-3 correct">
                        <div className=" nav-item fs-3 mx-1 " style={{color: "white"}}><i class="fa-solid fa-check"></i> Correct!</div>
                    <div className="d-flex justify-content-end align-items-center">
                        <button className="btn btn-light mx-3" onClick={handleNxt}>Next</button>
                    </div>
            </nav>
        )
  return (
    <div style={{backgroundColor: '#f2f1f1', height:'700px'}}>
            {/* <Navbar /> */}
            <div className="d-flex mt-3 mx-3 container-color">
                    <div className="py-2 mx-2">
                        {/* <i class="fa-solid fa-arrow-left-long" type="button" onClick={()=> navigate(`/homepage/practice`)}></i> */}
                    </div>
                    <div className="py-2">
                        Mental maths -
                    </div>
                    <div className="py-2">
                        Level 0
                    </div>
            </div>
            <div className="container mt-4 ">
                <div className="d-flex justify-content-center">
                    <div className="display-1">{numbers.a}</div>
                    <div className="display-1 ">{operator ? '+' : '-'}</div>
                    <div className="display-1 ">{numbers.b}</div>
                    <div className="display-1 ">=</div>
                    <input style={{backgroundColor: "white"}} disabled={submitted} type="number" className="input display-1 text-center align-items-center" value={answer} onChange={(e) => setAnswer(e.target.value)}></input>
                </div>
                <div className="d-flex mt-5 justify-content-center">

                    <button disabled={submitted || !answer} type="submit" className="btn btn-dark" onClick={handleSubmit}>submit</button>
                </div>
            </div>
            {submitted === true ?  (parseInt(answer) === (operator ? (numbers.a + numbers.b) : Math.abs(numbers.a - numbers.b)) ? correctBar : incorrectBar): ''}
            
            
            

        </div>
  )
}

export default MentalMathLevel0