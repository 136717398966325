import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Style.css";
import CustomButton from "../coreComponents/CustomButton";
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from 'react-router-dom';

function SquareAndTriangle({location}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);
  
  const [rectangles, setRectangles] = useState(getRandomRectangles());
  const [squares, setSquare] = useState(getRandomSquares());
  const [area, setArea] = useState("");
  const [perimeter, setPerimeter] = useState("");
  const [widths, SetWidth] = useState(Math.floor(Math.random() * 20) + 10);
  const [isCorrect, setIsCorrect] = useState(null);
  const [isSquare, setIsSquare] = useState(Math.random() < 0.5); 

  function getRandomSquares() {
    const sideLength1 = Math.floor(Math.random() * 20) + 10;
    const sideLength2 = Math.floor(Math.random() * (sideLength1 - 5)) + 5;
    const sideLength3 =
      Math.floor(Math.random() * (sideLength1 - sideLength2)) + 5;

    return [
      { sideLength: sideLength1 },
      { sideLength: sideLength2 },
      { sideLength: sideLength3 },
    ];
  }

  function getRandomRectangles() {
    const width = Math.floor(Math.random() * 20) + 10;
    const height1 = Math.floor(Math.random() * 10) + 5;
    const height2 = Math.floor(Math.random() * (height1 - 5)) + 5;
    const height3 = Math.floor(Math.random() * (height1 - height2)) + 5;

    return [
      { width: width, height: height1 },
      { width: width, height: height2 },
      { width: width, height: height3 },
    ];
  }

  async function checkAnswersRectangle  () {
    const totalArea = rectangles.reduce(
      (total, rectangle) => total + rectangle.width * rectangle.height,
      0
    );
    
    const totalPerimeter = rectangles.reduce(
      (total, rectangle) => total + 2 * (rectangle.width + rectangle.height),
      0
    );
    
    if (area === totalArea && perimeter === totalPerimeter) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
    if(location.state.homework){
      dispatch(updateHomeWork({
       module: location.state.topic, id: location.state.id, correct: isCorrect}))    
  }
  else {
      await dispatch(submitResult({
       result: isCorrect ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
  }
  setAttempted(attempted + 1);


  }

  function checkAnswersSquare() {
    const totalArea = squares.reduce(
      (total, square) => total + square.sideLength ** 2,
      0
    );
    
    const totalPerimeter = squares.reduce(
      (total, square) => total + 4 * square.sideLength,
      0
    );
    
    if (area === totalArea && perimeter === totalPerimeter) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  }

  function handleNext() {
    
  if(location.state.homework && attempted >= location.state.ques) {
    alert('you have completed your homework');
    navigate('/homepage/homework');
  }
    setRectangles(getRandomRectangles());
    setSquare(getRandomSquares());
    setIsSquare(Math.random() < 0.5);
    SetWidth(Math.floor(Math.random() * 20) + 10)
    setArea("");
    setPerimeter("");
    setIsCorrect(null);
  }
  const Rectangle = (
    <>
      <Col xs={12} md={4}>
        <div className="rectangle-container">
          <h2 style={{ marginTop: "21px" }}></h2>
          <div
            className="rectangle border"
            style={{
              height: "100px",
              backgroundColor: "White",
              width: "50%",
              marginLeft: "59px",
            }}
          ></div>
        </div>
      </Col>
      <Col xs={12} md={4}>
        <div className="rectangle-container">
          <h2>
            <span>{widths}</span>
          </h2>
          <div
            className="rectangle border"
            style={{ height: "50px", backgroundColor: "White" }}
          ></div>
          <h2 style={{ marginTop: "2px", width: "80%" }}></h2>
        </div>
      </Col>
      <Col xs={12} md={4}>
        <div className="rectangle-container">
          <h2 style={{ marginTop: "21px" }}></h2>
          <div
            className="rectangle border"
            style={{
              height: "100px",
              backgroundColor: "White",
              width: "50%",
            }}
          ></div>
        </div>
      </Col>
    </>
  );

  const Square = (
    <>
      <Col xs={12} md={4}>
        <div className="rectangle-container">
          <h2 style={{ marginTop: "21px" }}></h2>
          <div
            className="rectangle border"
            style={{
              height: "100px",
              backgroundColor: "White",
              width: "100%",
              // marginLeft: "59px",
            }}
          ></div>
        </div>
      </Col>
      <Col xs={12} md={4}>
        <div className="rectangle-container">
          <h2>
            <span>{widths}</span>
          </h2>
          <div
            className="rectangle border"
            style={{ height: "100px", backgroundColor: "White" }}
          ></div>
        </div>
      </Col>
      <Col xs={12} md={4}>
        <div className="rectangle-container">
          <h2 style={{ marginTop: "21px" }}></h2>
          <div
            className="rectangle border"
            style={{
              height: "100px",
              backgroundColor: "White",
              width: "100%",
            }}
          ></div>
        </div>
      </Col>
    </>
  );

  return (
    <>
      <Container>
        <Row>
          {isSquare ? (
            <Col xs={12} md={4} className="col-12 col-md-3 d-flex">
              {Square}
            </Col>
          ) : (
            <Col xs={12} md={4} className="col-12 col-md-3 d-flex">
              {Rectangle}
            </Col>
          )}

          <Col xs={12} md={7} className="mt-5 d-flex">
            <Form>
              <Form.Group className="d-flex align-items-center mb-3">
                <Form.Label className="mx-2"> Area</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter total area"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="d-flex align-items-center mb-3">
                <Form.Label className="mx-2">Perimeter</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter total perimeter"
                  value={perimeter}
                  onChange={(e) => setPerimeter(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>

        <div className="text-center mt-5">
          <CustomButton
            onClick={isSquare ? checkAnswersSquare : checkAnswersRectangle}
            label="Submit"
          />
        </div>
      </Container>
      <Col xs={12} md={12}>
        {isCorrect === true && <CorrectBar onClick={handleNext} />}
        {isCorrect === false && <IncorrectBar onClick={handleNext} />}
      </Col>
    </>
  );
}

export default SquareAndTriangle;
