import React, {useState} from 'react'
import './MentalMaths.css'
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from 'react-router-dom';


const MentalMathLevel1 = ({location}) => {
    //Complementary addition
    const level1 = (operator) => {
        const base = [10,100,1000]
        const result = base[Math.floor(Math.random()*base.length)];
        var r1 = Math.floor(Math.random()*result);
        var r2 = result - r1;
        
        return ({
            a: operator ? r1 : result,
            b:r2
        })
    }
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [attempted, setAttempted] = useState(location.state.attempted);

    const [operator, setOperator] = useState(Math.floor(Math.random()*2));
    const [numbers, setNumbers] = useState(level1(operator));
    const [answer, setAnswer] = useState();
    const [submitted, setSubmitted] = useState(false);

    const handleNxt = () => {
        setAnswer('');
        setSubmitted(false);
        setOperator(Math.floor(Math.random()*2));
        setNumbers(level1(operator));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        if(location.state.homework){
            dispatch(updateHomeWork({
             module: location.state.topic, id: location.state.id, correct: parseInt(answer) === (operator ? (numbers.a + numbers.b) : Math.abs(numbers.a - numbers.b))}))    
        }
        else {
            await dispatch(submitResult({
             result: parseInt(answer) === (operator ? (numbers.a + numbers.b) : Math.abs(numbers.a - numbers.b)) ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
        }
        setAttempted(attempted + 1);

    }

    const incorrectBar = (
        <div className=" mx-3">
            
            <nav className="d-flex navbar mt-5 incorrect">
                <div className="nav-item mx-2 fs-5" style={{color: "white"}}><i class="fa-solid fa-xmark"></i> Wrong !</div>
                <div className="d-flex justify-content-end align-items-center">
                    <button className="btn btn-light mx-2" onClick={handleNxt}>Next</button>
                </div>
            </nav>
            <div className="mx-1 mt-2">
                The correct solution is
                <div className="d-flex justify-content-center">
                <div className="display-1 ">{numbers.a}</div>
                <div className="display-1 ">{operator ? '+' : '-'}</div>
                <div className="display-1 ">{numbers.b}</div>
                <div className="display-1 ">=</div>
                <div style={{backgroundColor: "white"}} className="input display-1 text-center mb-5">{operator ? numbers.a + numbers.b : numbers.a - numbers.b }</div>
            </div>
            </div>
        </div>)
        const correctBar = (
            <nav className="d-flex navbar mt-5 mx-3 correct">
                        <div className=" nav-item fs-3 mx-1 " style={{color: "white"}}><i class="fa-solid fa-check"></i> Correct!</div>
                    <div className="d-flex justify-content-end align-items-center">
                        <button className="btn btn-light mx-3" onClick={handleNxt}>Next</button>
                    </div>
            </nav>
        )
  return (
    <div style={{backgroundColor: '#f2f1f1', height:'700px'}}>
            {/* <Navbar /> */}
            <div className="d-flex mt-3 mx-3 container-color">
                    <div className="py-2 mx-2">
                        {/* <i class="fa-solid fa-arrow-left-long" type="button" onClick={()=> navigate(`/homepage/practice`)}></i> */}
                    </div>
                    <div className="py-2">
                        Mental maths -
                    </div>
                    <div className="py-2">
                        Level 1
                    </div>
            </div>
            <div className="container mt-4 ">
                <div className="d-flex justify-content-center d-flex flex-row">
                    <div className="display-1">{numbers.a}</div>
                    <div className="display-1 ">{operator ? '+' : '-'}</div>
                    <div className="display-1 ">{numbers.b}</div>
                    <div className="display-1 ">=</div>
                    <input style={{backgroundColor: "white"}} disabled={submitted} type="number" className="input display-1 text-center align-items-center" value={answer} onChange={(e) => setAnswer(e.target.value)} inputMode='numeric' pattern="[0-9]*"></input>
                </div>
                <div className="d-flex mt-5 justify-content-center">

                    <button disabled={submitted || !answer} type="submit" className="btn btn-dark" onClick={handleSubmit}>submit</button>
                </div>
            </div>
            {submitted === true ?  (parseInt(answer) === (operator ? (numbers.a + numbers.b) : Math.abs(numbers.a - numbers.b)) ? correctBar : incorrectBar): ''}
            
            
            

        </div>
  )
}

export default MentalMathLevel1