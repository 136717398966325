import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { ExcelRenderer } from 'react-excel-renderer';
import WhiteBar from '../../Component/coreComponents/WhiteBar';

function AddItemForm() {
  const [items, setItems] = useState([]);
  const [currentItemName, setCurrentItemName] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const [currentPrice, setCurrentPrice] = useState('');
  const [currentPer, setCurrentPer] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [msg, setMsg] = useState();

  const handleItemNameChange = (event) => {
    setCurrentItemName(event.target.value);
  }

  const handleCategoryChange = (event) => {
    setCurrentCategory(event.target.value);
  }

  const handlePriceChange = (event) => {
    setCurrentPrice(event.target.value);
  }

  const handlePerChange = (event) => {
    setCurrentPer(event.target.value);
  
}
  const handleRemove = (index) => {
    // event.preventDefault();
    if(items.length === 1){
        setItems([])
    } else{
        const newItems = items.slice(); // create a new copy of the array
        newItems.splice(index, 1); // modify the new array
        setItems(newItems); // set the new array as the state
    }
    
  }

  const handleAdd = (event) => {
    event.preventDefault();
    const newItem = { itemName: currentItemName, category: currentCategory, price: currentPrice, per: currentPer };
    setItems([...items, newItem]);
    
    // reset current word fields
    setCurrentItemName('');
    setCurrentCategory('');
    setCurrentPrice('');
    setCurrentPer('');
  }

  const handleSubmit = async(event) => {
    event.preventDefault();
    const res = await fetch(
        process.env.REACT_APP_API_ADDRESS + "/shoppingCart/api/add-Items",
    
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "x-auth-token": `${localStorage.getItem('token')}`
          },
          body: JSON.stringify(items),
        }
      );
      const resJson = await res.json();
      
    }

    const onDrop = (acceptedFiles) => {
        let fileObj = acceptedFiles[0];
  
      // check for file extension
      if (!fileObj.name.endsWith('.xlsx') && !fileObj.name.endsWith('.xls')) {
        // setMsg('Please choose a valid Excel file');
        return;
      }
  
      // parse Excel file using ExcelRenderer
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          
        } else {
        //   setInputExcel(resp.rows);
          
          let rowLength = resp.rows.length;
          const itemList = [];
          while(rowLength--){
              const newItem = {
                  itemName: resp.rows[rowLength][0], 
                  category: resp.rows[rowLength][1], 
                  price: resp.rows[rowLength][2], 
                  per: resp.rows[rowLength][3] 
                }
                
                itemList.push(newItem);
            }
            setItems([...items,...itemList]);
          
          
        }
      });
      }

  return (
    <div className='overflow-hidden'>
        <WhiteBar label="Add items"/>
        <div className='mx-3'>

      <h1>Add items to Database</h1>
      <div className='my-3 d-flex justify-content-center '>
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <div className='input-group'>
              <input
               className='form-control'
               {...getInputProps()} 
            //    ref= {fileInputRef} 
               style={{width: 400, backgroundColor:"white"}}
               />
            </div>
          </div>
          )}
        </Dropzone>
              <div className="input-group-append">
                {/* <button className="input-group-text" id="basic-addon2" onClick={clearUpload}>X</button> */}
              </div>
              <p>Drag and drop Excel file here, or click to select file</p>
        </div>
        <table className="table">
        <thead className="thead-dark">
          <tr>
            <th style={{width: 20}}  scope="col">#</th>
            <th style={{width: 200}} scope="col">Item</th>
            <th style={{width: 400}} scope="col">Price</th>
            <th style={{width: 400}} scope="col">Category</th>
            <th style={{width: 400}} scope="col">per</th>
          </tr>
        </thead>
        <tbody>
      {items.length >0 && items.map((item, index) => (
                <tr>

            <th style={{width: 20}}scope="row">{index}</th>
            <td style={{width: 200}}><input className="form-control" type='text' value={items[index].itemName} onChange={(e)=>items[index].itemName = e.target.value}/></td>
            <td style={{width: 400}}><input className="form-control" type='text' value={items[index].price} onChange={(e)=>items[index].price = e.target.value}/></td>
            <td style={{width: 400}}><input className="form-control" type='text' value={items[index].category} onChange={(e)=>items[index].category = e.target.value}/></td>
            <td style={{width: 400}}><input className="form-control" type='text' value={items[index].per} onChange={(e)=>items[index].per = e.target.value}/></td>
            <td>

            <button className='btn btn-dark' onClick={()=>handleRemove(index)}>-</button>
            </td>
          </tr>
          ))}
          </tbody>
          </table>
            <form onSubmit={handleAdd}>
                <table className="table">
                    <tbody>
          <tr>
            <th style={{width: 20}} scope="row">{items.length}</th>
            <td style={{width: 200}}><input className="form-control" type='text' value={currentItemName} onChange={handleItemNameChange}/></td>
            <td style={{width: 400}}><input className="form-control" type='text' value={currentPrice} onChange={handlePriceChange}/></td>
            <td style={{width: 400}}><input className="form-control" type='text' value={currentCategory} onChange={handleCategoryChange}/></td>
            <td style={{width: 400}}><input className="form-control" type='text' value={currentPer} onChange={handlePerChange}/></td>
            <td>

            <button type="submit" className='btn btn-dark' disabled={!(currentCategory&&currentItemName&&currentPer&&currentPrice)}>+</button>
            </td>
          </tr>
        </tbody>
        </table>
        </form>
        <div className='text-center'>

                <button type="submit" className='btn btn-dark' onClick={handleSubmit}>Submit</button>
        </div>
        </div>
    </div>
  );
}

export default AddItemForm;
