import React, { useEffect, useState } from "react";
import "./Login.css";
import { useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../redux/authSlice";
import { resetScore } from "../../redux/scoreSlice";
import bigLogo from '../../assets/Asset 5.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
const Login = () => {
  const user = useSelector((state) => state.user);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [msg, setMsg] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const history = useNavigate();
  const dispatch = useDispatch();

  const handleUsernameChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await dispatch(loginUser({ email, password }))
      .then((res) => {
        if (res.payload) {
          
          setMsg(res.payload.msg);
        }

        if (res.error) {
          setMsg(res.error.message);
          
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    if (user.token) {
      history("/homepage");
    }
  };

  useEffect(() => {
    if (user.token) {
      // if(user.role === "STUDENT") dispatch(resetScore({email: user.email}));
      history("/homepage");
    }
  }, [user, history]);

  return (
    <section className="vh-100" style={{ backgroundColor: "white", maxHeight:'calc(100vh - 60px)' }}>
      <div
      style={{
        paddingLeft: '00px',
        paddingRight: '00px',
        //gap:'30px'
      }}
       className={`w-100 d-flex ${window.innerWidth < 768 ? 'flex-column pl-0 pr-5':'pl-0 pr-10'} h-100 gap-10 align-items-center  justify-content-center`}>
        {/** */}
        <div className="w-100 d-flex align-items-end justify-content-center align-self-end">
          <img
          className="w-100 max-h-100"
          src={bigLogo} 
          />
        </div>

        {/** form */}
        <div 
        style={{
          padding: '0 80px'
        }}
        className="w-100 m-0">
        <form onSubmit={handleLogin}>
              <div className="text-center">
                <img src=".\user.jpg" alt="Logo" className="img-fluids" />
              </div>
              <div className="form-outline mb-2">
                {msg && <p className="alert alert-danger">{msg}</p>}
                <label className="form-label" htmlFor="form3Example3">
                  User Id
                </label>
                <input
                  type="email"
                  autoFocus
                  id="form3Example3"
                  className={
                    msg
                      ? "is-invalid form-control form-control-cd"
                      : "form-control form-control-cd"
                  }
                  placeholder="Enter a valid email address"
                  value={email}
                  onChange={handleUsernameChange}
                />
              </div>
              <div className="form-outline mb-2">
                <label className="form-label" htmlFor="form3Example4">
                  Password
                </label>
                <input
                  type="password"
                  id="form3Example4"
                  className={
                    msg
                      ? "is-invalid form-control form-control-md"
                      : "form-control form-control-md"
                  }
                  placeholder="Enter password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
              <div className="text-center text-lg-start mt-4">
                <button
                  size="md"
                  type="submit"
                  onClick={handleLogin}
                  disabled={isLoading || !email || !password}
                  className="btn btn-primary btn-md w-100"
                  style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                >
                  {isLoading ? (
                    <>
                      <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                      Logging in...
                    </>
                  ) : (
                    "Log in"
                  )}
                </button>
              </div>
            </form>
        </div>
      </div>

      {/* <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-6 d-flex justify-content-center align-items-center">
        
      </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <form onSubmit={handleLogin}>
              <div className="text-center">
                <img src=".\user.jpg" alt="Logo" className="img-fluids" />
              </div>
              <div className="form-outline mb-2">
                {msg && <p className="alert alert-danger">{msg}</p>}
                <label className="form-label" htmlFor="form3Example3">
                  User Id
                </label>
                <input
                  type="email"
                  autoFocus
                  id="form3Example3"
                  className={
                    msg
                      ? "is-invalid form-control form-control-cd"
                      : "form-control form-control-cd"
                  }
                  placeholder="Enter a valid email address"
                  value={email}
                  onChange={handleUsernameChange}
                />
              </div>
              <div className="form-outline mb-2">
                <label className="form-label" htmlFor="form3Example4">
                  Password
                </label>
                <input
                  type="password"
                  id="form3Example4"
                  className={
                    msg
                      ? "is-invalid form-control form-control-md"
                      : "form-control form-control-md"
                  }
                  placeholder="Enter password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
              <div className="text-center text-lg-start mt-4">
                <button
                  size="md"
                  type="submit"
                  onClick={handleLogin}
                  disabled={isLoading || !email || !password}
                  className="btn btn-primary btn-md w-100"
                  style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                >
                  {isLoading ? (
                    <>
                      <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                      Logging in...
                    </>
                  ) : (
                    "Log in"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default Login;
