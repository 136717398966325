import React from 'react'
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";

function ShowAnswer({ answer, pairs, next, inputs }) {
  return (
    <>
    {
        (answer ?
        <CorrectBar onClick={next}/> : 
        <IncorrectBar onClick={next}>
            <ul className={`${inputs === 3 ? 'list-grid-answer-big' : 'list-grid-answer-small'} p-0 my-0 align-self-center`} style={{ fontSize: '30px'}}>
                {
                    pairs.map((pair, index) =>   
                    <li key={index} className='d-flex gap-1 m-0'>
                      <span className="px-0 text-center flex-0.5 py-0 bg-light list-item-answer">{pair.a1}</span>
                      <span className="px-0 text-center flex-0.5 py-0 bg-light list-item-answer">{pair.a2}</span>{','}
                    </li>
                    )
                }
            </ul>
        </IncorrectBar> )
    }
    </>
  )
}

export default ShowAnswer