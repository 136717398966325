import React, { useEffect, useState } from 'react';
import { generateLayout } from 'crossword-layout-generator';
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchWords } from '../redux/wordSlice.jsx';
import WhiteBar from '../../Component/coreComponents/WhiteBar.jsx';
import P5Component from './sketch';
import ShowAnswer from './ShowAnswer.jsx';
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from 'react-router-dom';

/**
 * 
 *  Level 0 - 7 words , easy word list 
    Level 1 - 10 words, easy word list 
    Level 2 - 10 words, medium word list 
    Level 3 - 12 words, medium word list
    Level 4 - 12 words, difficult word list
    Level 5 - 14 words, difficult word list 

 */


function CrossWord() {

    const tempw = useSelector((state) => state.word.words)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [attempted, setAttempted] = useState(location.state.attempted);
    const Level = Number(location.state.level);
    const [table, setTable] = useState([]);
    const [dimen, setDimen] = useState({ row: 0, col: 0}); //dimensions
    const [clues, setClues] = useState(null);
    const [dir, setDir] = useState('across');
    const [answerGrid, setAnswerGrid] = useState([]);
    const [answertype, setAnswertype] = useState(false);
    const [show, setShow] = useState(false);
    const [cansubmit, setCansubmit] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [answer, setanswer] = useState([]);

    useEffect(() => {

       
        generateInput();
        // const layout = generateLayout(input);
        // 
        // setTable(() => layout.table)
    }, [])


    //function which returns words
    const getWords = async () => {
        let n, level;
        switch(Level){
            case 0: //Level 0 - 7 words , easy word list 
                n = 7;
                level = "'easy'";
                break;
            case 1://Level 1 - 10 words, easy word list 
                n = 10;
                level = "'easy'";
                break;
            case 2://Level 2 - 10 words, medium word list 
                n = 10;
                level = "'medium'";
                break;
            case 3://Level 3 - 12 words, medium word list
                n = 12;
                level = "'medium'"
                break;
            case 4://Level 4 - 12 words, difficult word list
                n = 12;
                level = "'hard'";
                break;
            case 5:// Level 5 - 14 words, difficult word list 
                n = 14;
                level = "'hard'";
                break;
        }
        await dispatch(fetchWords({n, level}))
        
    }

    const resetAnswer = (rows, cols) => {
        for(var i = 0; i< rows; i++){
            answerGrid.push([]);
            for(var j = 0; j< cols; j++){
                answerGrid[i].push('-');
            }
        }
        setAnswerGrid(() => answerGrid);
    }

    //function to generate input for the lib

    const generateInput = async () => {

        //json { clue, answer};
        await getWords();
        let input = tempw.map(a => { return { clue: a.meaning, answer: a.word}});
        
        let l = generateLayout(input);
        let { rows, cols, result, table } = l;
        
        resetAnswer(rows, cols);

        let temp = result.map( ({ startx, starty, answer, orientation, position}) => {
            if(orientation !== 'none') {
               // 
                table[starty -1][startx - 1] = { val: table[starty - 1][startx - 1], start: true, position: position };
                return { value: table[starty -1][startx - 1], start: true, position: position}
            }
        })
        
        setDimen({row: rows, col: cols});
        setAnswerGrid(() => answerGrid);
        setTable(() => table);
        setClues(() => result);
    }

    const setAnswer = (x, y, char) => {
        answerGrid[y - 1][x - 1] = char;
        //setAnswerGrid(() => answerGrid);
        setCansubmit(true);
        //
       // 

       
        switch (dir) {
            case 'across':
                
                return { row: x + 1, col: y };
            case 'down': 
                return { row: x, col: y + 1};
            default: break;
        }

    }

    const check = () => {
        let wrong = true, tempArray= [];
       setClues( clues.map((word) => {

        let { startx, starty, orientation, position, answer } = word;
        //
        let bool = false;
        if(startx && starty){
            let x = startx - 1;
            let y = starty - 1;

            let word = ''
            if(orientation === 'across'){
                for(var i = 0; i < answer.length; i++){
                    word = `${word}${answerGrid[y][x + i]}`;
                }
                //
                if(word !== answer.toUpperCase()){
                    wrong = false;
                    bool = true;
                }
            }
            if(orientation === 'down'){
                for(var i = 0; i < answer.length; i++){
                    word = `${word}${answerGrid[y  + i][x]}`;
                }
                //
                if(word !== answer.toUpperCase()){
                    wrong = false;
                    bool = true;
                }
            }
            
        }
        tempArray.push({...word, wrong: bool});
        return {...word, wrong: bool}
        }))

       //clues.splice(0, clues.length);
        //clues = [...tempArray]
        setanswer(() => { return tempArray});
        

        // answerGrid.map((a, i1) => {
        //     a.map((b, i2) => {
        //         const letter = typeof(table[i1][i2]) === 'object' ? table[i1][i2].val : table[i1][i2];
        //         //
        //         if(b !== String(letter)?.toUpperCase()) wrong = false;;
                
        //     })
        // })

        return wrong;
    }

    const onSubmit = async () => {
        setAnswertype(check());
        setShow(true);
        setSubmitted(true);
        if(location.state.homework){
            dispatch(updateHomeWork({
             module: location.state.topic, id: location.state.id, correct: answertype}))    
        }
        else {
            await dispatch(submitResult({
             result: answertype ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
        }
        setAttempted(attempted + 1);

    
        
    }

    const next = () => {
        
        if(location.state.homework && attempted >= location.state.ques) {
            alert('you have completed your homework');
            navigate('/homepage/homework');
        }
        answerGrid.splice(0, answerGrid.length);
        setAnswerGrid(() => [])
        setShow(false);
        setAnswertype(false);
        setCansubmit(false);
        setSubmitted(false);
        getWords();
        generateInput();
    }

    return (
    <>
     <WhiteBar label="Cross Word" level={location.state.level} />
        <div style={{ fontSize: '20px'}} className='d-flex justify-content-center align-items-center flex-column'>
            <div style={{ width: '100%'}} className='text-left d-flex align-items-center mt-4 justify-content-around'>
            {/** question */}
            <div className='d-flex gap-3'>
            <div className='d-flex flex-column'>
            <div>
                { /** across */}
                <h4 className='border-0 px-0 py-2 m-0'>Across</h4>
                <ul className='d-flex flex-column mb-2 p-0 gap-2'>
                    
                    { clues && clues.map( (res, index) => 
                        res.orientation === 'across' ? 
                        <li key ={index} style={{minWidth: '200px'}}>
                            <span className='d-flex gap-2 justify-content-between'>
                                <p className='m-0 p-0'>{`${res.position}. ${res.clue}`}</p>
                                {
                                    
                                    (submitted && !answertype) ? 
                                    <p className={`m-0 px-2 align-self-end ${res.wrong ? 'text-danger' : 'text-success'}`}>{`${res.answer}`}</p>
                                    : null
                                }
                            </span>
                        </li>
                     : null
                    )}
                </ul>
            </div>
            <div>
                { /** down */}
                <h4 className='border-0 px-0 py-2  m-0'>Down</h4>
                <ul className='m-0 p-0 d-flex flex-column gap-2'>
                   
                    { clues && clues.map( (res, index) => 
                        res.orientation === 'down' ? 
                        <li key ={index}>
                            <span className='d-flex gap-2 justify-content-between'>
                                <p className='m-0 p-0'>{`${res.position}. ${res.clue}`}</p>
                                {
                                    
                                    (submitted && !answertype) ? 
                                    <p className={`m-0 px-2 align-self-end ${res.wrong ? 'text-danger' : 'text-success'} `}>{`${res.answer}`}</p>
                                    : null
                                }
                            </span>
                        </li> : null
                    )}
                </ul>
            </div>
            </div>
            {/** wrong answer */}
            
            </div>
            {/** show grid */}
            <div className='d-flex gap-2'>
            {table.length && <>
                <div className='d-flex flex-column gap-2'>
                    <button
                    className={`btn ${dir === 'across' ? 'btn-success': 'btn-dark'}`}
                     onClick = { () => { setDir('across')}}>Across</button>
                    <button
                     className={`btn ${dir === 'down' ? 'btn-success': 'btn-dark'}`}
                     onClick = { () => { setDir('down')}}>Down</button>
                </div>
                <P5Component
                    key={[table, dir]}
                    grid={table}
                    dim={dimen}
                    answerGrid={answerGrid}
                    setAnswer={setAnswer}
                />
            </>}
            </div>
            </div>
            <button 
            disabled={!cansubmit}
            onClick={onSubmit}
            className='btn btn-dark'>Submit</button>
        </div>
        {
            show && <ShowAnswer answer={answertype} next={next} />
        }
    </>
    )
}

export default CrossWord