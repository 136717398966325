import React,{useState,useEffect, useRef} from 'react'
import WhiteBar from '../../Component/coreComponents/WhiteBar'
import { ExcelRenderer } from 'react-excel-renderer';
import Dropzone from 'react-dropzone';


const AddWord = () => {
  const [words, setWords] = useState([]);
  const [currentWord, setCurrentWord] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const [currentLevel, setCurrentLevel] = useState('');
  const [currentMeaning, setCurrentMeaning] = useState('');
  const [msg, setMsg] = useState();
  const fileInputRef = useRef(null);

  const handleWordChange = (event) => {
    setCurrentWord(event.target.value);
  }

  const handleCategoryChange = (event) => {
    setCurrentCategory(event.target.value);
  }

  const handleLevelChange = (event) => {
    setCurrentLevel(event.target.value);
  }

  const handleMeaningChange = (event) => {
    setCurrentMeaning(event.target.value);
  
}
  const handleRemove = (index) => {
    // event.preventDefault();
    if(words.length === 1){
        setWords([])
    } else{
        const newWords = words.slice(); // create a new copy of the array
        newWords.splice(index, 1); // modify the new array
        setWords(newWords); // set the new array as the state
    }
    
  }

  const handleAdd = (event) => {
    event.preventDefault();
    const newWord = { word: currentWord, meaning: currentCategory, level: currentLevel, category: currentMeaning };
    setWords([...words, newWord]);
    
    // reset current word fields
    setCurrentWord('');
    setCurrentCategory('');
    setCurrentLevel('');
    setCurrentMeaning('');
  }

  const handleSubmit = async(event) => {
    event.preventDefault();
    const res = await fetch(
        process.env.REACT_APP_API_ADDRESS + "/word/api/add-words",
    
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "x-auth-token": `${localStorage.getItem('token')}`
          },
          body: JSON.stringify(words),
        }
      );
      const resJson = await res.json();
      
    }

    const onDrop = (acceptedFiles) => {
        let fileObj = acceptedFiles[0];
  
      // check for file extension
      if (!fileObj.name.endsWith('.xlsx') && !fileObj.name.endsWith('.xls')) {
        // setMsg('Please choose a valid Excel file');
        return;
      }
  
      // parse Excel file using ExcelRenderer
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          
        } else {
        //   setInputExcel(resp.rows); Jbschool@2012
        
        let rowLength = resp.rows.length;
        const wordList = [];
          while(rowLength--){
            const newWord = {
                  word: resp.rows[rowLength][0], 
                  meaning: resp.rows[rowLength][1], 
                  level: resp.rows[rowLength][2], 
                  category: resp.rows[rowLength][3] 
                }
                
                wordList.push(newWord);
              }
            setWords([...words,...wordList]);
          
          
        }
      });
    }
    const clearUpload = () => {
      setWords([]);
      fileInputRef.current.value = ""; // set the value of the file input to an empty string
    };
    
  return (
    <div>
      <WhiteBar label="Add words"/>
       
      <div className='mx-3'>
      <h1>Add words to Database</h1>

      <div className='my-3 d-flex justify-content-center'>
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <div className='input-group'>
              <input
               className='form-control'
               {...getInputProps()} 
               ref= {fileInputRef} 
               style={{width: 400, backgroundColor:"white"}}
               />
              </div>
            </div>
          )}
        </Dropzone>
              <div className="input-group-append">
                <button className="input-group-text" id="basic-addon2" onClick={clearUpload}>X</button>
              </div>
              <p>Drag and drop Excel file here, or click to select file</p>
        </div>
        <table className="table">
        <thead className="thead-dark">
          <tr>
            <th style={{width: 20}}  scope="col">#</th>
            <th style={{width: 200}} scope="col">Word</th>
            <th style={{width: 400}} scope="col">Meaning</th>
            <th style={{width: 400}} scope="col">Level</th>
            <th style={{width: 400}} scope="col">Category</th>
          </tr>
        </thead>
        <tbody>
      {words.length >0 && words.map((item, index) => (
                <tr>

            <th style={{width: 20}} scope="row">{index}</th>
            <td style={{width: 200}}><input className="form-control" type='text' value={words[index].word} onChange={(e)=>words[index].word = e.target.value}/></td>
            <td style={{width: 400}}><input className="form-control" type='text' value={words[index].level} onChange={(e)=>words[index].level = e.target.value}/></td>
            <td style={{width: 400}}><input className="form-control" type='text' value={words[index].category} onChange={(e)=>words[index].category = e.target.value}/></td>
            <td style={{width: 400}}><input className="form-control" type='text' value={words[index].meaning} onChange={(e)=>words[index].meaning = e.target.value}/></td>
            <td>

            <button className='btn btn-dark' onClick={()=>handleRemove(index)}>-</button>
            </td>
          </tr>
          ))}
          <tr>
            <td style={{width: 20}} >{words.length}</td>
            <td style={{width: 200}}><input className="form-control" type='text' value={currentWord} onChange={handleWordChange}/></td>
            <td style={{width: 400}}><input className="form-control" type='text' value={currentLevel} onChange={handleLevelChange}/></td>
            <td style={{width: 400}}><input className="form-control" type='text' value={currentCategory} onChange={handleCategoryChange}/></td>
            <td style={{width: 400}}><input className="form-control" type='text' value={currentMeaning} onChange={handleMeaningChange}/></td>
            <td>

            <button onClick={handleAdd} className='btn btn-dark' disabled={!(currentCategory&&currentWord&&currentMeaning&&currentLevel)}>+</button>
            </td>
          </tr>
          </tbody>
          </table>
        <div className='text-center'>

                <button type="submit" className='btn btn-dark' onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
    // const response = await fetch(
    //           process.env.REACT_APP_API_ADDRESS + "/word/api/addWord",
          
    //           {
    //             method: "POST",
    //             headers: {
    //               "Content-type": "application/json",
    //               "x-auth-token": `${localStorage.getItem('token')}`
    //             },
    //             body: JSON.stringify(body),
    //           }
    //         );
    //         const res = await response.json()
    //         if(res.msg){
    //           setMsg(res.msg)
    //         }
}

export default AddWord