import React from 'react'

const CorrectBar = (props) => {
    
  return (
    <nav className="d-flex navbar mt-4 mx-3 correct">
      <div className=" nav-item fs-3 mx-1 " style={{ color: "white" }}>
        <i className="fa-solid fa-check mx-1"></i> Correct!
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <button className="btn btn-light mx-3" onClick={props.onClick}>
          Next
        </button>
      </div>
    </nav>
  )
}

export default CorrectBar