import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Style.css";
import CustomButton from "../coreComponents/CustomButton";
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { useNavigate } from 'react-router-dom';

function Square({location}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);
  
  let num = Math.floor(Math.random() * 30) + 1;
  const [width, setWidth] = useState(num);
  const [height, setHeight] = useState(num);
  const [area, setArea] = useState("");
  const [perimeter, setPerimeter] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);

  const checkAnswers = async (e) => {
    e.preventDefault();
    if (area === width * height && perimeter === 4 * width) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }


    if(location.state.homework){
      dispatch(updateHomeWork({
       module: location.state.topic, id: location.state.id, correct: isCorrect}))    
      }
      else {
          await dispatch(submitResult({
          result: isCorrect ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
      }
      setAttempted(attempted + 1);
  };

const handleNext =()=>{
    
if(location.state.homework && attempted >= location.state.ques) {
  alert('you have completed your homework');
  navigate('/homepage/homework');
}
  let num = Math.floor(Math.random() * 30) + 1;
  setWidth(num)
  setHeight(num)
  setArea("")
  setPerimeter("")
  setIsCorrect(null)

}

  return (
    <>
      <Container>
        <Row>
          <Col xs={12} md={5}>
            <div style={{ display: "flex" }}>
              <div>
                <h2 style={{ width: 100 }}>
                  <span>{width}</span>
                </h2>
                <div
                  style={{
                    backgroundColor: "white",
                    width: 100,
                    height: 100,
                  }}
                ></div>
              </div>
              <h3 style={{ height: 100, marginRight: "20rem",marginTop:"40px",width:100 }}>
                <span>{height}</span>
              </h3>
            </div>
          </Col>
          <Col xs={12} md={7} className="mt-5 d-flex">
            <Form onSubmit={checkAnswers}>
              <Form.Group className="d-flex align-items-center mb-3">
                <Form.Label className="mx-2">Area</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter area"
                  value={area}
                  onChange={(e) => setArea(parseInt(e.target.value))}
                  className="w-50"
                />
              </Form.Group>
              <Form.Group className="d-flex align-items-center">
                <Form.Label className="mx-2">Perimeter</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter perimeter"
                  value={perimeter}
                  onChange={(e) => setPerimeter(parseInt(e.target.value))}
                  className="w-50"
                />
              </Form.Group>
              <div className="text-center mt-5">
             <CustomButton label="Submit" onClick={checkAnswers}></CustomButton>
              </div>
            </Form>
          </Col>
        </Row>

        <Row>
          
        </Row>
      </Container>
      {isCorrect === true ? (
       <CorrectBar onClick={handleNext}/>
      ) : (
       ""
      )}
      {isCorrect === false?(
      <>
      <IncorrectBar onClick={handleNext}/>
      <Col className="text-center mt-2">
            <h6>Correct Answers</h6>
            <p>Area: {width * height}</p>
            <p>Perimeter: {4 * width}</p>
          </Col>
      </>
      ):("")}
    </>
  );
}

export default Square;
