import { applyMiddleware, combineReducers, configureStore, createAsyncThunk, createStore } from "@reduxjs/toolkit";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import authSlice from "./redux/authSlice";
import { persistReducer } from "redux-persist";
import { composeWithDevTools } from 'redux-devtools-extension'
import scoreSlice from "./redux/scoreSlice";
import wordSlice from "./English/redux/wordSlice";
import homeworkSlice from "./redux/homeWorkSlice";
const reducers = {
    user:authSlice,
    result: scoreSlice,
    word:wordSlice,
    homework: homeworkSlice
    }
const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2
}
const rootReducer = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig,rootReducer)
const configStore= () => (configureStore(
    {reducer:persistedReducer},
    // applyMiddleware()
    ))

export default configStore;