import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import IncorrectBar, { Correct } from "./IncorrectBar";
import "./Skip.css";
import { useLocation, useNavigate } from "react-router";
import Navbar from "../Navbar/Navbar";
import { Col } from "react-bootstrap";
import UserButton from "../coreComponents/UserButton/UserButton";
import WhiteBar from "../coreComponents/WhiteBar";
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';


const levels = [
  { level: 0, diff: 2, range: [0, 10] },
  { level: 1, diff: [2, 10], range: [0, 50] },
  { level: 2, diff: [2, 3, 5, 10], range: [0, 100] },
  { level: 3, diff: [2, 3, 4, 5, 6, 7, 8, 9, 10], range: [0, 100] },
  { level: 4, diff: [...Array.from({ length: 9 }, (x, i) => -i - 2), ...Array.from({ length: 14 }, (x, i) => i + 2)], range: [0, 100] },
  { level: 5, diff: [...Array.from({ length: 9 }, (x, i) => -i - 2), ...Array.from({ length: 19 }, (x, i) => i + 2)], range: [-200, 1000] },
];

function SkipCounting() {
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [selectedLevel, setSelectedLevel] = useState(
    levels[parseInt(location.state.level)]
  );

  const [attempted, setAttempted] = useState(location.state.attempted);

  const [answer, setAnswer] = useState(Array(13).fill(""));
  const [expectedAnswer, setExpectedAnswer] = useState([]);
  const [firstTerm, setFirstTerm] = useState(1);
  const [start, setStart] = useState(2);
  const [message, setMessage] = useState("");
  const [d, setD] = useState(2);

  const Answer = Array.from({ length: 13 }, (_, i) => firstTerm + i * d);

  const expectedArray = Answer.slice(0, 3);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    let diff = selectedLevel.diff;
    if (Array.isArray(diff)) {
      diff = diff[Math.floor(Math.random() * diff.length)];
    }
    setD(diff);
    const { range } = selectedLevel;
    const randomStart = Math.floor(Math.random() * (range[1] - range[0] + 1)) + range[0];
    setStart(randomStart);
    setFirstTerm(randomStart);
    setAnswer([randomStart, randomStart + diff, randomStart + 2 * diff, ...Array(10 - 3).fill("")]);
  }, [selectedLevel]);

  const handleAnswer = (e, index) => {
    const updatedAnswer = [...answer];
    updatedAnswer[index] = e.target.value;
    setAnswer(updatedAnswer);
  };

  const handleNext = () => {
    let diff = selectedLevel.diff;
    if (Array.isArray(diff)) {
      diff = diff[Math.floor(Math.random() * diff.length)];
    }
    setD(diff);
    const { range } = selectedLevel;
    setStart(Math.floor(Math.random() * (range[1] - range[0] + 1)) + range[0]);
    setFirstTerm(start);
    setAnswer([start, start + d, start + 2 * d, ...Array(13 - 3).fill("")]);
    setMessage("");
    // setAnswer(Array(13).fill(""))
    setSubmitted(false);
  };

  const handleCheck = async () => {

    const expectedAnswer = Array.from(
      { length: 13 },
      (_, i) => firstTerm + i * d
    );
    if (answer.toString() === expectedAnswer.toString()) {
      setMessage("Correct");
    } else {
      setMessage("Incorrect");
    }
    setSubmitted(true);

    if (location.state.homework) {
      dispatch(updateHomeWork({
        module: location.state.topic, id: location.state.id, correct: message === 'Correct'
      }))
    }
    else {
      await dispatch(submitResult({
        result: message === 'Correct' ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
      }))
    }
    setAttempted(attempted + 1);

  };

  const handleReset = () => {
    if (location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
    }
    setAnswer(Array(13).fill(""))
  }
  return (
    <div>
      <WhiteBar label="Skip Counting" level={location.state.level} />
      <div className="container mt-3">
        <div
          style={{
            marginLeft: "11%",
          }}
        >
          <div className="form-group">
            {expectedArray.map((term, index) => (
              <input
                key={index}
                type="number"
                value={term}
                disabled={submitted}
                readOnly
                style={{
                  width: "70px",
                  marginRight: "10px",

                  height: "50px",
                }}
                className="text-center border border-2"
              />
            ))}
          </div>
          <br />
          <div
            className="form-group"
            style={{
              display: "flex",
              marginLeft: "16.5%",
              marginBottom: "22px",
            }}
          >
            {Array.from({ length: 5 }, (_, i) => i + 3).map((_, index) => (
              <React.Fragment key={index}>
                <input
                  type="number"
                  value={answer[index + 3]}
                  onChange={(e) => handleAnswer(e, index + 3)}
                  className="text-center border border-2"
                  disabled={submitted}
                  style={{
                    width: "70px",
                    height: "50px",
                    marginRight: "10px",
                  }}
                />
              </React.Fragment>
            ))}
          </div>

          <div
            className="form-group text-center"
            style={{ display: "flex", marginLeft: "49.5%" }}
          >
            {Array.from({ length: 5 }, (_, i) => i + 8).map((_, index) => (
              <React.Fragment key={index}>
                <input
                  type="number"
                  disabled={submitted}
                  value={answer[index + 8]}
                  onChange={(e) => handleAnswer(e, index + 8)}
                  className="text-center border border-2"
                  style={{
                    width: "70px",
                    height: "50px",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                />
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-center gap-4 mb-2">
          <UserButton label="Reset" disabled={submitted} onClick={handleReset} />
          <UserButton
            label="Submit"
            onClick={handleCheck}
            disabled={answer.includes("") || submitted}
          />

        </div>


        {/* <IncorrectBar Answer={expectedAnswer} /> */}
      </div>
      <div>
        {message === "Incorrect" && (
          <IncorrectBar
            Answer={Answer}
            expectedAnswer={expectedAnswer}
            message={message}
            handleNext={handleNext}
          />
        )}
        {message === "Correct" && <Correct handleNext={handleNext} />}
      </div>
    </div>
  );
}

export default SkipCounting;  