import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import selectedTopics from '../../Component/coreComponents/gradeWiseTopics/GradeTopics';
import WhiteBar from '../../Component/coreComponents/WhiteBar';
import './styles.css'; // Import custom CSS styles

const Practice = () => {
  const user = useSelector(state => state.user);
  const [selectedMathGrade, setSelectedMathGrade] = useState(selectedTopics({className: user.className}).math);
  const [selectedEngGrade, setSelectedEngGrade] = useState(selectedTopics({className: user.className}).eng);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const navigate = useNavigate();
  
  const handleSubmit = ()=>{
    
    navigate(`/homepage/practice/${selectedTopic.replace(/\s+/g, "")}`, {
      state: {
        level: selectedLevel,
        topic: selectedTopic
      }
    });
   }
  useEffect(()=>{
    setSelectedMathGrade(selectedTopics(user).math);
    setSelectedEngGrade(selectedTopics(user).eng);
  },[])
  
  const selectOption = (
    <>
      <option value="0">Level 0</option>
      <option value="1">Level 1</option>
      <option value="2">Level 2</option>
      <option value="3">Level 3</option>
      <option value="4">Level 4</option>
      <option value="5">Level 5</option>
    </>
  );
  return (
    <>
    <WhiteBar label="Practice"/>
    <Container>
      <form onSubmit={handleSubmit}>

      <Row>
        <Col>
            <Container className="custom-container mt-5">
              <div className="custom-title">Maths</div>
              <div className="custom-content">
                {selectedMathGrade.map((title, index) => {
                  return (
                    <Row className="my-1 mx-1" key={index}>
                      <Col>
                      <div
                        className="btn d-flex btn-light text-left"
                        style={{ width: "220px", backgroundColor: selectedTopic === title ? 'green' : '',color: selectedTopic === title ? 'white' : ''}}
                        type="button"
                        onClick={()=>{if(selectedTopic === title){setSelectedTopic(null)}else{setSelectedTopic(title);}setSelectedLevel(null)}}
                        
                      >{index+1}. {title}
                      </div>
                      </Col>
                      {selectedTopic === title && 
                      <Col>
                        <select
                         className="btn"
                         style={{ width: "120px", backgroundColor: selectedLevel ? 'green' : '',color: selectedLevel ? 'white' : ''}}
                         value={selectedLevel}
                         onChange={(event)=> {
                           setSelectedLevel(event.target.value);
                         }}
                         >
                        <option value="">Level</option>
                          {selectOption}
                        </select>
                      </Col>}
                    </Row>
                  );
                })}
              </div>
            </Container>
        </Col>
        <Col>
            <Container className="custom-container mt-5">
              <div className="custom-title">English</div>
              <div className="custom-content">
              {selectedEngGrade.map((title, index) => {
                  return (
                    <Row className="my-1 mx-1" key={index}>
                      <Col>
                      <div
                        className="btn d-flex btn-light text-left"
                        style={{ width: "220px", backgroundColor: selectedTopic === title ? 'green' : '',color: selectedTopic === title ? 'white' : ''}}
                        type="button"
                        onClick={()=>{if(selectedTopic === title){setSelectedTopic(null)}else{setSelectedTopic(title);}setSelectedLevel(null)}}
                        
                      >{index+1}. {title}
                      </div>
                      </Col>
                      {selectedTopic === title && 
                      <Col>
                        <select
                         className="btn"
                         style={{ width: "120px", backgroundColor: selectedLevel ? 'green' : '',color: selectedLevel ? 'white' : ''}}
                         value={selectedLevel}
                         onChange={(event)=> {
                           setSelectedLevel(event.target.value);
                         }}
                         >
                        <option value="">Level</option>
                          {selectOption}
                        </select>
                      </Col>}
                    </Row>
                  );
                })}
              </div>
            </Container>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center'>
        <button
         className={(selectedTopic && selectedLevel) ? "btn btn-success" : "btn btn-dark"}
         disabled={!(selectedTopic && selectedLevel)}
         style={{ width: "150px",}}
         onClick={handleSubmit}
         onKeyPress={(event)=>{if(event.key === 'Enter'){handleSubmit()}}}
         >
          Start
        </button>
      </Row>
      </form>
    </Container>
    </>
    
  );
}

export default Practice;