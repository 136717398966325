
const randomNum = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

/**

   * loop through words sorted by length
   * choose random direction
   * find random col and row for it to start
   * verify if the coords are valid or not
   * if not find the offset by which the corrds are not valid
   * choose another row and col with random coords
   * verify that position
   * insert the word... 
 */

export const matrixGenerator = (matrix, words, size, directions) => {

    let coords = new Map(), k = 0;
    for (let word of words) {
        let row, col,direction;
        direction = directions[randomNum(0, directions.length - 1)]
        row = randomNum(0, size - 1);
        col = randomNum(0, size - 1);
        do{
         
              direction = directions[randomNum(0, directions.length - 1)]
              row = randomNum(0, size -1);
              col = randomNum(0, size - 1);
              
              let offsetR = row + (word.length - 1) > (size - 1) ?  (row + (word.length - 1)) - size : null;
              let offsetC = col + (word.length - 1) > (size - 1) ?  (col + (word.length - 1)) - size : null;
              if(offsetC || offsetR){
                row = randomNum(0, offsetR || size -1);
                col = randomNum(0, offsetC || size - 1);
              }
         }while(!validPos(word, row, col, direction, matrix, size))
        
        // //fill the letters in matrix
        let tempc = []
        for (let i = 0; i < word.length; i++) {
            const letter = word.charAt(i);
            const newRow = row + i * direction[1];
            const newCol = col + i * direction[0];
            //
            matrix[newRow][newCol] = letter.toUpperCase();
            tempc.push({ row: newRow, col: newCol})
            
          }
          coords.set(word, tempc);
          k++;
    }

    //all the letters are placed fill the remaining matrix with random letters
    for (let i = 0; i < size; i++) {
        for (let j = 0; j < size; j++) {
            /** capital letters code go from 65 to 90 */
            if(matrix[i][j] === '.')
             matrix[i][j] =  String.fromCharCode(randomNum(65, 90));
        }
    }
    return { matrix, coords };
} 

const validPos = (word, row, col, direction, matrix, size) => {
    if (col + (direction[0] * (word.length - 1)) >= size || row + (direction[1]  * (word.length - 1)) >= size) {
        return false;
      }
    // Check for overlapping words
  for (let i = 0; i < word.length; i++) {
    const letter = word.charAt(i);
    const newRow = row + i * direction[1];
    const newCol = col + i * direction[0];
    if (matrix[newRow][newCol] !== letter && matrix[newRow][newCol] !== '.') {
      return false
     
    }
  }

  return true;
}