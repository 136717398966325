import React from 'react'
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";

function ShowAnswer({ answer, numbers, next }) {

  let steps = [], printAnswer, pad = 0;

  function showDivision(x, y) {
    //op 512 64

    var a = x, b = y, num = a.toString();
    var temp = num[0], i = 0, tempq, a1, a2, a3, q = '';

    while(i < num.length){
      /**
       * tempq stores result when dividing by 1st digit
       * q stores quotient
       * a1 stores the next step when we multiply tempq with b
       * a2 subtracts
       * a3 stores next dividend
       * temp becomes a3
       * push a1 and temp
       */

      tempq = parseInt(parseInt(temp) / b );
      q = `${q}${tempq.toString()}`
      a1 = tempq * b;
      a2 = parseInt(temp) - a1;
      if(i < num.length - 1)
      a3 = a2* 10 + parseInt(num[i + 1]);
      else {
        a3 = a2 * 10;
      }

      temp = a3;
      
      steps.push(a1, temp);
      i++;
    }

    
    printAnswer = q;

  }

  showDivision(numbers.a, numbers.b);
  
  function padding(index) {
    // if(index % 2 == 1 && (index !== steps.length - 1))
    // pad = index; 906 3
    // return `${pad - 1}ch`;
    
    if(index % 2 === 1 ){
      if(index - String(steps[index]).length > 0){
        pad = index - String(steps[index]).length;
        
      }
      else if( String(steps[index]).length < (String(numbers.b).length))
      pad = index;

    }
    if(pad >= (String(numbers.b).length))
    pad = String(numbers.b).length - 1;

    return `${pad}ch`;
  }
  return (

    <>
      {
        (answer ?
          <CorrectBar onClick={next} /> :
          <IncorrectBar onClick={next}>
            <div className='long-division d-flex gap-3 mt-0 justify-content-center' style={{ fontSize: '25px' }}>
              <span className="d-flex p-0 text-center flex-0.5 gap-2 py-1px-3"  >
                <div className=' d-flex flex-column flex-wrap'>
                  <span style={{ fontSize: '20px' }} className=' m-0 align-self-end'>{printAnswer}</span>
                  <div className='d-flex gap-2 align-self-end p-0 m-0'>
                    <span>{numbers.b}</span>
                    <span
                      className='d-flex gap-0 flex-column'>
                      <span className='align-self-start border-dark border-start border-top border-3 px-3 '>{numbers.a}
                      </span >
                      {/** to display long division in columns */}
                      <span
                      className='d-flex flex-column flex-wrap gap-0 px-3'>
                        {
                          steps.map ( (step, index) => (
                            <>
                            <span 
                             style = {{
                              paddingLeft: `${ index < steps.length - 1 ? padding(index) : ''}`
                            }}
                            className={`${index % 2 === 0 ? 'border-bottom border-dark' : ''} 
                            ${index < steps.length - 1 ? 'align-self-start' : 'align-self-end'}`
                            }>
                              {
                              step
                              }
                              </span>
                            </>
                          ))
                        }

                      </span>
                    </span>
                  </div>

                </div>
                {/* { missing === 0 && <><span className='p-0 text-center flex-0.5 py-1 bg-light px-3'>{numbers.a}</span>{`÷ ${numbers.b} = ${numbers.c}`}</> }
              { missing === 1 && <>{`${numbers.a} ÷`} <span className='p-0 text-center flex-0.5 py-1 bg-light px-3'>{numbers.b}</span> {` = ${numbers.c}`}</> }
              { missing === 2 && <>{`${numbers.a} ÷ ${numbers.b} = `} <span className='p-0 text-center flex-0.5 py-1 bg-light px-3'>{numbers.c}</span></> } */}
              </span>

            </div>
          </IncorrectBar>)
      }
    </>
  )

}

export default ShowAnswer


  // let steps = [], cols, printAnswer,  r = [];
  // function showDivision(x, y) {

  //   var a = x, b = y, q = '';
  //   var num = a.toString();
  //   cols = num.length;
  //   var i = 0;
  //   var temp = parseInt(num[i]);
    

  //   while (i < num.length) {

  //     /** tempq stores current quotient like 108/9
  //      * tempq will be 1/9 =0, b is divisor
  //      * q is current quotient to store answer
  //      * tempr stores remainder from temp and b, temp stores first value by default and will keep increasng
  //      * temp =1, temp = 10 temp = 108 
  //      * tempq2 stores remainder and the next number in the dividend
  //      * like 10
  //      * if r < divisor
  //      */
  //     var tempq = parseInt(temp / b);
  //     q = `${q}${tempq.toString()}`

  //     var tempr = temp % b;
  //     var tempq2 = `${tempr.toString()}${num[i + 1]}`; // i < num.length - 1 ?  : `${tempr.toString()}`;

  //     //if b * tempq == 0 the value will not get pushed
  //     
      
  //     if ((b * tempq)) {
  //       steps.push(b * tempq, parseInt(tempq2))
  //       r.push(tempr);
  //     } 
      
  //     temp = parseInt(tempq2);
  //     

  //     // 
  //     i++;
  //   }

  //   printAnswer = parseInt(q);;

  // }

  // showDivision(numbers.a, numbers.b);