import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import WhiteBar from '../coreComponents/WhiteBar';
import coin1 from "./Coin-1.png";
import coin2 from "./Coin-2.png";
import coin5 from "./Coin-5.png";
import Note10 from "./Note-10.png";
import Note20 from "./Note-20.png";
import Note50 from "./Note-50.png";
import Note100 from "./Note-100.png";
// import { getState, submitResult } from "../../redux/authSlice";
import { submitResult } from "../../redux/scoreSlice";
import "./ChangingMoney.css";
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useDispatch } from "react-redux";
const Level2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

const [attempted, setAttempted] = useState(location.state.attempted);


    const random4 = () => {
        const array1 = [1,2,5,10,20];
        const result1 = [];
        const array2 = [coin1,coin2, coin5,Note10,Note20];
        const result2 = [];
        
        for (let i = 0; i < 4; i++) {
            const randomIndex = Math.floor(Math.random() * array1.length);
            result1.push(parseInt(array1[randomIndex]));
            result2.push((array2[randomIndex]));
            array1.splice(randomIndex, 1);
            array2.splice(randomIndex, 1);
        }
        
        return {denomination:result1,coin:result2};
    }
    // const denominations =[1,2,5,10,20];
    // const coins = [coin1,coin2, coin5,Note10,Note20];
    const denCoin = random4();
    const [denomination,setDenomination] = useState(denCoin.denomination.sort((a,b)=>a-b));
    const [coin,setCoin] = useState(denCoin.coin.sort());
    
    const location = useLocation();
    const [numbers,setNumbers] = useState(Math.floor(Math.random()*100+1));
    const [answer1,setAnswer1] = useState('');
    const [answer2,setAnswer2] = useState('');
    const [answer3,setAnswer3] = useState('');
    const [answer4,setAnswer4] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      // await dispatch(submitResult({result:(parseInt(answer) === numbers.a + numbers.b ? "CORRECT" : "INCORRECT"),email:localStorage.getItem('email')}))
      setSubmitted(true);
      if(location.state.homework){
        dispatch(updateHomeWork({
         module: location.state.topic, id: location.state.id, correct: parseInt(numbers) === answer1*denomination[0]+answer2*denomination[1]+answer3*denomination[2]+answer4*denomination[3]}))    
    }
    else {
        await dispatch(submitResult({
         result:parseInt(numbers) === answer1*denomination[0]+answer2*denomination[1]+answer3*denomination[2]+answer4*denomination[3]? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
    }
    setAttempted(attempted + 1);

    };
    const handleNxt = () => {
        
        // setAnswer("");
        const denCoin = random4();
        setDenomination(denCoin.denomination.sort((a,b)=>a-b))
        setCoin(denCoin.coin.sort())
        setSubmitted(false);
        setNumbers(Math.floor(Math.random()*100+1));
    };
    // 
    return (
        <div>
        {/* {
        <p className='text-center' style={{ fontSize: "22px", fontWeight: "600" }}>How will you pay Rs {numbers} ?</p>
        <div
         className="container"
         style={{
           display: "flex",
           marginTop: "3%",
           alignItems: "center",
           justifyContent: "space-evenly"
         }}
       >
         <table className="money-table">
           <tbody>
             <tr>
               {coin.map((coin,index)=>{
                 return (<>
                   <td><img src={coin} alt=""/></td>
                 </>)
               })}
             </tr>
             <tr>
               
                <td>
                 <input
                  type='number' 
                  value={answer1}
                  onChange={(e)=>setAnswer1(e.target.value)}
                  />
                </td>
                <td>
                 <input
                  type='number' 
                  value={answer2}
                  onChange={(e)=>setAnswer2(e.target.value)}
                  />
                </td>
                <td>
                 <input
                  type='number' 
                  value={answer3}
                  onChange={(e)=>setAnswer3(e.target.value)}
                  />
                </td>
                <td>
                 <input
                  type='number' 
                  value={answer4}
                  onChange={(e)=>setAnswer4(e.target.value)}
                  />
                </td>
               </tr>
           </tbody>
         </table>
       </div>
       {/* <div className="answer">
        <p style={{ fontSize: "22px", fontWeight: "600" }}>
          How much money do you have?{" "}
        </p>
        <input
          style={{
            backgroundColor: "white",
            fontSize: "30px",
            width: "75px",
            border: "none"
          }}
          disabled={submitted}
          type="number"
          className="text-center align-items-center"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
        />
      </div> */}
      <div className="d-flex mt-5 justify-content-center">
        <button
          disabled={submitted}
          type="submit"
          className="btn btn-dark mb-5"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      {submitted === true
        ? parseInt(numbers) === answer1*denomination[0]+answer2*denomination[1]+answer3*denomination[2]+answer4*denomination[3]
          ? <CorrectBar onClick={handleNxt}/>
          : <IncorrectBar onClick={handleNxt} label='hi'>hi</IncorrectBar>
        : ""}
    </div>
  )
}

export default Level2