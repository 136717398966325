import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import WhiteBar from '../coreComponents/WhiteBar';
import coin1 from "./Coin-1.png";
import coin2 from "./Coin-2.png";
import coin5 from "./Coin-5.png";
import Note10 from "./Note-10.png";
import Note20 from "./Note-20.png";
import Note50 from "./Note-50.png";
import Note100 from "./Note-100.png";
import { updateHomeWork } from '../../redux/homeWorkSlice';

// import { getState, submitResult } from "../../redux/authSlice";
import { submitResult } from "../../redux/scoreSlice";
import "./ChangingMoney.css";
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";
import { useDispatch } from "react-redux";

const Level3 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [attempted, setAttempted] = useState(location.state.attempted);
  const random4 = () => {
    /** 
     * 2 loops 
     * first loop 3 
     * arr  1 2 5
     * index 
     * admin 1 and admin2 grade 1
     */
    // arr1 = 1 2 5
    // arr 2= 10 20 50 100
    const arrval1 = [1, 2, 5];
    const arrval2 = [10, 20, 50, 100]
    const result1 = [];
    const arrcoin1 = [{ 0: coin1 }, { 1: coin2 }, { 5: coin5 },];
    const arrcoin2 = [{ 10: Note10 }, { 20: Note20 }, { 50: Note50 }, { 100: Note100 }];
    const result2 = [];


    for (let i = 0; i < 2; i++) {
      const randomIndex = Math.floor(Math.random() * arrval1.length);
      result1.push(parseInt(arrval1[randomIndex]));
      result2.push((arrcoin1[randomIndex]));
      arrval1.splice(randomIndex, 1);
      arrcoin1.splice(randomIndex, 1);
    }
    for (let j = 0; j < 2; j++) {
      const randomIndex = Math.floor(Math.random() * arrval2.length);
      result1.push(parseInt(arrval2[randomIndex]));
      result2.push((arrcoin2[randomIndex]));
      arrval2.splice(randomIndex, 1);
      arrcoin2.splice(randomIndex, 1);

    }
    
    return { denomination: result1, coin: result2 };
  }
  // const denominations =[1,2,5,10,20];
  // const coins = [coin1,coin2, coin5,Note10,Note20];
  const denCoin = random4();
  const [denomination, setDenomination] = useState(denCoin.denomination.sort((a, b) => a - b));
  const [coin, setCoin] = useState(denCoin.coin.sort((a, b) => Object.keys(a) - Object.keys(b)));
  //
  const location = useLocation();

  const [numbers, setNumbers] = useState(Math.floor(Math.random() * 1000 + 1));
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [answer3, setAnswer3] = useState('');
  const [answer4, setAnswer4] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // await dispatch(submitResult({result:(parseInt(answer) === numbers.a + numbers.b ? "CORRECT" : "INCORRECT"),email:localStorage.getItem('email')}))
    setSubmitted(true);

    if (location.state.homework) {
      dispatch(updateHomeWork({
        module: location.state.topic, id: location.state.id, correct: parseInt(numbers) === answer1 * denomination[0] + answer2 * denomination[1] + answer3 * denomination[2] + answer4 * denomination[3]
      }))
    }
    else {
      await dispatch(submitResult({
        result: parseInt(numbers) === answer1 * denomination[0] + answer2 * denomination[1] + answer3 * denomination[2] + answer4 * denomination[3] ? "CORRECT" : "INCORRECT", email: localStorage.getItem('email')
      }))
    }
    setAttempted(attempted + 1);

    
  };
  const handleNxt = () => {

    if (location.state.homework && attempted >= location.state.ques) {
      alert('you have completed your homework');
      navigate('/homepage/homework');
    }
    
    // setAnswer("");
    const denCoin = random4();
    setDenomination(denCoin.denomination.sort((a, b) => a - b))
    setCoin(denCoin.coin.sort((a, b) => Object.keys(a) - Object.keys(b)))
    setSubmitted(false);
    setNumbers(Math.floor(Math.random() * 1000 + 1));
  };
  // 
  return (
    <div>
      {/* {
      <p className='text-center' style={{ fontSize: "22px", fontWeight: "600" }}>How will you pay Rs {numbers} ?</p>
      <div
        className="container"
        style={{
          display: "flex",
          marginTop: "3%",
          alignItems: "center",
          justifyContent: "space-evenly"
        }}
      >
        <table className="money-table">
          <tbody>
            <tr>
              {coin.map((coin, index) => {
                return (<>
                  <td><img src={Object.values(coin)} alt="" /></td>
                </>)
              })}
            </tr>
            <tr>

              <td>
                <input
                  type='number'
                  value={answer1}
                  onChange={(e) => setAnswer1(e.target.value)}
                />
              </td>
              <td>
                <input
                  type='number'
                  value={answer2}
                  onChange={(e) => setAnswer2(e.target.value)}
                />
              </td>
              <td>
                <input
                  type='number'
                  value={answer3}
                  onChange={(e) => setAnswer3(e.target.value)}
                />
              </td>
              <td>
                <input
                  type='number'
                  value={answer4}
                  onChange={(e) => setAnswer4(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <div className="answer">
        <p style={{ fontSize: "22px", fontWeight: "600" }}>
          How much money do you have?{" "}
        </p>
        <input
          style={{
            backgroundColor: "white",
            fontSize: "30px",
            width: "75px",
            border: "none"
          }}
          disabled={submitted}
          type="number"
          className="text-center align-items-center"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
        />
      </div> */}
      <div className="d-flex mt-5 justify-content-center">
        <button
          disabled={submitted}
          type="submit"
          className="btn btn-dark mb-5"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      {submitted === true
        ? parseInt(numbers) === answer1 * denomination[0] + answer2 * denomination[1] + answer3 * denomination[2] + answer4 * denomination[3]
          ? <CorrectBar onClick={handleNxt} />
          : <IncorrectBar onClick={handleNxt} label='hi'>hi</IncorrectBar>
        : ""}
    </div>
  )
}

export default Level3