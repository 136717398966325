import React from 'react'
import CorrectBar from "../coreComponents/CorrectBar";
import IncorrectBar from "../coreComponents/IncorrectBar";;

function ShowAnswer({ answer, next, numbers}) {
  let steps = [], printAnswer, pad = [];

  function showDivision(x, y) {
    //op 512 64

    var a = x, b = y, num = a.toString();
    var temp = num[0], i = 0, tempq, a1, a2, a3, q = '';

    while(i < num.length){
      /**
       * tempq stores result when dividing by 1st digit
       * q stores quotient
       * a1 stores the next step when we multiply tempq with b
       * a2 subtracts
       * a3 stores next dividend
       * temp becomes a3
       * push a1 and temp
       */
      tempq = parseInt(parseInt(temp) / b );
      q = `${q}${tempq.toString()}`
      a1 = tempq * b;
      const nDigit = a1.toString().length
      a2 = parseInt(temp) - a1;
      if(i < num.length - 1)
      a3 = a2* 10 + parseInt(num[i + 1]);
      else {
        a3 = a2 * 10;
      }

      temp = a3;
      steps.push(a1, temp.toString().padStart(nDigit, '0'));
      i++;
    }
    while(parseInt(steps[0])===0){
      steps.splice(0,2);
    }

    printAnswer = q;

  }

  showDivision(numbers.a, numbers.b);

  function padding(index) {
    // if(index % 2 == 1 && (index !== steps.length - 1))
    // pad = index; 906 3
    // return `${pad - 1}ch`;
    if(index % 2 === 1){
      if(index - String(steps[index]).length > 0){
        pad = index - String(steps[index]).length;
      }
      else if( String(steps[index]).length < 2)
      pad = index;
      if(pad >  String(numbers.a).length)
      pad = 2;
    }
    return `${pad}ch`;
  }
  
  return (
    <>
    {
        (answer ?
        <CorrectBar onClick={next}/> : 
        <IncorrectBar onClick={next}>
         <div className='long-division d-flex gap-3 mt-0 justify-content-center' style={{ fontSize: '25px' }}>
              <span className="d-flex p-0 text-center flex-0.5 gap-2 py-1px-3"  >
                <div className=' d-flex flex-column flex-wrap'>
                  <span style={{ fontSize: '20px' }} className=' m-0 align-self-end'>{printAnswer}</span>
                  <div className='d-flex gap-2 align-self-end p-0 m-0'>
                    <span>{numbers.b}</span>
                    <span
                      className='d-flex gap-0 flex-column'>
                      <span className='align-self-start border-dark border-start border-top border-3 px-3 '>{numbers.a}
                      </span >
                      {/** to display long division in columns */}
                      <span
                      className='d-flex flex-column flex-wrap gap-0 px-3'>
                        {
                          steps.map ( (step, index) => (
                            <>
                            <span 
                            style = {{
                              paddingLeft: `${ index < steps.length - 1 ? padding(index) : ''}`
                            }}
                            className={`${index % 2 === 0 ? 'border-bottom border-dark' : ''} 
                            ${index < steps.length - 1 ? 'align-self-start' : 'align-self-end'}`
                            }>
                              {
                              step
                              }
                              </span>
                            </>
                          ))
                        }

                      </span>
                    </span>
                  </div>

                </div>
              </span>

            </div>
        </IncorrectBar> )
    }
    </>
  )
}

export default ShowAnswer
