import React, { useState, useEffect } from "react";
import CorrectBar from "../coreComponents/CorrectBar";
import Navbar from "../Navbar/Navbar";
import { useLocation } from "react-router-dom";
import IncorrectBarMagicSq from "../coreComponents/IncorrectBarMagicSq";
import WhiteBar from "../coreComponents/WhiteBar";
import { useDispatch } from "react-redux";
import { submitResult } from "../../redux/scoreSlice";
import { updateHomeWork } from '../../redux/homeWorkSlice';
import { useNavigate } from 'react-router-dom';




function getRandomInt(min, max) {
  let diff = max - min;
  return Math.floor(Math.random() * diff) + min;
}
function getRandom(min, max) {
  // const a = getRandomInt(1,max);
  return getRandomInt(1,getRandomInt(1,max))
  // let diff = max - min;
  // return Math.floor(Math.random() * diff) + min;
}
function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) { 
 
      // Generate random number 
      var j = Math.floor(Math.random() * (i + 1));
                 
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }
     
  return array;
}
const levels = [
  { arrayLength: 4, target: ()=>getRandomInt(4,14)},
  { arrayLength: 9, target: ()=>getRandomInt(4,9)},
  { arrayLength: 9, target: ()=>getRandomInt(7,15)},
  { arrayLength: 9, target: ()=>getRandomInt(10,35)},
  { arrayLength: 9, target: ()=>getRandomInt(15,50)},
  { arrayLength: 9, target: ()=>getRandomInt(35,100)},
];
function Three() {
  const location = useLocation();
  
  const generateMagicSquare = (target) => {
    const magicNumber = target;
    if(parseInt(location.state.level) > 0){
      let array1 = [];
      let array2 = [];
      let array3 = [];
    //Gerenate Random number

    do {
      // Generating array[1] 1st Row
      array1[0] = getRandom(0, magicNumber);
      array1[1] = getRandom(0, (magicNumber - array1[0]));
      array1[2] = magicNumber - array1[0] - array1[1];
      //
    
      // 21 element
      array2[0] = getRandom(0, (magicNumber - array1[0]));
    
      // 22 element
      let max22 = 0;
      max22 = array1[1];
      if (array2[0] > array1[1]) {
        max22 = array2[0];
      }
      array2[1] = getRandom(0, (magicNumber - max22));
    
      // 23 element
      array2[2] = magicNumber - array2[0] - array2[1];
      //
    
      // Generating 3rd Row
      array3[0] = magicNumber - array1[0] - array2[0];
      array3[1] = magicNumber - array1[1] - array2[1];
      array3[2] = magicNumber - array1[2] - array2[2];
    
      //
    } while (array3[2] < 0);
    // setMagicSquare([array1, array2, array3]);
    const arrayFinal = shuffleArray([array1, array2, array3])
    return {target:magicNumber,array:arrayFinal}
  }else {
    let number1 = getRandomInt(0,magicNumber);
    return {target: target, array: [number1, magicNumber - number1, magicNumber - number1, number1]}
  }
  }

  const genQuestion = (fn) => {
    const {target,array} = fn;
    setTarget(target);
    let answer = array.flat();
    setAnswer(answer);
    setQuestion(Array(levels[location.state.level].arrayLength).fill(null));
    const queArray = Array(levels[location.state.level].arrayLength).fill(null);
    if(levels[location.state.level].arrayLength > 5){

      const randoms = shuffleArray([0,1,2]);
      randoms.forEach((x,index)=>randoms[index]=x+index*3)
      const leftArray = Array.from(Array(levels[location.state.level].arrayLength).keys()).filter(x => !randoms.includes(x));
      randoms.push(leftArray[Math.floor(Math.random()*6)] )
      queArray[randoms[0]]=(answer[randoms[0]])
      queArray[randoms[1]]=(answer[randoms[1]])
      queArray[randoms[2]]=(answer[randoms[2]])
      queArray[randoms[3]]=(answer[randoms[3]])
    } else {
      const randoms = getRandomInt(0,3);
      queArray[randoms]=(answer[randoms])

    }
    setQuestion(queArray)
  }
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [attempted, setAttempted] = useState(location.state.attempted);

  const [fn,setFn] = useState(generateMagicSquare(levels[location.state.level].target()));
  const [answerSubmitted, setAnswerSubmitted] = useState(Array(levels[location.state.level].arrayLength).fill(null));
  const [answer, setAnswer] = useState();
  const [question, setQuestion] = useState();
  const [target, setTarget] = useState();
  const [correct, setCorrect] = useState();
  const [submitted, setSubmitted] = useState();
  
  const handleSubmit = async ()=>{
    for(let i=0;i<levels[location.state.level].arrayLength;i++){
      if(parseInt(question[i]) !== parseInt(answer[i])){
        setCorrect(false);
        break;
      } else {
        setCorrect(true);
      }
    }
    setSubmitted(true);
    if(location.state.homework){
      dispatch(updateHomeWork({
       module: location.state.topic, id: location.state.id, correct: correct}))    
  }
  else {
      await dispatch(submitResult({
       result: correct ? "CORRECT" : "INCORRECT" , email:localStorage.getItem('email')}))
  }
  setAttempted(attempted + 1);

  }
  const handleNxt = () => {
    if(location.state.homework && attempted >= location.state.ques) {
                alert('you have completed your homework');
                navigate('/homepage/homework');
            }
    setAnswer(null);
    setTarget(null);
    setSubmitted(false);
    setQuestion((null));
    // setAnswerSubmitted((Array(levels[location.state.level].arrayLength).fill(null)));
    setFn(generateMagicSquare(levels[location.state.level].target()))
  }
  useEffect(() => {
    setAnswer(null);
    setTarget(null);
    // setQuestion(Array(levels[location.state.level].arrayLength).fill(null));
    // setAnswerSubmitted((Array(levels[location.state.level].arrayLength).fill(null)));
    genQuestion(fn);
  }, [fn])
  if(!question){
    return null;
  }
  return (
    <div>
    {/* <Navbar/> */}
        <WhiteBar label="Magic Square" level={location.state.level}/>
      <div className="d-flex justify-content-center my-1">
        <div style={{fontSize: "20px", fontWeight: 400}}>Magic Square Number: {target}</div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <div className="border rounded border-dark">
          <table className="table" style={{margin: "2px 2px 3px 3px"}}>
              {question.map((item, i) => 
              {
                return (
                  <React.Fragment key={`${i}`}>
                      <td key={`${i}`}>
                        <input
                          type="number"
                          className={`text-center border p-0 border-dark ${(levels[location.state.level].arrayLength > 4 ? i%3 === 2 : i%2 === 1 ) ? "me-1" : ''}`}
                          style={{ height: 50, width: 50, fontSize: "30px",
                           margin:"1px 0px 0px 0px"
                           }}
                          min="1"
                          max={target - 1}
                          value={answerSubmitted[i] === null ? item : answerSubmitted[i]}
                          onChange={(e) => {const queArray= question; queArray[i]= e.target.value;setQuestion(queArray);}}
                        />
                      </td>
                      {(levels[location.state.level].arrayLength > 4 ? i%3 === 2 : i%2 === 1 )&& <tr></tr>}
                  </React.Fragment>
                )
              }
              )}
          </table>
        </div>
      </div>
      <div className="d-flex mt-4 justify-content-center">
        {/* <button
          type="reset"
          className="btn btn-dark mb-4 me-3"
          onClick={()=>setAnswerSubmitted(question)}
        >
          Reset
        </button> */}
        <button
          disabled={submitted}
          type="submit"
          className="btn btn-dark mb-4"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      {submitted === true
        ? correct
          ? <CorrectBar onClick={handleNxt}/>
          : <IncorrectBarMagicSq answer={answer} onClick={handleNxt} >
            
          </IncorrectBarMagicSq>
        : ""}
    </div>
  );
}

export default Three;

{/* <table className="table table-bordered">
            <tbody>

              {
              array.map((item, i) => 
              ( <tr key={i}>
                {item.map((data,j) => 
                    (
                      <td
                       key={`${i}+${j}`}
                        // className={`form-control text-center`}
                        // style={{ height: 50, width: 50, fontSize: "30px"}}
                       >
                        {/* {data} */}
          //               <input
          //               type="number"
          //               className="form-control text-center"
          //               style={{ height: 40, width: 40, fontSize: "25px"}}
          //               min="1"
          //               // max={targetSum - 1}
          //               value={data}
          //               // onChange={(event) => handleGridChange(i, j, event)}
          //             />
          //             </td>)
                      
          //             )}
          //     </tr>)
          //       )
          //     }
          //   </tbody>
          // </table> */}

/**
 *  const array = answer.length > 5 ? [[],[],[]] : [[],[]];
    
    let index = 0;
    for(let i =0; i<array.length;i++){
        for(let j=0;j<array.length;j++){
            array[i][j] = answer[index];
            index+=1;
        }

    }
    
 */