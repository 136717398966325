import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import './ErrorReport.css';


function ErrorReport() {

    const user = useSelector(state => state.user);
    const [results, setResults] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [show, setShow] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [response, setResponse] = useState(null);
    const handleClose =  () => {
        setShow(false);
        setResponse(null);
    }

    const fetchData = async () => {
        try {
          const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/error/viewErrors`, {
            method: 'get',
            headers: {
              "Content-type": "application/json",
              "x-auth-token": `${localStorage.getItem("token")}`,
            },
          });
          const data = await res.json();
          
          setResults(data);
        } catch (error) {
          console.error(error);
        }
      };

    useEffect(() => {
    
        fetchData();
    
      return () => {
        
      }
    }, []);
    
    const setShowData = (item) => {
        setSelectedItem(item);
        setShow(true);
    }

    const del = async () => {
        setDeleting(true);
        const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/error/deleteError`, {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                "x-auth-token": `${localStorage.getItem("token")}`,
              },
            body: JSON.stringify(selectedItem)
        })
        const data = await res.json();
        
        setResponse(data);
        setDeleting(false);
    }


    if(user.role !== 'SUPERADMIN')
        return (<>
        <h1>You Are not Authorized to view this page..</h1>
        </>)

  return (
    <div className='m-3'>
        <table className='table table-light text-center align-middle'>
      <thead>
        <tr>
          <th>S.no</th>
          <th>Email</th>
          <th>Description</th>
          <th>Created At</th>
          <th>Image</th>
        </tr>
      </thead>
      <tbody>
        {results.length ? results.map((result, index) => (
          <tr key={result.filename}>
            <td>{index + 1}</td>
            <td>{result.email}</td>
            <td>{result.description}</td>
            <td>{new Date(result.createdAt).toLocaleString()}</td>
            <td>
              <img 
              onClick={() => {setShowData(result)}}
              style={{
                maxWidth: '100%',
                height:'auto',
                cursor: 'pointer'
              }} src={result.dataUri} />
            </td>
          </tr>
        )) : <h4>No errors for now...</h4>}
      </tbody>
    </table>
    {
        show && <ImageModal
        {...selectedItem}
        del={del}
        res={response}
        deleting={deleting}
        handleClose ={handleClose}
         />
    }
    </div>
  )
}


function ImageModal(props){

    const { handleClose,description, createdAt, dataUri, deleting, del, res} = props;

    
    return <>
    <Modal show={true} onHide={handleClose} centered dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title">
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Error
        </Modal.Title>
      </Modal.Header>
        <Modal.Body>
        <label htmlFor="text">Created At:</label>
          <input
            className="mb-2 form-control"
            id="text"
            rows="3"
            disabled
            value={new Date(createdAt).toLocaleString()}
          />
            
        <label htmlFor="text">Description:</label>
          <textarea
            className="mb-2 form-control"
            id="text"
            rows="2"
            disabled
            value={description}
          />
          <label>Image: </label>
          <img
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
            src={dataUri}
          />
          {
            res &&( res.status ? 
            <p className='text-left text-success ml-2'>Deleted Successfully..</p> :
            <p className='text-left text-success ml-2'>There was an error when deleting...</p>)
        }
        </Modal.Body>
        
        <Modal.Footer>
        <Button 
        disabled={deleting}
        variant="primary" onClick={del}>
       { deleting ? 'deleting...' : 'Delete'}
        </Button>
      </Modal.Footer>
      </Modal>
      </>
}

export default ErrorReport