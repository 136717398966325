import React, { useEffect, useRef } from "react";
import P5 from 'p5';



//var allSelections = [];


/**
 * for some reason row for horizontal and column for vertical works,, idk why
 */
export default (props) => {

    const { size, words,selections, add, submitted, answer, notFound } = props;
    
    const containerRef = useRef(null);
    const rows = size;
    const cols = size;
    const SCALE = 30;
    let background, canvas;
    var prevCell = null;
    var currCell = null;
    var  currSelection = null;
    let totalW, totalH;
    let selectWord = '';
    
    const Sketch = (p5) => {
        p5.setup = () => {
            
            totalW = totalH = size*SCALE;
            p5.createCanvas(totalW, totalH);
            const { width, height} = p5;
    
            background = p5.createGraphics(width, height);
            background.background(255, 255, 255, 0);
    
            /** draw matrix */
            for(var x = 0; x <= size; x++){
    
                background.stroke(0, 0 ,0 , 255);
                background.strokeWeight(.25);
                background.line(x * SCALE, 0, x * SCALE, height);
                background.line(0, x * SCALE, width,  x * SCALE);
    
            }
    
            for(let i = 0; i < size; i++){
                for(let j = 0; j < size;j++){//(${i},${j})
                    background.text(`${words[i][j]}`, (j*SCALE) + (Math.floor(SCALE / 2)), (i*SCALE) + (Math.floor(SCALE / 2)));
                }
            }
        };
    
        p5.draw = () => {
            p5.background(255);
           
            checkMouse(p5);
            showAllSelections(p5);
            p5.frameRate(30 );
            submitted && showNotfound(p5);
            p5.mouseReleased = () => {
                // if(!allSelections) {
                //     allSelections = [];
                //     allSelections[0] = currSelection
                // }
                // else allSelections.push(currSelection);
               // check if mouse is click inside the canvas
               if(p5.mouseX > 0 && p5.mouseX < totalW && p5.mouseY > 0 && p5.mouseY < totalH){
                
                add(currSelection);
                
               }
            }
    
            p5.image(background, 0, 0, p5.width, p5.height);
        };
    }

    function showNotfound(p5) {
        //

        notFound.map( selection => {
            let { word, coords} = selection;
            //

            p5.fill(125, 125, 255, 125);

            coords.map(({row, col}) => {
                p5.rect(col * SCALE, row * SCALE, SCALE, SCALE);
            })

        })
     
    }
    
    function showAllSelections(p5) {
       //

        let temp = selections; //[];
        if(!submitted) {
            temp = selections;
        } 
        else temp = answer;
        //
        temp.map(({selection, type}) => {
            p5.push();
            if(selection){
                switch(type){
                    case 'default':  p5.fill(255, 125, 125, 125); break;
                    case 'found': p5.fill(125, 255, 125, 125); break;
                    case 'wrong': p5.fill(255, 255, 125, 125); break;
                    default: p5.fill(125, 125, 255, 125); break;
                }
                selection.map(({row, col}) => {
                       
                        p5.rect(row * SCALE, col * SCALE, SCALE, SCALE);
                    })
            }
            p5.pop();
        })
    }

    function checkMouse(p5) {
        if(!p5.mouseIsPressed) {
            prevCell = null;
            currSelection = null;
            nextCell = null;
            return
        };
        if(p5.mouseIsPressed){
            if(!prevCell) {
                prevCell = findCell(p5.mouseX, p5.mouseY);
                currSelection = [];
                currSelection[0] = prevCell;

            }
            var nextCell = findCell(p5.mouseX, p5.mouseY);
            
            if (nextCell)
                currCell = nextCell;
                
            currSelection = findSelection(p5);
            //
        }
        showSelection(p5);
        //
    }


    function showSelection(p5) {
        p5.push();
        if(currSelection){
            selectWord = '';
            currSelection.map(({row, col}) => {
                p5.fill(125, 125, 255, 125);
                p5.rect(row * SCALE, col * SCALE, SCALE, SCALE);
                //
                selectWord=`${selectWord}${words[col][row]}`
            })
        }
        p5.pop();
       // 

    }

    function findCell(x, y) {
        var col = Math.floor(x/SCALE)
        var row = Math.floor(y/SCALE);

        if (col < 0 || col >= cols || row < 0 || row >= rows)
            return null;

        return { row: col, col: row };
    }

    
    function findSelection(p5)
    {
        if (!prevCell || !currCell)
            return null;

        // JavaScript feature
        // Execute hSelection() ... and if null execute vSelection(), etc.
        return hSelection() || vSelection(p5) || dSelection(p5);
    }

        
    function hSelection()
    {
        if (!prevCell || !currCell)
            return null;
            
        if (prevCell.row !== currCell.row)
            return null;

        var ar = [];
        
        var delta = prevCell.col <= currCell.col ? 1 : -1;

        for(var col = prevCell.col; col !== currCell.col + delta; col += delta)
        {
            var row = prevCell.row;
            var chr = words[col][row];
           // 
            ar.push( { row : row, col : col, chr : chr } );
        }

      //  
        return ar;        
    }

    function vSelection()
    {
        if (!prevCell || !currCell)
            return null;
            
        if (prevCell.col !== currCell.col)
            return null;

        var ar = [];
        
        var delta = prevCell.row <= currCell.row ? 1 : -1;

        for(var row = prevCell.row; row !== currCell.row + delta; row += delta)
        {
            var col = prevCell.col;
            var chr = words[col][row];
            //
            ar.push( { row : row, col : col, chr : chr } );
        }

        return ar;        
    }

    function dSelection(p5)
    {
        if (!prevCell || !currCell)
            return null;
            
        if (p5.abs(currCell.row - prevCell.row) !== p5.abs(currCell.col - prevCell.col))
            return null;
        
        var ar = [];
        
        var dh = prevCell.col <= currCell.col ? 1 : -1;
        var dv = prevCell.row <= currCell.row ? 1 : -1;

        var row = prevCell.row;
        var col = prevCell.col;

        while(row !== currCell.row + dv && col !== currCell.col + dh)
        {
            var chr = words[col][row];
            ar.push( { row : row, col : col, chr : chr } );

            row += dv;
            col += dh;
        }

        return ar;
    }

    useEffect(() => {
      
        const can = new P5(Sketch, containerRef.current);
    
        //
      return () => {
        can.remove()
      }
    }, [])
    


	return <div ref={containerRef}></div>;

}



// const matrixX = 250;
// const matrixY = 10;


// var matrix = [
//     ['J', 'Q', 'V', 'N', 'R', 'G', 'N', 'T', 'U', 'I'], 
//     ['V', 'L', 'A', 'O', 'S', 'A', 'X', 'P', 'S', 'C'], 
//     ['W', 'P', 'R', 'I', 'D', 'M', 'E', 'I', 'I', 'T'], 
//     ['K', 'P', 'I', 'T', 'I', 'E', 'T', 'R', 'H', 'K'],  
//     ['Z', 'U', 'A', 'C', 'K', 'S', 'G', 'C', 'X', 'T'],  
//     ['X', 'T', 'B', 'N', 'F', 'Z', 'Y', 'S', 'G', 'J'],  
//     ['O', 'D', 'L', 'U', 'Y', 'U', 'H', 'A', 'H', 'N'],  
//     ['A', 'F', 'E', 'F', 'C', 'A', 'N', 'V', 'A', 'S'],  
//     ['E', 'D', 'O', 'C', 'R', 'P', 'L', 'A', 'Y', 'A'],  
//     ['F', 'X', 'E', 'E', 'V', 'C', 'P', 'J', 'H', 'B']
// ];


// var found = [];

// var prevCell = null;
// var currCell = null;
// var  currSelection = null;


// function setup()
// {
//     found = [];
//     
//     createCanvas(1000, 1000)
// }

// function draw()
// {
//     clear();
    
//     displayBackground();

//     display();
//     checkMouse();

//     // text("Prev: " + (prevCell ? prevCell.row + "x" + prevCell.col : "null"), 10, 10);
//     // text("Curr: " + (currCell ? currCell.row + "x" + currCell.col : "null"), 10, 30);
// }

// function displayBackground()
// {
//     noStroke();
//     background(241, 69, 40);
//     fill(42, 59, 85);
//     circle(-90, 300, 400);
//   //
// }

// function checkMouse()
// {
//     if (!mouseIsPressed)
//     {
//         validateSelection();
        
//         prevCell = null;
//         currCell = null;
//         currSelection = null;
//         return;
//     }

//     if (!prevCell)
//         prevCell = findCell(mouseX, mouseY);

//     var nextCell = findCell(mouseX, mouseY);
    
//     if (nextCell)
//         currCell = nextCell;
        
//     currSelection = findSelection();
// }


// function validateSelection()
// {
//     var word = selectedWord();
//     if (!word)
//         return;
        
//     if (foundWord(word))
//         return;
        
//     if (words.includes(word))
//         addFound(word, currSelection);
        
//     if (found.length === words.length)
//     {
//         showScene("Congrats");
//     }
// }

// function findCell(x, y)
// {
//     var col = Math.floor( (x - matrixX) / squareSize );
//     var row = Math.floor( (y - matrixY) / squareSize );
    
//     if (col < 0 || col >= cols || row < 0 || row >= rows )
//         return null;

//     return { row : row, col : col };
// }

// function display()
// {
//     displayMatrix();
//     displaySelection();
//     displayWords();
// }


// function displayMatrix()
// {
//     push();
    
//     textAlign(CENTER, CENTER);
    
//     for(var row = 0; row < matrix.length; row++)
//     {
//         var arRow = matrix[row];
        
//         for(var col = 0; col < arRow.length; col++)
//         {
//             var chr = arRow[col];

//             var x = matrixX + col * squareSize;
//             var y = matrixY + row * squareSize;

//             stroke(0);
            
//             var clr = isSelected(row, col) ? "LightBlue" : (foundCell(row, col) ? "Pink" : "White");
//             fill( clr );
//             rect( x, y, squareSize, squareSize );
            
//             noStroke();
//             fill(0);
//             text(chr, x + squareSize / 2, y + squareSize / 2);
//         }
//     }
    
//     pop();
// }

// function selectedWord()
// {
//     if (!currSelection)    
//         return "";
        
//     var txt = "";    
    
//     for(var o of currSelection)
//     {
//         txt += o.chr;
//     }
    
//     return txt;
// }




// function displayWords()
// {
//     push();
//     noStroke();
//     for(var i = 0; i < words.length; i++)
//     {
//         fill( foundWord(words[i]) ? "Gray" : "White" );
//         text(words[i], 30, matrixY + 20 + i * 50);
//     }
//     pop();
// }


// function findSelection()
// {
//     if (!prevCell || !currCell)
//         return null;

//     // JavaScript feature
//     // Execute hSelection() ... and if null execute vSelection(), etc.
//     return hSelection() || vSelection() || dSelection();
// }

// function hSelection()
// {
//     if (!prevCell || !currCell)
//         return null;
        
//     if (prevCell.row != currCell.row)
//         return null;

//     var ar = [];
    
//     var delta = prevCell.col <= currCell.col ? 1 : -1;

//     for(var col = prevCell.col; col != currCell.col + delta; col += delta)
//     {
//         var row = prevCell.row;
//         var chr = matrix[row][col];
        
//         ar.push( { row : row, col : col, chr : chr } );
//     }

//     return ar;        
// }

// function vSelection()
// {
//     if (!prevCell || !currCell)
//         return null;
        
//     if (prevCell.col != currCell.col)
//         return null;

//     var ar = [];
    
//     var delta = prevCell.row <= currCell.row ? 1 : -1;

//     for(var row = prevCell.row; row != currCell.row + delta; row += delta)
//     {
//         var col = prevCell.col;
//         var chr = matrix[row][col];
        
//         ar.push( { row : row, col : col, chr : chr } );
//     }

//     return ar;        
// }

// function dSelection()
// {
//     if (!prevCell || !currCell)
//         return null;
        
//     if (abs(currCell.row - prevCell.row) != abs(currCell.col - prevCell.col))
//         return null;
    
//     var ar = [];
    
//     var dh = prevCell.col <= currCell.col ? 1 : -1;
//     var dv = prevCell.row <= currCell.row ? 1 : -1;

//     var row = prevCell.row;
//     var col = prevCell.col;

//     while(row != currCell.row + dv && col != currCell.col + dh)
//     {
//         var chr = matrix[row][col];
//         ar.push( { row : row, col : col, chr : chr } );

//         row += dv;
//         col += dh;
//     }

//     return ar;
// }

// // Returns true if the specified cell is part of the current selection 
// function isSelected(row, col)
// {
//     if (!currSelection)
//         return false;
        
//     for(var o of currSelection)
//     {
//         if (o.row === row && o.col === col)
//             return true;
//     }
    
//     return false;
// }

// // Add specified word and list of cells to the found list
// function addFound(word, cells)
// {
//     found.push( { word : word, cells : cells } );
// }

// // Returns true if the specified word is already found
// function foundWord(word)
// {
//     for(var o of found)
//     {
//         if (o.word === word)
//             return true;
//     }
    
//     return false;
// }

// // Returns true if the specified cell is part of an word already found
// function foundCell(row, col)
// {
//     for(var o of found)
//     {
//         for(var oCell of o.cells)
//         {
//             if (oCell.row === row && oCell.col === col)
//                 return true;
//         }
//     }
    
//     return false;
// }