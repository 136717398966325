import { useState } from "react";
import { Form, Row, Col, FormGroup, FormLabel } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signupUser } from "../../redux/authSlice";
import CustomButton from "../coreComponents/CustomButton";
import RegisterModal from "../coreComponents/RegisterModal";
import Navbar from "../Navbar/Navbar";
import "./forms.css";
const AdminForm = () => {
  const [isShow, setShow] = useState();
  const [message, setMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRegister = (e) => {
    e.preventDefault();
    dispatch(
      signupUser({ firstName, lastName, organization, role, email, password })
    ).then((res)=>{
      
      setShow(true);
      if(res.payload.status === 200){
        setMessage({msg:"Successful Registration!",status:"success"});
      } else{
        setMessage({msg:"Registration failed!",status:"fail"});
      }
    })
  };
  return (
        <Form className="p-4" style={{ backgroundColor: "#f2f1f1", borderRadius: '10px'}}>
          <RegisterModal isShow={isShow} status={message} onClick={()=>navigate('/')}/>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input1">First Name</FormLabel>
                <Form.Control
                  type="text"
                  id="input1"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input2">Last Name</FormLabel>
                <Form.Control
                  type="text"
                  id="input2"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <FormGroup>
              <FormLabel for="input1">Organization Name</FormLabel>
              <Form.Control
                type="text"
                id="input1"
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel for="input3">User Role</FormLabel>
              <Form.Control
                as="select"
                id="input3"
                value={role}
                onChange={(e) => setRole("ADMIN")}
              >
                <option>select</option>
                <option value="ADMIN">admin</option>
                {/* <option value="TEACHER">Teacher</option>
                  <option value="STUDENT">Student</option> */}
              </Form.Control>
            </FormGroup>
            <FormGroup>
              <FormLabel for="input1">User Id</FormLabel>
              <Form.Control
                type="email"
                id="input1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Row>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input7">Password</FormLabel>
                <Form.Control
                  type="password"
                  id="input7"
                  className={
                    confirmPassword && confirmPassword !== password
                      ? "form-control is-invalid"
                      : ""
                  }
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <FormLabel for="input9">Confirm Password</FormLabel>
                <Form.Control
                  type="password"
                  id="input9"
                  className={
                    confirmPassword && confirmPassword !== password
                      ? "form-control is-invalid"
                      : ""
                  }
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5 text-center">
              <CustomButton
               onClick={handleRegister} 
               disabled={!(firstName && lastName && email && password && confirmPassword && password === confirmPassword)}
               label="Submit" />
            </Col>
          </Row>
        </Form>
  );
};

export default AdminForm;
